import React, { SVGProps } from 'react';

export const IrregularCheckmarkSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='25' cy='25' r='23' fill='#374151' />
    <path
      d='M16 26.3364L21.1429 32.2273L34 17.5'
      stroke='white'
      strokeWidth='2.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
