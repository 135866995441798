import React, { useState, VFC } from 'react';

import { Tabs } from '@components/common';

import { LABELS, TABS } from './constants';
import { TabValues } from './types';
import { compact } from '@components/utils';
import { ResponsesTable, TreeTestSummary } from './components';

interface Props {
  answers: ScreenerResponseAnswerValue<'tree_test'>[];
  blockableId: number;
}

export const TreeTestResponse: VFC<Props> = ({ answers, blockableId }) => {
  const [activeTab, setActiveTab] = useState<TabValues>('table');
  const [preview, setPreview] = useState<NonNullable<ScreenerResponseAnswerValue<'tree_test'>> | null>(null);

  const validAnswers = compact(answers) as NonNullable<ScreenerResponseAnswerValue<'tree_test'>>[];

  const renderTabContent = () => {
    switch (activeTab) {
      case 'results':
        return null;
      case 'table':
        return <ResponsesTable answers={validAnswers} setPreview={setPreview} />;
      default:
        return null;
    }
  };

  if (validAnswers.length === 0) {
    return null;
  }

  return (
    <div>
      <TreeTestSummary answers={validAnswers} />
      <Tabs<TabValues> className='mb-4' current={activeTab} tabs={TABS} labels={LABELS} onSelect={setActiveTab} />
      {renderTabContent()}
    </div>
  );
};
