import { useState } from 'react';

import { createScreenerResponse } from '@api/public_endpoints';
import { useParticipantRecording } from '@components/ScreenSharingProvider';
import { isBlockOfKind, Enums } from '@components/SurveyBuilder';

import * as Types from '../types';

type ScreenerResponseAnswers = {
  [key: number]:
    | string
    | { label: string | string[] }
    | { prototype_test_events: Types.PrototypeTestEvent[] }
    | { card_sort_results: Types.CardSortResult[]; card_sort_events: Types.CardSortEvent[]; completed: boolean };
};

interface ResponseData extends ScreenerResponseAnswers {
  name?: string | null;
  email: string | null;
  consent: Participation['consent'];
  daily_recording_id: string | null;
  timings: { [key: number]: Partial<Types.Timings> };
}

type Result = [
  (anwsers: Types.Answer[], participation: Participation) => void,
  {
    isError: boolean;
    isLoading: boolean;
    transformed: ScreenerResponseAnswers;
  }
];

const transform = (answers: Types.Answer[]): ScreenerResponseAnswers => {
  const result: ScreenerResponseAnswers = {};

  answers.forEach(({ block, value, prototype_test_task, card_sort_task }) => {
    if (!block.screener_field_id) {
      return;
    }

    if (isBlockOfKind(block, Enums.Kind.prototypeTest) && prototype_test_task) {
      result[block.screener_field_id] = { prototype_test_events: prototype_test_task.events };
    } else if (isBlockOfKind(block, Enums.Kind.cardSort) && card_sort_task) {
      result[block.screener_field_id] = {
        card_sort_results: card_sort_task.results,
        card_sort_events: card_sort_task.events,
        completed: card_sort_task.isComplete
      };
    } else if (isBlockOfKind(block, Enums.Kind.multiSelect) && Array.isArray(value)) {
      result[block.screener_field_id] = { label: value };
    } else if (
      (isBlockOfKind(block, Enums.Kind.singleSelect) || isBlockOfKind(block, Enums.Kind.yesNo)) &&
      typeof value === 'string'
    ) {
      result[block.screener_field_id] = { label: value };
    } else if (typeof value === 'string') {
      result[block.screener_field_id] = value;
    } else if (typeof value === 'number') {
      result[block.screener_field_id] = value.toString();
    } else {
      result[block.screener_field_id] = '';
    }
  });

  return result;
};

const timings = (answers: Types.Answer[]): ResponseData['timings'] => {
  const result: ResponseData['timings'] = {};

  answers.forEach(({ block, timings }) => {
    if (!block.screener_field_id || !timings) {
      return;
    }

    result[block.screener_field_id] = timings;
  });

  return result;
};

export const useCreateResponse = (): Result => {
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [transformed, setTransformed] = useState<ScreenerResponseAnswers>({});

  const { recordingId: dailyRecordingId } = useParticipantRecording();

  const submit = async (answers: Types.Answer[], participation: Participation) => {
    if (answers.length === 0) {
      return;
    }

    const [{ block }] = answers;

    if (!block.screener_id) {
      return;
    }

    setIsLoading(true);

    const newTransformed = transform(answers);
    setTransformed(newTransformed);

    const responseData: ResponseData = {
      name: participation.name,
      email: participation.email,
      consent: participation.consent,
      daily_recording_id: dailyRecordingId || null,
      timings: timings(answers),
      ...newTransformed
    };

    try {
      await createScreenerResponse(block.screener_id, participation.id, responseData);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return [submit, { isError, isLoading, transformed }];
};
