import React, { useMemo, useState } from 'react';
import { Button, Input, Modal, ModalHeading, Text } from '@components/common';
import { Label } from '@components/fields';
import { SelectWithSelectedButtons } from '@components/common';
import { DropdownItem } from '@components/common/DropdownCombobox';
import { capitalize } from '@components/utils';
import { useForm } from 'react-hook-form';
import { Error } from '@components/shared/AI';

interface Props {
  onClose: () => void;
  categories: string[];
  onMerge: (args: { name: string; categories: string[] }) => void;
  defaultSelected?: string[];
  defaultMergedCategory?: string;
  isLoading?: boolean;
}

export const MergeCategoriesModal: React.FC<Props> = ({
  onClose,
  categories,
  onMerge,
  defaultSelected,
  defaultMergedCategory = '',
  isLoading
}) => {
  const { handleSubmit, register, errors, getValues } = useForm({
    defaultValues: {
      mergedCategory: defaultMergedCategory
    },
    mode: 'onSubmit'
  });

  const [selected, setSelected] = useState<string[]>(defaultSelected || []);

  const onSelect = (value: DropdownItem | null) => {
    if (!value) return;

    setSelected([...selected, value.value]);
  };

  const onDeselect = (value: string) => {
    setSelected(selected.filter((s) => s !== value));
  };

  const items = useMemo(
    () =>
      categories
        .filter((value) => !selected.includes(value))
        .map((value) => ({
          label: capitalize(value),
          value
        })),
    [selected, categories]
  );

  return (
    <Modal
      size='md'
      onClose={onClose}
      renderFooter={() => (
        <>
          <Button disabled={isLoading} onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!selected.length || isLoading}
            primary
            loading={isLoading}
            onClick={handleSubmit(() => onMerge({ name: getValues().mergedCategory, categories: selected }))}
          >
            Merge
          </Button>
        </>
      )}
    >
      <ModalHeading className='mb-1'>Merge categories</ModalHeading>
      <Text className='mb-6' h='400' color='gray-500'>
        Combine similar category names for easier analysis
      </Text>

      <Label className='mb-1'>Merge these categories</Label>
      <div className='mb-6'>
        <SelectWithSelectedButtons
          renderValue={capitalize}
          onSelect={onSelect}
          values={selected}
          onDeselect={onDeselect}
          items={items}
          placeholder='Select categories'
        />
      </div>

      <Label className='mb-1' labelFor='mergedCategory'>
        Into
      </Label>
      <Input
        name='mergedCategory'
        id='mergedCategory'
        defaultValue={defaultMergedCategory}
        error={!!errors.mergedCategory}
        ref={register({ required: 'Category name is required.' })}
        placeholder='Enter merged category name'
      />
      {errors.mergedCategory && (
        <Error aria-label='Error message' className='mt-1'>
          {errors.mergedCategory.message}
        </Error>
      )}
    </Modal>
  );
};
