import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Text } from '@components/common';
import { ErrorSvg } from '@components/svgs';

import { ConsentCheckboxes } from './ConsentCheckboxes';
import { RULES } from './constants';
import { EmailInput, ShortTextInput } from './fields';
import { QuestionCard, QuestionInner, QuestionTitle } from './QuestionCard';

type NameAndIdQuestionProps = Pick<UseFormMethods, 'errors' | 'watch' | 'register'> & {
  account: PublicGQAccount;
  study: PublicStudy;
  token: string;
  external: boolean;
  hasPrefillId: boolean;
  number: number;
  isActive?: boolean;
  consentCheckboxes: ConsentCheckbox[];
  onFocus?: () => void;
  onClick?: () => void;
};

export const NameAndIdQuestion: React.FC<NameAndIdQuestionProps> = ({
  token,
  external,
  hasPrefillId,
  study,
  account,
  consentCheckboxes,
  register,
  errors,
  watch,
  isActive,
  onFocus,
  onClick
}) => {
  const unique_id_attr = account.unique_id_attr;
  const hasToken = Boolean(token && token.length > 0);

  const { t } = useTranslation('SurveyQuestion');

  return (
    <QuestionCard onClick={onClick} isActive={!!isActive}>
      <QuestionInner>
        <QuestionTitle>{t('your_details')}</QuestionTitle>
        {!external && (
          <div className='relative mt-5 mb-8'>
            <label htmlFor='name' className='text-custom-brand mb-2 font-bold'>
              {t('full_name')}
            </label>
            <ShortTextInput
              placeholder={t('placeholder_enter')}
              field={{ id: 'name', required: true } as any}
              ariaLabel='Name'
              rules={RULES.name}
              register={register}
              autofocus
              error={errors.name}
              watch={watch}
              onFocus={onFocus}
            />
            {errors.name && (
              <Text aria-label='Error message' color='red-600' h='400' className='flex items-center mt-4 truncate'>
                <ErrorSvg className='mr-2' />
                {t(errors.name.message) || t('required')}
              </Text>
            )}
          </div>
        )}
        {!external && (study.comms_medium === 'sms' || unique_id_attr === 'phone_number') && (
          <div className='relative mb-8'>
            <label htmlFor='phone_number' className='text-custom-brand mb-2 font-bold'>
              {t('phone_number')}
            </label>
            <ShortTextInput
              placeholder='e.g. +1 415 555 5555'
              field={{ id: 'phone_number', required: true } as any}
              rules={RULES.phone_number}
              register={register}
              readOnly={unique_id_attr === 'phone_number' && (hasPrefillId || hasToken)}
              error={errors['phone_number']}
              watch={watch}
              onFocus={onFocus}
            />
            {errors.phone_number && (
              <Text aria-label='Error message' color='red-600' h='400' className='flex items-center mt-4 truncate'>
                <ErrorSvg className='mr-2' />
                {t(errors.phone_number.message) || t('required')}
              </Text>
            )}
          </div>
        )}
        {!external && (study.comms_medium === 'email' || unique_id_attr === 'email') && (
          <div className='relative mb-8'>
            <label htmlFor='email' className='text-custom-brand mb-2 font-bold'>
              {t('email_address')}
            </label>
            <EmailInput
              placeholder={t('placeholder_enter')}
              field={{ id: 'email', required: true } as any}
              ariaLabel='Email'
              rules={RULES.email}
              register={register}
              readOnly={unique_id_attr === 'email' && (hasPrefillId || hasToken)}
              error={errors['email']}
              watch={watch}
              onFocus={onFocus}
            />
            {errors.email && (
              <Text aria-label='Error message' color='red-600' h='400' className='flex items-center mt-4 truncate'>
                <ErrorSvg className='mr-2' />
                {t(errors.email.message) || t('required')}
              </Text>
            )}
          </div>
        )}
        <ConsentCheckboxes consentCheckboxes={consentCheckboxes} register={register} errors={errors} />
      </QuestionInner>
    </QuestionCard>
  );
};
