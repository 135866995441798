import { ValidationRules } from 'react-hook-form';

import { emailPattern } from '@helpers/emailRegexpValidation';

export const RULES: Record<string, ValidationRules> = {
  name: {
    required: { value: true, message: 'please_enter_your_name' },
    pattern: { value: /[^\s]/, message: 'please_enter_your_name' }
  },
  email: {
    required: { value: true, message: 'please_enter_your_email_address' },
    pattern: { value: emailPattern, message: 'please_enter_valid_email_address' }
  },
  phone_number: {
    required: { value: true, message: 'please_enter_phone_number' }
  }
};
