import * as React from 'react';
import pluralize from 'pluralize';
import tinytime from 'tinytime';

import { Text } from '@components/common';
import { TimezoneIndicator } from '@components/shared/TimezoneIndicator';

const dateFormat = tinytime('{dddd}, {MMMM} {DD}, {YYYY}');
const timeFormat = tinytime('{h}:{mm} {a}');

type Props = {
  editMode: boolean;
  bookingDetail: CalendarBookingDetail;
};

export const SessionDetails: React.VFC<Props> = ({ editMode, bookingDetail }) => {
  const event = bookingDetail.candidate_event;

  if (!event) {
    return null;
  }

  return (
    <div>
      <Text as='h3' h='600' bold mb='6'>
        Session details
      </Text>

      {!!event.time && (
        <div className='py-2'>
          <div className='flex items-start'>
            <div className='w-36'>
              <Text h='400' bold className='py-2.5'>
                Date
              </Text>
            </div>
            <Text className='py-2.5' h='400'>
              {dateFormat.render(event.time.start_time)}
            </Text>
          </div>
        </div>
      )}

      {!!event.time && (
        <div className='py-2'>
          <div className='flex items-start'>
            <div className='w-36'>
              <Text h='400' bold className='py-2.5'>
                Time
              </Text>
            </div>
            <div>
              <Text className='py-2.5' h='400'>
                {timeFormat.render(event.time.start_time)} - {timeFormat.render(event.time.end_time)}
              </Text>
              <div className='py-2.5'>
                <TimezoneIndicator />
              </div>
            </div>
          </div>
        </div>
      )}

      {!!event.time && (
        <div className='py-2'>
          <div className='flex items-start'>
            <div className='w-36'>
              <Text h='400' bold className='py-2.5'>
                Duration
              </Text>
            </div>
            <div>
              <Text className='py-2.5' h='400'>
                {pluralize('minutes', event.time.duration_in_minutes, true)}
              </Text>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
