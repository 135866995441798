import * as React from 'react';

import { SlideOut } from '@components/common';
import { useStudyBookability } from '@components/StudyMessages/hooks/useStudyBookability';
import { BookabilityAlerts } from '@components/shared/BookabilityAlerts';

import { ShareableLinks } from './ShareableLinks';

type Props = {
  study: Study;
  onClose: () => void;
};

export const InviteViaLinkSlideout: React.FC<Props> = ({ study, onClose }) => {
  const { bookability, startPolling } = useStudyBookability({ studyId: study.id, event: 'invite' });

  return (
    <SlideOut
      subtitle='These are generic participant invitation links that anyone can access.'
      title='Invite via link'
      onClose={onClose}
      size='xl'
    >
      <div className='px-6'>
        {bookability && <BookabilityAlerts study={study} bookability={bookability} onStartPolling={startPolling} />}
        <ShareableLinks study={study} />
      </div>
    </SlideOut>
  );
};
