interface Args {
  answers: NonNullable<ScreenerResponseAnswerValue<'card_sort'>>[];
  categories: string[];
  cards: string[];
}

export interface AgreementMatrixData {
  card: string;
  categoriesWithAgreement: Record<string, Record<string, number>>;
}

export const buildAgreementMatrixData = ({ answers, categories, cards }: Args): AgreementMatrixData[] => {
  return cards.map((card) => {
    const categoriesWithAgreement = categories.reduce((acc, category) => {
      const curr = { ...acc };

      const totalCardsInCategory = answers.reduce((acc, answer) => {
        if (answer.results.find(({ name }) => name === category)?.cards.includes(card)) {
          return acc + 1;
        }
        return acc;
      }, 0);

      curr[card] = { ...(curr[card] || {}), [category]: totalCardsInCategory };

      return curr;
    }, {});

    return {
      card,
      categoriesWithAgreement
    };
  });
};
