// Without the symbol
export const moneyFormat = (num: number, digits: number = 2): string =>
  num.toFixed(digits).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

export const kFormat = (num: number): string =>
  Math.abs(num) > 999 ? Math.sign(num)*(Math.round(Math.abs(num)/100)/10) + 'k' : (Math.sign(num)*Math.abs(num)).toString();

export * from './ids';

export const sum = (arr: number[]): number => arr.reduce((acc, v) => acc + v, 0);

export const toPositiveNumber = (v: string | number): number =>
  !!Number(v) && Math.abs(Number(v)) >= 0 ? Math.abs(Number(v)) : 0;

export const pct = (a: number, b: number) => Math.round((100 * a) / b);

export const average = (numbers: number[]) => {
  if (numbers.length === 0) {
    return 0; // Return 0 if the array is empty
  }

  const sum = numbers.reduce((acc, curr) => acc + curr, 0);

  return sum / numbers.length;
};
