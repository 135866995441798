import { ColumnDef } from '@tanstack/react-table';
import { Text } from '@components/common';
import { capitalize } from '@components/utils';
import React from 'react';
import { AgreementMatrixData } from './buildAgreementMatrixData';
import { renderMergedCategories } from '@components/ScreenerResults/components/CardSortResponse/utils';

export type DefinitionFnReturn = ColumnDef<AgreementMatrixData>[];

export type DefinitionFn = (args: {
  answers: NonNullable<ScreenerResponseAnswerValue<'card_sort'>>[];
  categories: string[];
  showPercentage: boolean;
  cards: string[];
  mergedCategories: { name: string; categories: string[] }[];
}) => DefinitionFnReturn;

const getMatrixValue = (showPercentage, value, totalAnswers) => {
  if (!value) return '';

  const matrixValue = showPercentage ? `${Math.round(((value || 0) / totalAnswers || 1) * 100)} %` : value;

  return matrixValue;
};

const getStyle = (value, totalAnswers) => ({
  width: '145px',
  backgroundColor: value ? `rgba(104, 117, 245, ${value / totalAnswers})` : '#E5E7EB',
  color: value > 0.5 ? 'white' : '#374151'
});

export const getAgreementMatrixColumnDefs: DefinitionFn = ({
  mergedCategories,
  showPercentage,
  categories,
  answers
}) => {
  const columns: DefinitionFnReturn = [
    {
      id: 'card',
      accessorKey: 'card',
      cell: (props) => (
        <Text className='flex items-center justify-start bg-white p-2 h-11 truncate'>{props.getValue()}</Text>
      ),
      header: () => <div className='bg-white h-11' />
    },
    ...categories.map((category) => ({
      id: category,
      accessorFn: (row) => row.categoriesWithAgreement[row.card][category],
      cell: (props) => (
        <Text
          style={getStyle(props.getValue(), answers.length)}
          h='600'
          className='flex items-center justify-center m-1.5 rounded-md p-2 h-11'
        >
          {getMatrixValue(showPercentage, props.getValue(), answers.length)}
        </Text>
      ),
      header: () => (
        <Text style={{ width: '150px' }} className='flex items-center h-11 bg-white'>
          <div className='pl-2 truncate'>{capitalize(category)}</div>
          {renderMergedCategories({ mergedCategories, category })}
        </Text>
      )
    }))
  ];
  return columns;
};
