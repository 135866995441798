import cn from 'classnames';
import React from 'react';

export const QuestionWrapper: React.FC<{ id: any }> = ({ id, children }) => (
  <div
    id={`step-${id}`}
    key={id}
    aria-live='polite'
    className='tablet:p-6 flex p-4 my-6 border border-gray-200 rounded-sm'
  >
    {children}
  </div>
);

export const QuestionCard: React.FC<{ isActive: boolean; onClick?: () => void }> = ({
  isActive,
  children,
  onClick
}) => {
  return (
    <div className={cn('w-full flex', { 'opacity-50': !isActive })} onClick={onClick || undefined}>
      {children}
    </div>
  );
};

export const QuestionInner: React.FC = ({ children }) => <div className='max-w-40 flex-1'>{children}</div>;

interface QuestionTitleProps {
  required?: boolean;
  htmlFor?: string;
}

export const QuestionTitle: React.FC<QuestionTitleProps> = ({ htmlFor, children, required }) => (
  <label
    htmlFor={htmlFor}
    className={cn('text-xl font-bold leading-normal text-custom-brand flex items-start space-x-1')}
  >
    <span>{children}</span>
    {required && <span className='h400'>*</span>}
  </label>
);
export const QuestionNumber: React.FC<{ number: number }> = ({ number }) => (
  <div className='text-custom-brand w-8 text-xl font-bold'>{number}.</div>
);
