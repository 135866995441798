import { useMemo } from 'react';

import { compact } from '@components/utils';
import { useDisabledFeatures } from '@hooks/useDisabledFeatures';
import { usePlan } from '@hooks/usePlan';

type Hook = [PlanOption[], (options: PlanOption[], save: (study: Study) => void) => void];

export const useStudyPlan = (study: Study, setStudy: (study: { id: number } & Partial<Study>) => void): Hook => {
  const { hasFeature } = usePlan();
  const hasIncentives = hasFeature('incentives');
  const disabledFeatures = useDisabledFeatures();
  const allowedIncentives = hasIncentives && !disabledFeatures.incentives;

  // Issues with it updating when state - moving it to just read off study :)
  const options = useMemo(() => {
    return compact<PlanOption>([
      study.incentive_method ? 'incentive' : '',
      study.has_screener ? 'screener' : '',
      study.external_candidates_enabled ? 'external_candidates' : ''
    ]);
  }, [study]);

  const setOptions = (options: PlanOption[]) => {
    const defaultIncentive = options.includes('external_candidates') ? 5 : 1;

    let patch: Partial<Study> = {
      has_screener: options.includes('screener'),
      external_candidates_enabled: options.includes('external_candidates'),
      incentive: study.incentive ? Math.max(defaultIncentive, study.incentive) : defaultIncentive
    };

    // toggling on/off screener or incentives on the plan step should alter the study
    // as well
    if (study.incentive_method && !options.includes('incentive')) {
      patch = {
        ...patch,
        incentive_method: null,
        incentive: null,
        incentive_coupons: null,
        incentive_instructions: null,
        incentive_title: null
      };
    }
    if (!study.incentive_method && options.includes('incentive')) {
      patch = {
        ...patch,
        incentive_method: allowedIncentives ? 'tremendous' : 'manual'
      };
    }
    if (!study.incentive_method && !options.includes('incentive')) {
      patch = {
        ...patch,
        incentive: null
      };
    }

    if (options.includes('external_candidates')) {
      patch = {
        ...patch,
        currency: 'USD',
        incentive_method: 'tremendous'
      };
    }
    setStudy({ id: study.id, ...patch });
  };

  return [options, setOptions];
};
