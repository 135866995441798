import * as React from 'react';
import { useEffect, useState } from 'react';

import { Button, Text, Toggle, TippyOrNot, TeamIcon } from '@components/common';
import { moneyFormat } from '@components/utils';
import { useToaster } from '@stores/toaster';
import { useAccount } from '@hooks/useAccount';
import { useTeams } from '@hooks/useTeams';

import * as toasts from '../toasts';
import { MoveBalanceSlideout } from '../MoveBalanceSlideout';

type WalletType = 'funds' | 'credits';

const WalletMetric: React.FC<{ label: string; value?: number; tippyContent: string }> = ({
  label,
  value,
  tippyContent
}) => {
  return (
    <div className='flex w-48 h-10 my-4 px-6 align-middle items-center border-r border-gray-200'>
      {value !== undefined ? (
        <>
          <Text h='500' className='mr-2'>
            ${moneyFormat(value / 100)}
          </Text>
          <TippyOrNot content={tippyContent} show={!!tippyContent}>
            <Text h='200' color='gray-500'>
              {label}
            </Text>
          </TippyOrNot>
        </>
      ) : (
        <Text h='200' color='gray-500'>
          N/A
        </Text>
      )}
    </div>
  );
};

type WalletRowProps = {
  showCredits: boolean;
  funds_available: number;
  funds_allocated: number;
  funds_spent: number;
  credits_available?: number;
  credits_allocated?: number;
  teamWalletsEnabled: boolean;
  teamId?: number;
  openSlideout: (walletType: WalletType, to?: string) => void;
  showTippy?: boolean;
};
const WalletsRow: React.FC<WalletRowProps> = ({
  showCredits,
  funds_available,
  funds_allocated,
  funds_spent,
  credits_available,
  credits_allocated,
  teamWalletsEnabled,
  teamId,
  openSlideout,
  showTippy = false
}) => {
  return (
    <div className='flex'>
      <div className='flex pl-2'>
        {showCredits && (
          <div>
            <div className='flex w-18 h-10 my-4 align-middle items-center'>
              <Text h='500' color='gray-500'>
                Funds
              </Text>
            </div>
            <div className='flex w-18 h-10 my-4 align-middle items-center'>
              <Text h='500' color='gray-500'>
                Credits
              </Text>
            </div>
          </div>
        )}
        <div>
          <WalletMetric
            label='Available'
            value={funds_available}
            tippyContent={showTippy ? 'The amount of general funds that are available for use' : ''}
          />
          {showCredits && (
            <WalletMetric
              label='Available'
              value={credits_available}
              tippyContent={showTippy ? 'The amount of recruitment fee credits that are available for use' : ''}
            />
          )}
        </div>
        <div>
          <WalletMetric
            label='Allocated'
            value={funds_allocated}
            tippyContent={showTippy ? 'The amount of general funds that are allocated to studies and teams' : ''}
          />
          {showCredits && (
            <WalletMetric
              label='Allocated'
              value={credits_allocated}
              tippyContent={
                showTippy ? 'The amount of recruitment fee credits that are allocated to studies and teams' : ''
              }
            />
          )}
        </div>
        <div>
          <WalletMetric
            label='Spent'
            value={funds_spent}
            tippyContent={showTippy ? 'The amount of general funds that were spent' : ''}
          />
          {showCredits && <WalletMetric label='' value={undefined} tippyContent='' />}
        </div>
        <div>
          <div className='flex w-60 h-10 pl-6 my-4 align-middle items-center'>
            <Button
              icon='transferVertical'
              medium
              disabled={!teamWalletsEnabled}
              onClick={() => openSlideout('funds', teamId ? teamId.toString() : undefined)}
            >
              Move funds
            </Button>
          </div>
          {showCredits && (
            <div className='flex w-60 h-10 pl-6 my-4 align-middle items-center'>
              <Button
                icon='transferVertical'
                medium
                disabled={!teamWalletsEnabled}
                onClick={() => openSlideout('credits', teamId ? teamId.toString() : undefined)}
              >
                Move credits
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

type TeamWalletsTableProps = {
  showCredits: boolean;
  teams: Team[];
  teamWalletsEnabled: boolean;
  openSlideout: (walletType: WalletType, to?: string) => void;
};
const TeamWalletsTable: React.FC<TeamWalletsTableProps> = ({
  showCredits,
  teams,
  teamWalletsEnabled,
  openSlideout
}) => {
  return (
    <>
      {teams.map((team) => (
        <div className='mt-6 pt-6 border-t border-gray-200'>
          <div className='flex items-center space-x-2 mb-2'>
            <TeamIcon team={team} />
            <Text h='500' bold>
              {team.name}
            </Text>
          </div>
          <div className='flex flex-col space-y-2'>
            <WalletsRow
              showCredits={showCredits}
              funds_available={team.wallet_available || 0}
              funds_allocated={team.wallet_allocated || 0}
              funds_spent={team.wallet_spent || 0}
              credits_available={team.external_credits_available || 0}
              credits_allocated={team.external_credits_allocated || 0}
              teamWalletsEnabled={teamWalletsEnabled}
              teamId={team.id}
              openSlideout={openSlideout}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export const WalletsIndex: React.FC = () => {
  const [slideoutOpen, setSlideoutOpen] = useState(false);

  const {
    account,
    update,
    refresh,
    account: { wallet }
  } = useAccount();
  const { teams = [], isError } = useTeams({ includeWallet: true });

  const [teamWalletsEnabled, setTeamWalletsEnabled] = useState(account.team_wallets_enabled);
  const [hasTeamBalances, setHasTeamBalances] = useState(false);

  const [walletType, setWalletType] = useState<WalletType>('funds');
  const [initialTo, setInitialTo] = useState('');

  const showToast = useToaster();

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedGetTeams());
    }
  }, [isError]);

  useEffect(() => {
    setHasTeamBalances(!!teams.find((t) => (t.wallet_available || 0) > 0));
  }, [teams]);

  useEffect(() => {
    setTeamWalletsEnabled(account.team_wallets_enabled);
  }, [account]);

  async function handleTeamWalletsEnabled(v: boolean) {
    setTeamWalletsEnabled(v);
    await update({ team_wallets_enabled: v });
    refresh();
  }

  const openSlideout = (walletType: WalletType, to?: string) => {
    setWalletType(walletType);
    setInitialTo(to || '');
    setSlideoutOpen(true);
  };

  const onClose = () => {
    setSlideoutOpen(false);
  };

  const onSuccess = () => {
    refresh();
  };

  const showCredits = (account.external_credits_available || 0) > 0 || (account.external_credits_allocated || 0) > 0;

  return (
    <>
      <div className='flex items-center justify-between mb-4 monitor:mb-6'>
        <Text h='700' as='h1' bold>
          Wallets
        </Text>

        <div className='space-x-3 ml-4'>
          {teamWalletsEnabled && (
            <Button medium icon='transferVertical' onClick={() => openSlideout('funds')}>
              Move funds
            </Button>
          )}
          {teamWalletsEnabled && showCredits && (
            <Button medium icon='transferVertical' onClick={() => openSlideout('credits')}>
              Move credits
            </Button>
          )}
          <Button medium icon='accountingDocument' onClick={() => (window.location.href = '/incentives/invoice')}>
            Create invoice
          </Button>
          <Button medium icon='cashPayment' primary onClick={() => (window.location.href = '/incentives/deposit')}>
            Deposit funds
          </Button>
        </div>
      </div>

      <div className='w-max p-6 mb-12 bg-white border border-gray-200 rounded-md'>
        <Text h='500' bold mb='2'>
          General
        </Text>
        <div className='flex flex-col space-y-2'>
          <WalletsRow
            showCredits={showCredits}
            funds_available={wallet.available}
            funds_allocated={wallet.allocated}
            funds_spent={wallet.spent}
            credits_available={account.external_credits_available || 0}
            credits_allocated={account.external_credits_allocated || 0}
            teamWalletsEnabled={teamWalletsEnabled}
            openSlideout={openSlideout}
            showTippy
          />
        </div>
      </div>

      <div className='w-max p-6 bg-white border border-gray-200 rounded-md'>
        <div className='flex mb-6'>
          <div className='flex-1'>
            <Text h='500' bold>
              Team wallets
            </Text>
          </div>
          <TippyOrNot content='All teams wallets must be empty before disabling' show={hasTeamBalances}>
            <Toggle
              on={teamWalletsEnabled}
              disabled={hasTeamBalances}
              onToggle={handleTeamWalletsEnabled}
              testId='team-wallets-enabled-toggle'
            />
          </TippyOrNot>
        </div>
        {teamWalletsEnabled ? (
          teams.length ? (
            <TeamWalletsTable
              showCredits={showCredits}
              teams={teams}
              teamWalletsEnabled={teamWalletsEnabled}
              openSlideout={openSlideout}
            />
          ) : (
            <Text h='400' color='gray-500'>
              Once you've added one or more teams, you'll be able make funds available to them here.
            </Text>
          )
        ) : (
          <Text h='400' color='gray-500'>
            When enabled, studies created with a Team will be required to use the Team Wallet for incentives.
          </Text>
        )}
      </div>
      {slideoutOpen && (
        <MoveBalanceSlideout
          walletType={walletType}
          account={account}
          teams={teams}
          initialFrom='account'
          initialTo={initialTo}
          onClose={onClose}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};
