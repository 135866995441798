export const buildCardsTableData = ({ answers, categories, cards }) => {
  return cards.map((card) => {
    const categoriesWithCard = categories.filter((category) => {
      return answers.some((answer) => {
        const currentResult = answer.results.find(({ name }) => name === category);

        return currentResult?.cards.includes(card);
      });
    });

    const categoriesWithFrequency = categoriesWithCard.map((category) => {
      const numbers = answers.reduce(
        (acc, answer) => {
          const currentResult = answer.results.find(({ name }) => name === category);

          if (currentResult?.cards.includes(card)) {
            acc.frequency += 1;
            acc.position += currentResult.cards.indexOf(card) + 1;
          }

          return acc;
        },
        { frequency: 0, position: 0 }
      );
      return {
        name: category,
        frequency: numbers.frequency,
        avgPosition: (numbers.position / numbers.frequency).toFixed(1),
        avgAgreement: 1 / categoriesWithCard.length
      };
    });

    const sortedCategoriesWithFrequency = categoriesWithFrequency.sort((a, b) => b.frequency - a.frequency);

    return {
      card,
      categories: sortedCategoriesWithFrequency || []
    };
  });
};
