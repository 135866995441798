import { Toast } from '@stores/toaster';

export const failedMerge = (): Toast => ({
  heading: 'Failed to merge categories!',
  icon: 'error',
  text: 'Something went wrong merging the categories. Please try again later.'
});

export const failedUnmerge = (): Toast => ({
  heading: 'Failed to unmerge categories!',
  icon: 'error',
  text: 'Something went wrong unmerging the categories. Please try again later.'
});
