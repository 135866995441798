import React from 'react';

import { Button } from '@components/common';
import HeadlessTippy from '@tippyjs/react/headless';
import { JSONContent } from '@tiptap/react';

import { CtaTooltip } from './CtaTooltip/CtaTooltip';
import { CtaProps } from '@components/StudyMessages/components/DocumentPreview/CtaTooltip/helpers';

type Opts = {
  ctaProps?: CtaProps;
  renderMergeTag?: (str: string) => JSX.Element;
  evalIfCondition?: (str: string) => boolean;
  ctaAs: 'button' | 'link';
};
const DEFAULT_OPTS: Opts = { ctaAs: 'button' };

export const renderNodes = (nodes?: JSONContent[], opts: Opts = DEFAULT_OPTS, level = 0): JSX.Element | null => {
  if (nodes) {
    return (
      <>
        {nodes.map((n, i) => (
          <React.Fragment key={i}>{renderNode(n, opts, level)}</React.Fragment>
        ))}
      </>
    );
  }
  return null;
};

const renderNode = (
  node: JSONContent,
  opts: Opts = DEFAULT_OPTS,
  level: number = 0
): JSX.Element | string | undefined => {
  let content = renderNodeContent(node, opts, level);
  (node.marks || []).forEach((mark) => {
    content = applyMark(mark, content, opts);
  });
  return content;
};

const renderCta = (node: JSONContent, opts: Opts = DEFAULT_OPTS, level = 0): JSX.Element | string | undefined => {
  return (
    <HeadlessTippy
      interactive
      disabled={!opts.ctaProps}
      placement='top-start'
      render={(attrs) => <CtaTooltip {...attrs} {...(opts.ctaProps || {})} />}
    >
      {opts.ctaAs === 'button' ? (
        <Button className='btn-custom-brand mt-2 mb-6' secondary>
          {renderNodes(node.content, opts, level)}
        </Button>
      ) : (
        <a className='text-custom-brand block mt-4 mb-4 font-bold underline'>
          {renderNodes(node.content, opts, level)}
        </a>
      )}
    </HeadlessTippy>
  );
};

export const renderNodeContent = (
  node: JSONContent,
  opts: Opts = DEFAULT_OPTS,
  level: number = 0
): JSX.Element | string | undefined => {
  const listStyle = level === 0 ? 'list-decimal' : level === 1 ? 'list-alpha' : 'list-roman';

  switch (node.type) {
    case 'hardBreak':
      return <br />;
    case 'heading':
      return (
        <div className='text-custom-brand mb-4 text-xl font-bold text-gray-700'>
          {renderNodes(node.content, opts, level)}
        </div>
      );
    case 'text':
      return node.text;
    case 'paragraph':
      return (
        <div className='text-custom-brand h500 mb-2' style={node.attrs}>
          {(node.content || []).length == 0 ? <span className='block h-5' /> : renderNodes(node.content, opts, level)}
        </div>
      );
    case 'bullet_list':
    case 'bulletList':
      return (
        <ul className='text-custom-brand mb-2 ml-6 list-disc' style={node.attrs}>
          {renderNodes(node.content, opts, level)}
        </ul>
      );
    case 'ordered_list':
    case 'orderedList':
      return (
        <ol className={`text-custom-brand mb-2 ml-6 ${listStyle}`} style={node.attrs}>
          {renderNodes(node.content, opts, level + 1)}
        </ol>
      );
    case 'list_item':
    case 'listItem':
      return (
        <li className='text-custom-brand' style={node.attrs}>
          {renderNodes(node.content, opts, level)}
        </li>
      );
    case 'cta':
      return renderCta(node, opts);
    case 'merge_tag':
      if (opts.renderMergeTag) {
        return opts.renderMergeTag((node.attrs || {}).attr);
      } else {
        return <span className='text-custom-brand'>{(node.attrs || {}).attr}</span>;
      }
    default:
      return '';
  }
};

function applyMark(mark, content, opts) {
  switch (mark.type) {
    case 'em':
    case 'italic':
      return <i>{content}</i>;
    case 'underline':
      return <u>{content}</u>;
    case 'strong':
      return <b>{content}</b>;
    case 'link':
      return (
        <a href={mark.attrs.href} target='_blank'>
          {content}
        </a>
      );
    case 'if':
      if (!opts.evalIfCondition) {
        return content;
      } else if (opts.evalIfCondition(mark.attrs.attr)) {
        return content;
      } else {
        return null;
      }
    default:
      return content;
  }
}
