import React from 'react';

import { Table } from '@components/shared/Table';
import { ColumnDef } from '@tanstack/react-table';
import { VideoCameraSVG } from '@components/svgs';
import { ClickPointer } from '@components/ScreenerResults/components/PrototypeTestResponse/components/ClickPointer';
import { Text } from '@components/common';
import { Tooltip } from 'components/shared/Tooltip';
import { Tooltip as InfoTooltip } from '@components/common';
import { formatMilliseconds } from '@components/ScreenerResults/components/CardSortResponse/utils';

export type DefinitionFnReturn = ColumnDef<NonNullable<ScreenerResponseAnswerValue<'tree_test'>>>[];

type Args = { setPreview: (value: NonNullable<ScreenerResponseAnswerValue<'tree_test'>>) => void };

export type DefinitionFn = (args: Args) => DefinitionFnReturn;

export const getTableColumnDefs: DefinitionFn = ({ setPreview }) => {
  const columns: DefinitionFnReturn = [
    {
      id: 'participation_name',
      accessorKey: 'participation_name',
      cell: (props) => (
        <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'tree_test'>>>
          {...props}
          render={({ row }) => (
            <div className='w-full flex items-center justify-between truncate space-x-2 px-2'>
              <div className='truncate'>{row.original.participation_name || 'Unnamed'}</div>
              {row.original.session_uuid && (
                <a href={`/sessions/${row.original.session_uuid}`} target='_blank'>
                  <VideoCameraSVG className='w-4 h-4' />
                </a>
              )}
            </div>
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader {...props} isStatic>
          Name
        </Table.ColumnHeader>
      )
    },
    {
      id: 'outcome',
      cell: (props) => (
        <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'tree_test'>>>
          {...props}
          render={({ row }) => (
            <div className='px-2'>
              <ClickPointer size='lg' failure={!row.original.completed} />
            </div>
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader {...props} isStatic>
          Outcome <InfoTooltip content='Users who selected the correct answer' />
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => (
        <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'tree_test'>>>
          render={({ row }) => (
            <div className='px-2'>
              <ClickPointer size='lg' failure={!row.original.completed} />
            </div>
          )}
          {...props}
        />
      ),
      id: 'direct',
      accessorFn: (row) => `direct`,
      header: (props) => (
        <Table.ColumnHeader {...props} isStatic>
          Direct <InfoTooltip content='Users who selected the correct and direct answer' />
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'tree_test'>>> {...props} />,
      id: 'total_time',
      accessorFn: (row) => (row.time_in_task ? formatMilliseconds(row.time_in_task) : '0 s'),
      header: (props) => (
        <Table.ColumnHeader {...props} isStatic>
          Total time
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => (
        <Table.Cell<NonNullable<ScreenerResponseAnswerValue<'tree_test'>>>
          {...props}
          render={({ row }) => (
            <Tooltip content='Details'>
              <button
                aria-label='Details'
                onClick={() => setPreview(row.original)}
                className='mx-2 hover:bg-gray-100 inline-flex items-center p-1 border border-gray-200 rounded'
              >
                Reset password
              </button>
            </Tooltip>
          )}
        />
      ),
      id: 'responses',
      header: (props) => (
        <Table.ColumnHeader {...props} isStatic>
          Response
        </Table.ColumnHeader>
      )
    }
  ];

  return columns;
};
