export const NULL_ANSWER: IdealAnswer = { operator: null, value: null };

export const OPERATOR_LABELS: Record<IdealAnswerOperator, string> = {
  contains: 'Contains',
  not_contains: "Doesn't contain",
  starts_with: 'Starts with',
  ends_with: 'Ends with',
  eq: 'Equals',
  not_eq: "Doesn't equal",
  gt: 'Greater than',
  lt: 'Less than',
  between: 'Within',
  contains_all: 'Contains all of',
  contains_any: 'Contains any of',
  contains_none: 'Contains none of'
};

const CHOICE_OVERRIDES = {
  eq: 'Is exact match',
  not_eq: 'Is not'
};

const DATE_OVERRIDES = {
  gt: 'After',
  lt: 'Before',
  between: 'Between'
};
const YES_NO_OVERRIDES = {
  eq: 'Is',
  not_eq: "Isn't"
};
export const OVERRIDE_LABELS: Partial<
  Record<ScreenerField['field_type'], Partial<Record<IdealAnswerOperator, string>>>
> = {
  date: DATE_OVERRIDES,
  multiple_choice: CHOICE_OVERRIDES,
  single_choice: CHOICE_OVERRIDES,
  yes_no: YES_NO_OVERRIDES
};

export const FIELD_OPERATORS: Record<ScreenerField['field_type'], IdealAnswerOperator[]> = {
  short_text: ['contains', 'not_contains', 'eq', 'starts_with', 'ends_with'],
  long_text: ['contains'],
  location: [],
  email: ['contains'],
  website: ['contains'],
  number: ['eq', 'gt', 'lt', 'between'],
  task: [],
  info: [],
  date: ['eq', 'gt', 'lt', 'between'],
  yes_no: ['eq', 'not_eq'],
  start_loom: [],
  stop_loom: [],
  multiple_choice: ['contains_any', 'contains_all', 'contains_none', 'eq'],
  number_range: ['eq', 'gt', 'lt'],
  single_choice: ['eq', 'contains_any'],
  prototype_test: [],
  card_sort: [],
  tree_test: []
};
