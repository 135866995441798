import React, { SVGProps } from 'react';

export const CrossedCircleSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
    <g clipPath='url(#clip0_1951_19651)'>
      <path
        d='M1 8C1 9.85651 1.7375 11.637 3.05025 12.9497C4.36301 14.2625 6.14349 15 8 15C9.85651 15 11.637 14.2625 12.9497 12.9497C14.2625 11.637 15 9.85651 15 8C15 6.14349 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85651 1 8 1C6.14349 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14349 1 8Z'
        stroke='currentColor'
        strokeWidth='0.88'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M3.0498 12.9498L12.9494 3.05029L3.0498 12.9498Z' fill='currentColor' />
      <path
        d='M3.0498 12.9498L12.9494 3.05029'
        stroke='currentColor'
        strokeWidth='0.88'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1951_19651'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
