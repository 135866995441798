import { Card } from '@components/shared/Skeleton';
import { NoParticipantsZDS } from '@components/StudiesApp/components/StudyPublished/components/NoParticipantsZDS';
import { Alerts } from '@components/StudiesApp/components/StudyPublished/components/Alerts';
import { BackgroundTasks } from '@components/shared/BackgroundTasks';
import { useGetStudyBackgroundTasksQuery } from '@components/StudiesApp/components/StudyPublished/api';
import { ZDS } from '@components/StudiesApp/components/StudyPublished/components/ZDS';
import { MessageRequests } from '@components/StudiesApp/components/StudyPublished/components/MessageRequests';
import { PageHeader } from '@components/shared/PageHeader';
import { ParticipationStatusTabs } from '@components/StudiesApp/components/StudyPublished/components/ParticipationStatusTabs';
import { TableFilters, useTableFilters } from '@components/shared/TableFilters';
import { BulkActions } from '@components/StudiesApp/components/StudyPublished/components/BulkActions';
import { Modal, Notification, Option, SlideOut, Spinner, Text } from '@components/common';
import pluralize from 'pluralize';
import {
  getHiddenColumnNames,
  getSelectedString,
  selectSubset,
  updateCellData
} from '@components/shared/GridTable/components/utils';
import { SortDropdown } from '@components/shared/SortDropdown';
import * as React from 'react';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ADD_EXISTING,
  ADD_INTERVIEW,
  ADD_MANUAL,
  CANCEL_INTERVIEW_SLIDEOUT,
  EDIT_CUSTOM_ATTRIBUTES,
  FUND_STUDY,
  INVITE_VIA_LINK,
  REVIEW_TIME_PROPOSAL,
  SEND_INVITES,
  SEND_REMINDERS,
  SEND_SCREENER,
  SEND_THANKS,
  StudyAction,
  UPDATE_MODERATOR
} from '@components/StudiesApp/components/StudyPublished/components/ACTIONS';
import { NotificationProps } from '@components/common/Notification';
import { useFeature } from '@hooks/useFeature';
import { useCollectionView } from '@stores/view';
import { useTeams } from '@hooks/useTeams';
import { compact, removeHash, setHash, uniq, uniqBy, without } from '@components/utils';
import { api } from '@api/reduxApi';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { useToaster } from '@stores/toaster';
import { useUser } from '@hooks/useUser';
import { usePermission } from '@hooks/usePermission';
import { CORE_ATTRS, EXTERNAL_ATTRS } from '@components/config';
import { useCandidateAttrs } from '@hooks/useCandidateAttrs';
import {
  buildParticipationsFilterDefs,
  getPreScreenerFieldId,
  getSurveyScreenerFieldId,
  getCustomerFieldId
} from '@components/StudiesApp/components/StudyPublished/components/buildParticipationsFilterDefs';
import {
  buildParticipantsColumns,
  ParticipationTableItem
} from '@components/StudiesApp/components/StudyPublished/ParticipationTable/helpers/buildParticipantsColumns';
import { getInitialStatus } from '@components/StudiesApp/components/StudyPublished/helpers/getInitialStatus';
import * as toasts from '@components/StudiesApp/components/StudyDraft/toasts';
import { ParticipationPill } from '@components/shared/ParticipationPill';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { ELocalStorageKeys } from '@constants/localStorageKeys';
import { UseRowSelect } from 'components/shared/GridTable/hooks/UseRowSelect';
import {
  useAbsoluteLayout,
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable
} from '@lib/react-table.production.min.js';
import {
  getDateAttrs,
  getStatusAttrs
} from '@components/StudiesApp/components/StudyPublished/ParticipationTable/helpers';
import { calculateNumber } from '@components/GQSurveyBuilder/utils';
import { UpdateCellData } from '@components/shared/GridTable/components/inputs/types';
import { useColumnResizeObserver } from '@components/shared/GridTable/hooks/useColumnResizeObserver';
import { buildColumnTabs } from '@components/StudiesApp/components/StudyPublished/utils';
import { TableStateProvider } from '@components/CandidatesApp/CandidatesIndex/CandidatesListPage';
import { GridTable } from '@components/shared/GridTable';
import { AddCandidates } from '@components/StudiesApp/components/StudyPublished/components/AddCandidates';
import { InviteLink } from '@components/StudiesApp/components/StudyPublished/components/InviteLink';
import { AddCandidateSlideOut } from '@components/shared/AddCandidateSlideOut';
import { NewInterview } from '@components/NewInterview';
import { ProfileContextProvider } from '@hooks/useProfileContext';
import { StudyAttrsSlideout } from '@components/StudiesApp/components/StudyPublished/components/StudyAttrsSlideout';
import { ProfileSlideout } from '@components/RepoSessionApp/ProfileSlideout';
import { EmailSlideOuts } from '@components/StudiesApp/components/StudyPublished/components/EmailSlideouts';
import { ReviewTimeProposalSlideout } from '@components/StudiesApp/components/StudyPublished/components/ReviewTimeProposalSlideout';
import { Paginator } from '@components/shared/Paginator';
import {
  getProfileSlideOutSections,
  getSortPrefix
} from '@components/StudiesApp/components/StudyPublished/components/ParticipantsTab/utils';
import { ParticipantsActions } from '@components/StudiesApp/components/StudyPublished/components/ParticipantsTab/components/ParticipantsActions';
import { parse } from '@components/shared/TableFilters/utils/encode';
import { useServerSideParticipations } from '@hooks/useServerSideParticipations';
import { failedAttrUpdate } from '@components/CandidateProfile';
import { TableSkeleton } from 'components/shared/GridTable/components';
import { makeStatesFromProxies } from '@components/shared/TableFilters/components/segments/utils';
import { CalendarEventSlideOut } from '@components/shared/CalendarEventSlideOut';
import { SelectSubsetDropdown } from 'components/shared/GridTable/components/SelectSubsetDropdownServerSide';
import { useSelectSubset } from './hooks/useSelectSubset';
import { InviteViaLinkSlideout } from '../InviteViaLinkSlideout';

interface Props {
  study: Study;
  backgroundTasks: BackgroundTask[];
  setBackgroundTasks: (tasks: BackgroundTask[]) => void;
  refetch: () => void;
}

export const ParticipantsTabServerSide: FC<Props> = ({
  study,
  backgroundTasks,
  setBackgroundTasks,
  refetch: refetchStudy
}) => {
  const [showSelectAll, setShowSelectAll] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [activeParticipation, setActiveParticipation] = useState<Participation | null>();
  const [profileSlideoutOpen, setProfileSlideoutOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [mode, setMode] = useState<StudyAction | null>(null);
  const [addCandidatesDefaultStudyLimit, setAddCandidatesDefaultStudyLimit] = useState<StudyLimit | null>(null);
  const [notification, setNotification] = useState<Omit<NotificationProps, 'onClose'> | null>();
  const [query, setQuery] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>(query);
  const [selectedParticipations, setSelectedParticipations] = useState<Participation[]>([]);

  const [storedSort, setStoredSort] = useLocalStorage<{ value: string; desc: boolean }>('participations-sort-value');
  const [columnsWidth, setColumnsWidth] = useLocalStorage<Record<string, number>>(
    ELocalStorageKeys.PARTICIPANTS_TABLE_COLUMNS_WIDTH
  );

  const [sort, setSort] = useState<{ value: string; desc: boolean }>({
    value: storedSort?.value || 'customer:name',
    desc: !!storedSort?.desc
  });

  const { style, currencySymbol, focus_group, on_gq, pre_screener, survey_screener } = study;

  const enableTeams = useFeature('teams');
  const enableInviteViaLinkSlideout = useFeature('invite_via_link');

  const canUpdate = usePermission<Study>('updateStudy')(study);

  const participantLevelIncentives = useFeature('participant_level_incentives');
  const hasParticipantLevelIncentives = participantLevelIncentives && canUpdate;

  const { view, setView } = useCollectionView();

  const teamId = enableTeams ? study.team_id : null;
  const { teams, findTeam } = useTeams({ skip: !enableTeams });
  const team = (teamId && findTeam(teamId)) || null;

  const screeningAttrs = [
    'preScreenerField.ideal_response',
    'preScreenerField.ideal_answers_count',
    ...(pre_screener?.fields || []).map((q) => getPreScreenerFieldId(q.id, true)),
    ...(study.has_external_candidates_requests ? EXTERNAL_ATTRS.map((a) => `extra.${a.name}`) : [])
  ];

  const initialAttrs = (study.visible_attrs || []).map((attr) =>
    ['company', 'title', 'location'].includes(attr) ? `extra.${attr}` : attr
  );

  const [visibleColumnNames, setVisibleColumnNames] = useState<string[]>(
    uniq(['name', 'rating', 'moderator', 'interview', 'interview_at', ...screeningAttrs, ...initialAttrs])
  );
  const { data: slots } = api.useGetStudySlotsBalanceQuery(study.id, { skip: !study.id });
  const { data: messageRequests, refetch: refetchMessageRequests } = api.useGetMessageRequestsQuery(study.id, {
    skip: !study.id
  });

  const [updateCandidate] = api.useUpdateCandidateMutation();

  const { data: studyLimits } = api.useGetStudyLimitsQuery(study.id);
  const {
    data: studyLimitsProgress,
    isSuccess: isSuccessStudyLimitsProgress,
    refetch: refetchStudyLimitsProgress
  } = api.useGetStudyLimitsProgressQuery(study.id, {
    skip: !studyLimits || studyLimits.length < 1
  });

  useEffect(() => {
    if (studyLimits && studyLimits.length > 0 && !visibleColumnNames.includes('segment')) {
      setVisibleColumnNames(['segment', ...visibleColumnNames]);
    }
  }, [studyLimits, visibleColumnNames]);

  const [searchParams, setSearchParams] = useSearchParams();

  const [updateStudy, { isError: updateError }] = api.useUpdateStudyMutation();
  const [updateParticipation] = api.useUpdateParticipationMutation();
  const skip = !!activeParticipation || !!mode || profileSlideoutOpen || query !== '';

  const { candidateAttrs, setCandidateAttrs, create } = useCandidateAttrs();
  const [currentStatus, setCurrentStatus] = useState<ParticipationStatus>(
    (view.tableTab as ParticipationStatus) || 'shortlisted'
  );
  const definitions = useMemo(
    () =>
      buildParticipationsFilterDefs({
        hasExternalCandidates: study.has_external_candidates_requests,
        preScreenerQuestions: pre_screener?.fields || [],
        surveyScreenerQuestions: survey_screener?.fields || [],
        customAttrs: candidateAttrs,
        segments: [],
        enableTeams,
        teams,
        preScreenerHasIdealAnswers: pre_screener?.has_ideal_answers || false,
        style: study.style,
        hasScreener: study.has_screener,
        onGQ: study.on_gq,
        serverSideParticipations: true
      }),
    [
      candidateAttrs,
      teams,
      study.on_gq,
      study.has_screener,
      pre_screener,
      survey_screener?.fields,
      study.has_external_candidates_requests
    ]
  );

  const filtersHook = useTableFilters<Participation>({
    trackKey: 'participations',
    definitions,
    query,
    syncWithURL: !searchParams.get('review_id')
  });

  const filters: string[] = filtersHook.filters.map(parse).filter(Boolean);

  const {
    participations = [],
    isFetching: isFetchingParticipations,
    isError: isErrorParticipations,
    initialFetchDone: initialFetchDoneParticipations,
    refetch: refetchParties,
    totalPages,
    counts,
    updateParticipation: updateParticipationCache
  } = useServerSideParticipations({
    studyId: study.id,
    preScreenerId: pre_screener?.id,
    surveyScreenerId: survey_screener?.id,
    page,
    sort: getSortPrefix(sort.value, definitions),
    sort_desc: sort.desc,
    op: filtersHook.op,
    searchQuery: query,
    filters,
    currentStatus,
    skip
  });

  const showToast = useToaster();
  const user = useUser();

  const canPII = usePermission('showPII')();

  const hiddenCore = ['first_name', 'last_name', ...(canPII ? [] : ['email', 'phone_number'])];

  const coreAttrs = CORE_ATTRS.filter((c) => !hiddenCore.includes(c.name));

  useEffect(() => {
    if (isErrorParticipations) {
      showToast({
        heading: 'An error occurred',
        icon: 'error',
        text: 'We couldn’t fetch participations for this study. Please try again later.'
      });
    }
  }, [isErrorParticipations]);

  const setStatus = (status: ParticipationStatus) => {
    setCurrentStatus(status);
    setAllSelected(false);
    setSelectedParticipations([]);
    setView({ tableTab: status });
  };

  // automatically open review time proposal slideout if arriving with a query apram
  const reviewTimeProposalParam = searchParams.get('review_id');
  const reviewTimeProposalSlideoutInitialOpenRef = useRef(false);

  useEffect(() => {
    if (
      participations.length > 0 &&
      mode === null &&
      !reviewTimeProposalSlideoutInitialOpenRef.current &&
      reviewTimeProposalParam
    ) {
      const partyId = parseInt(reviewTimeProposalParam);
      const party = participations.find((p) => p.id === partyId);

      if (!party?.time_proposal?.times.length) return;

      setActiveParticipation(party);
      reviewTimeProposalSlideoutInitialOpenRef.current = true;
      setMode(REVIEW_TIME_PROPOSAL);
    }
  }, [participations, reviewTimeProposalParam]);

  const data = useMemo<ParticipationTableItem[]>(() => {
    // this places a limit onto every participation object before its passed to the table
    const limits = studyLimitsProgress || {};
    const partyIdToLimit = (partyId: number) => {
      if (!studyLimits) return null;
      const limitId = Object.keys(limits).find((id) => limits[id].participation_ids.includes(partyId));
      const limit = studyLimits.find((l) => String(l.id) === limitId);
      return limit ? { order: limit.order, name: limit.name } : null;
    };
    return participations?.map((p) => ({
      participation: { ...p, name: p.customer?.name },
      limit: partyIdToLimit(p.id)
    }));
  }, [participations, studyLimits, studyLimitsProgress]);

  const defaultStatus = searchParams.get('status');

  useEffect(() => {
    if (initialFetchDoneParticipations && !view.tableTab) {
      let initialStatus: ParticipationStatus;

      if (defaultStatus) {
        initialStatus = defaultStatus?.toString() as ParticipationStatus;
        searchParams.delete('status');
        setSearchParams(searchParams);
      } else {
        initialStatus = getInitialStatus(counts);
      }
      if (initialStatus !== currentStatus) {
        setStatus(initialStatus);
      }
    }
  }, [initialFetchDoneParticipations, counts]);

  const handleHash = (participations: Participation[]) => {
    const hash = window.location.hash;

    if (hash) {
      const [path, id] = hash.substring(1).split('/');

      if (path === 'candidates') {
        const p = participations.find(({ customer_id }) => id.toString() === customer_id.toString());
        if (p) {
          setActiveParticipation(p);
          setProfileSlideoutOpen(true);
          setStatus(p.status);
        } else {
          removeHash();
        }
      } else if (path === 'fund') {
        setMode(FUND_STUDY);
      } else if (path === 'invite') {
        setMode(INVITE_VIA_LINK);
      } else {
        // Unhandled
      }
    }
  };

  useEffect(() => {
    if (participations.length) {
      handleHash(participations);
    }
  }, [participations]);

  useEffect(() => {
    setSelectedIds([]);
    setAllSelected(false);
    setSelectedParticipations([]);
  }, [currentStatus]);

  const handleAddCustomer = async (backgroundTask: BackgroundTask) => {
    if (backgroundTask) {
      setBackgroundTasks([...backgroundTasks, backgroundTask]);
      refreshStudy();
    }
    setMode(null);
    setStatus('shortlisted');
  };

  const handleClickRow = async (participation: Participation) => {
    if (participation.id === activeParticipation?.id) {
      unsetActiveParticipation();
    } else {
      setActiveParticipation(participation);
      setProfileSlideoutOpen(true);
      setHash(`candidates/${participation.customer_id}`);
    }
  };

  const handleClickRowModerator = (participation: Participation) => {
    setActiveParticipation(participation);
    setMode(UPDATE_MODERATOR);
  };

  const handleClickReviewTimeProposal = (participation: Participation) => {
    setActiveParticipation(participation);
    setMode(REVIEW_TIME_PROPOSAL);
  };

  const handleSelectIncentive = async (participation: Participation, value: number) => {
    await updateParticipation({ id: participation.id, incentive_in_cents: value * 100 });
    refetchStudy();
    refetchParties();
  };

  const handleClickRateParticipation = async (participation: Participation, rating: number) => {
    await updateParticipation({ id: participation.id, rating: rating });
    refetchParties();
  };

  const refreshStudy = async (fetchParties = true, resetMode = true) => {
    setLoading(true);
    removeHash();

    if (fetchParties) {
      refetchParties();
      if (isSuccessStudyLimitsProgress) {
        refetchStudyLimitsProgress();
      }
    }
    setLoading(false);
    if (resetMode) {
      setMode(null);
    }
  };

  useEffect(() => {
    if (updateError) {
      showToast(toasts.failedUpdate());
    }
  }, [updateError]);

  const onChangeCandidate = (customer: Candidate) => {
    if (activeParticipation) {
      const newParticipation = { ...activeParticipation, customer };
      setActiveParticipation(newParticipation);
      refetchParties();
    }
  };

  const onCloseProfileSlideout = () => {
    if (mode !== SEND_THANKS && mode !== SEND_INVITES && mode !== SEND_REMINDERS) {
      setActiveParticipation(null);
      setProfileSlideoutOpen(false);
      removeHash();
    }
  };

  const renderProfileSlideoutHeader = () => (
    <div className='flex items-center space-x-2'>
      <span className='h600 block'>{study.title}</span>
      {activeParticipation && <ParticipationPill participation={activeParticipation} />}
    </div>
  );

  const renderProfileSlideoutActions = (activeParticipation: Participation) => () =>
    canUpdate && (
      <BulkActions
        onSlideoutActions
        canSchedule={!!slots?.remaining && slots.remaining >= 1}
        participations={participations}
        closeAll={closeAll}
        selectedIds={[activeParticipation.id]}
        study={study}
        currentStatus={currentStatus}
        onSuccess={handleParticipationsUpdate}
        setLoading={setLoading}
        mode={mode}
        closeProfileSlideout={() => setProfileSlideoutOpen(false)}
        setMode={setMode}
        candidateId={activeParticipation.customer_id}
        query={bulkQuery}
        allSelected={allSelected}
      />
    );

  const { handleMouseOver, handleToggleCheckBox, resetOnClick, hoveredRows } = UseRowSelect();

  const toggleColVisibility = (name: string): void => {
    const newAttrs = visibleColumnNames.includes(name)
      ? compact(without(visibleColumnNames, name))
      : [...visibleColumnNames, name];

    setVisibleColumnNames(newAttrs);

    updateStudy({ id: study.id, visible_attrs: newAttrs });
  };

  const isVisible = (name: string) => visibleColumnNames.includes(name);

  const setSortValue = (value: string, desc: boolean): void => {
    setSort({ value, desc });
    setStoredSort({ value, desc });
  };

  const onSortChange = ({ value, desc = false }: { value: string; desc: boolean }): void => {
    setSortBy([{ id: value, desc }]);
    setSortValue(value, desc);
  };

  const onGQ = ['video_call', 'unmoderated_test'].includes(style) || on_gq;

  const addFilter = (filter: string) => {
    const def = definitions.find((d) => d.id === filter);

    if (def) {
      filtersHook.addFilter(def);
    }
  };

  useEffect(() => {
    if (!allSelected) {
      setShowSelectAll(false);
    }
  }, [view.sort]);

  const allColumns = useMemo(() => {
    const builtColumns = buildParticipantsColumns({
      definitions,
      columnsWidth,
      addFilter,
      preScreenerHasIdealAnswers: pre_screener?.has_ideal_answers || false,
      preScreenerQuestions: pre_screener?.fields || [],
      surveyScreenerQuestions: survey_screener?.fields || [],
      customAttrs: candidateAttrs,
      canPII,
      onClickParticipation: (row) => handleClickRow(row.participation),
      onClickModerator: (row) => handleClickRowModerator(row.participation),
      onClickReviewTimeProposal: (row) => handleClickReviewTimeProposal(row.participation),
      onSelectIncentive: (row, value) => handleSelectIncentive(row.participation, value),
      onClickRateParticipation: (row, rating) => handleClickRateParticipation(row.participation, rating),
      status: currentStatus,
      style,
      currencySymbol,
      focus_group,
      hasScreener: study.has_screener,
      hasExternalCandidates: study.has_external_candidates_requests,
      onGQ,
      isVisible,
      setSortValue,
      handleMouseOver,
      handleToggleCheckBox: (e) => {
        setAllSelected(false);
        handleToggleCheckBox(e);
      },
      setAllSelected,
      setShowSelectAll,
      hasParticipantLevelIncentives,
      resetOnClick: () => {
        if (selectedSubsetIds.length) {
          setSelectedIds([]);
          resetSubset();
        }

        resetOnClick();
      },
      hoveredRows,
      teams,
      serverSideParticipationsEnabled: true,
      enableTeams,
      incentiveMethod: study.incentive_method
    });

    return builtColumns.map((c) => ({
      ...c,
      toggleColVisibility
    }));
  }, [
    definitions,
    filtersHook.filters,
    visibleColumnNames,
    candidateAttrs,
    handleMouseOver,
    handleToggleCheckBox,
    hoveredRows,
    pre_screener,
    survey_screener,
    currentStatus,
    study.has_external_candidates_requests,
    participations,
    teams
  ]);

  type SortableOption<T extends string = string> = Option<T> & {
    disableSort?: boolean;
  };

  const columnOptions: SortableOption[] = useMemo(
    () =>
      [
        {
          name: 'contact_access',
          label: 'Contact access'
        },
        ...coreAttrs.filter((c) => !c.hide).map((c) => ({ name: getCustomerFieldId(c.name, true), label: c.label })),
        ...(canPII ? [{ name: getCustomerFieldId('email', true), label: 'Email' }] : []),
        ...(study.incentive_method == 'coupon' ? [{ name: 'incentive_coupon', label: 'Coupon Code' }] : []),
        ...(study.incentive_method != 'coupon' && hasParticipantLevelIncentives
          ? [{ name: 'incentive_in_whole_currency', label: 'Incentive', disableSort: true }]
          : []),
        ...(study.has_external_candidates_requests ? [{ name: 'recruiting_source', label: 'Source' }] : []),
        { name: 'customer:participations_count', label: '# of studies' },
        { name: 'token', label: 'Token' },
        { name: 'customer:eligible', label: 'Eligible' },
        { name: 'customer:last_contacted_at', label: 'Last invited' },
        ...getDateAttrs(style, study.has_screener, onGQ),
        ...getStatusAttrs(currentStatus, style, onGQ, true),
        ...candidateAttrs.map((a) => ({ name: `extra.${a.name}`, label: a.label })),
        ...(study.has_external_candidates_requests
          ? EXTERNAL_ATTRS.map((a) => ({ name: `extra.${a.name}`, label: a.label }))
          : []),
        ...(pre_screener?.fields || []).map((question, i) => ({
          name: getPreScreenerFieldId(question.id, true),
          label: question.label || `Screener Question #${calculateNumber(pre_screener?.fields || [], i)}`
        })),
        ...(survey_screener?.fields || []).map((question, i) => ({
          name: getSurveyScreenerFieldId(question.id, true),
          label: question.label || `Survey Question #${calculateNumber(survey_screener?.fields || [], i)}`
        }))
      ].map(({ name, label, disableSort }: { name: string; label: string; disableSort?: boolean }) => ({
        label,
        value: name,
        disableSort: disableSort || false
      })),

    [candidateAttrs, currentStatus, pre_screener, survey_screener, 0]
  );

  const hiddenColumns = useMemo(() => {
    return getHiddenColumnNames(columnOptions, visibleColumnNames);
  }, [columnOptions, visibleColumnNames]);

  const updateParticipationData: UpdateCellData = useCallback(
    async (candidateId, attrName, newValue) => {
      try {
        const updatedResult: Candidate = await updateCandidate({ id: candidateId, [attrName]: newValue }).unwrap();

        showToast(toasts.successUpdateProfile());

        const participationTableItem = data.find((p) => p.participation.customer_id === candidateId);
        if (!participationTableItem) {
          return;
        }
        const participation: Participation = participationTableItem.participation;

        const updatedParticipation: Participation = {
          ...participation,
          customer: {
            ...updatedResult
          }
        };
        updateParticipationCache(updatedParticipation);
      } catch (e) {
        showToast(failedAttrUpdate(e));
      }
    },
    [data]
  );

  const getRowId = React.useCallback((row) => {
    return row.participation.id;
  }, []);

  const tableInstance = useTable<ParticipationTableItem>(
    {
      columns: allColumns,
      data,
      updateCellData: updateParticipationData,
      autoResetFilters: false,
      autoResetPage: false,
      autoResetSelectedRows: false,
      defaultColumn: { disableFilters: true },
      manualPagination: true,
      manualSortBy: true,
      getRowId,
      pageCount: totalPages,
      initialState: {
        pageIndex: page - 1,
        hiddenColumns,
        sortBy: [{ id: view.sort.value, desc: view.sort.desc }],
        selectedRowIds: {}
      }
    },
    useAbsoluteLayout,
    useResizeColumns,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useColumnOrder,
    useRowSelect
  );

  const {
    setSortBy,
    rows,
    visibleColumns,
    gotoPage,
    pageOptions,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { selectedRowIds, pageIndex, columnOrder },
    toggleRowSelected,
    toggleAllRowsSelected,
    totalColumnsWidth,
    setSelectedRowIds
  } = tableInstance;

  const selectedIds = useMemo(() => Object.keys(selectedRowIds).map((str) => parseInt(str)), [selectedRowIds]);

  const setSelectedIds = (ids: number[]) => {
    setSelectedRowIds(ids.reduce((a, v) => ({ ...a, [v]: true }), {}));
  };

  useEffect(() => {
    setSelectedParticipations((prev) => {
      if (selectedIds.length === 0) {
        return [];
      } else if (selectedIds.length > prev.length) {
        return uniqBy([...prev, ...participations.filter((p) => selectedIds.includes(p.id))], (c) => c.id);
      } else if (selectedIds.length === prev.length) {
        return prev;
      } else {
        return prev.filter((p) => selectedIds.includes(p.id));
      }
    });
  }, [selectedIds]);

  const slideOutParties: Participation[] = useMemo(() => {
    if (activeParticipation) {
      return [activeParticipation];
    } else {
      return selectedParticipations;
    }
  }, [activeParticipation, selectedParticipations]);

  const selectedString = getSelectedString(allSelected, selectedIds.length);

  const onResize = (columnId: string, columnSize: number) => {
    setColumnsWidth({ ...columnsWidth, [columnId]: columnSize });
  };

  useColumnResizeObserver(tableInstance.state, onResize);

  useEffect(() => {
    setPage(1);
    gotoPage(0);
  }, [filtersHook.filters, query, currentStatus]);

  useEffect(() => setPage(pageIndex + 1), [pageIndex]);

  useEffect(() => {
    if (page && totalPages && page > totalPages) {
      setPage(totalPages);
      gotoPage(totalPages - 1);
    }
  }, [page, totalPages]);

  useEffect(() => {
    if (allSelected) {
      setSelectedRowIds(participations.reduce((a, v) => ({ ...a, [v.id]: true }), {}));
    }
  }, [participations]);

  const switchCandidate = (shift: number) => {
    if (!activeParticipation) return;

    const currentIndex = rows.findIndex((p) => p.original.participation.id === activeParticipation.id);

    if (rows.length === currentIndex + shift) {
      setActiveParticipation(rows[0].original.participation);
    } else if (currentIndex + shift < 0) {
      setActiveParticipation(rows[rows.length - 1].original.participation);
    } else {
      setActiveParticipation(rows[currentIndex + shift].original.participation);
    }
  };

  const unsetActiveParticipation = () => {
    setSelectedIds([]);
    setActiveParticipation(null);
    toggleAllRowsSelected(false);
    setShowSelectAll(false);
    setAllSelected(false);
    removeHash();
  };

  const handleParticipationsUpdate = (backgroundTask?: BackgroundTask): void => {
    if (backgroundTask) {
      setBackgroundTasks([...backgroundTasks, backgroundTask]);
      refreshStudy(true, false);
    }

    unsetActiveParticipation();
  };

  const handleAddManual: (params: { candidate: Candidate; background_task?: BackgroundTask }) => void = ({
    background_task
  }) => {
    setStatus('shortlisted');
    if (background_task) {
      setBackgroundTasks([...backgroundTasks, background_task]);
      refreshStudy(true, false);
    }
  };

  const closeAll = () => {
    setMode(null);
    setActiveParticipation(null);
    removeHash();
  };

  const handleEmailSuccess = (backgroundTask?: BackgroundTask, mode?: StudyAction) => {
    if (backgroundTask) {
      setBackgroundTasks([...backgroundTasks, backgroundTask]);
    }
    setMode(null);
    unsetActiveParticipation();
    refreshStudy();

    mode === SEND_SCREENER && setStatus('invited');
    mode && ![CANCEL_INTERVIEW_SLIDEOUT, SEND_INVITES].includes(mode) && refetchMessageRequests();
  };

  const showColumns = async (cols: string[]) => {
    setVisibleColumnNames(cols);

    await updateStudy({ id: study.id, visible_attrs: cols });
  };

  const gridTableProps = React.useMemo(
    () => ({
      tableInstance,
      tableColumnsOrder: ELocalStorageKeys.PARTICIPANTS_TABLE_ORDER,
      columnOptions,
      setVisibleColumnNames: showColumns,
      visibleColumnNames,
      tabs: buildColumnTabs(study),
      pagination: true
    }),
    [totalColumnsWidth, columnOptions, visibleColumnNames, selectedRowIds, rows, pageIndex, columnOrder]
  );

  const subsetButtonIsHidden = Object.keys(selectedRowIds).length > 0;

  const urlEncodedFilters: string[] = useMemo(() => {
    const segmentFilters = addCandidatesDefaultStudyLimit
      ? makeStatesFromProxies(
          filtersHook.definitions,
          addCandidatesDefaultStudyLimit.filters.filters,
          filtersHook.filterMetas
        )
      : [];

    const encodedFilters = compact(
      [...filtersHook.filters, ...segmentFilters].map((filter) => parse<Participation>(filter) || '')
    );

    if (enableTeams && teamId) {
      encodedFilters.push(`team_ids includes_any ${teamId}`);
    }

    return [`status is ${currentStatus}`, ...encodedFilters];
  }, [filters, addCandidatesDefaultStudyLimit, filtersHook.definitions, teamId]);

  const bulkQuery: ServerFilterQuery = useMemo<ServerFilterQuery>(() => {
    return {
      count: counts?.[currentStatus] || 0,
      op: filtersHook.op,
      q: query,
      sort: getSortPrefix(sort.value, definitions),
      sort_desc: sort.desc,
      filters: urlEncodedFilters
    };
  }, [counts, currentStatus, filtersHook.op, query, sort, urlEncodedFilters]);

  const [selectedSubsetIds, { isLoading: isLoadingSubset, reset: resetSubset, setSubset }] = useSelectSubset({
    query: bulkQuery,
    studyId: study.id,
    onError: () => {
      showToast({
        heading: 'Failed to select a subset',
        text: 'Something went wrong. Please try again later.',
        icon: 'error'
      });
    },
    onSuccess: setSelectedIds
  });

  const Table = React.useMemo(() => {
    return (
      <TableStateProvider>
        <GridTable {...gridTableProps} />
      </TableStateProvider>
    );
  }, [gridTableProps]);

  if (!initialFetchDoneParticipations) {
    return (
      <div className='px-page py-gutter h-full overflow-hidden rounded'>
        <Card height='100%' />
      </div>
    );
  }

  const showZds = !query && !filters.length && counts && Object.values(counts).every((v) => v === 0);

  if (showZds) {
    if (study.state === 'closed') return <NoParticipantsZDS />;

    return (
      <div className='px-page py-gutter'>
        <Alerts
          slots={slots}
          user={user}
          study={study}
          openFundModal={() => setMode(FUND_STUDY)}
          canAction={canUpdate}
          keys={['must_fund', 'pending_funding', 'funding_required']}
        />

        <BackgroundTasks
          onFinished={() => refreshStudy(true, false)}
          setBackgroundTasks={setBackgroundTasks}
          backgroundTasks={backgroundTasks}
          params={{ objectId: study.id }}
          backgroundTasksQuery={useGetStudyBackgroundTasksQuery}
        />

        <ZDS
          study={study}
          setMode={setMode}
          setAddCandidatesDefaultStudyLimit={setAddCandidatesDefaultStudyLimit}
          studyLimits={studyLimits}
        />
        {mode === ADD_EXISTING && (
          <Modal fullScreen onClose={closeAll} closeOnEsc={false}>
            <div className='h-full mt-10 overflow-y-auto'>
              <div className='mb-10'>
                <AddCandidates
                  study={study}
                  teamId={teamId}
                  onAdd={handleAddCustomer}
                  refetchParties={refetchParties}
                  studySegment={addCandidatesDefaultStudyLimit}
                />
              </div>
            </div>
          </Modal>
        )}
        <EmailSlideOuts
          allSelected={allSelected}
          query={bulkQuery}
          mode={mode}
          study={study}
          participations={[]}
          onClose={closeAll}
          onSuccess={handleEmailSuccess}
        />
        {!enableInviteViaLinkSlideout && mode === INVITE_VIA_LINK && (
          <Modal onClose={closeAll} size='md'>
            <InviteLink study={study} />
          </Modal>
        )}
        {enableInviteViaLinkSlideout && mode === INVITE_VIA_LINK && (
          <InviteViaLinkSlideout study={study} onClose={closeAll} />
        )}
        {mode === ADD_MANUAL && (
          <AddCandidateSlideOut
            style='addCandidate'
            onClose={closeAll}
            studyId={study.id}
            onAdd={handleAddManual}
            team={team}
          />
        )}
      </div>
    );
  }

  const isLoading = loading || isFetchingParticipations;

  const canSchedule = !!slots?.remaining && slots.remaining >= selectedIds.length;

  const selected = allSelected
    ? selectedParticipations.map(({ id }) => id)
    : Object.keys(selectedRowIds).map((id) => parseInt(id));

  return (
    <div className='w-full'>
      <Routes>
        <Route path='/calendar_events/:id' element={<CalendarEventSlideOut />} />
      </Routes>
      <MessageRequests
        className='desktop:px-8 px-4 mt-6'
        study={study}
        messageRequests={messageRequests}
        canAction={canUpdate}
      />
      <Alerts
        className='desktop:px-8 px-4 mt-6'
        slots={slots}
        user={user}
        study={study}
        openFundModal={() => setMode(FUND_STUDY)}
        canAction={canUpdate}
        keys={['must_fund', 'batching_on', 'pending_funding', 'funding_required']}
      />
      <div className='desktop:px-8 w-full px-4'>
        <BackgroundTasks
          onFinished={() => refreshStudy(true, false)}
          setBackgroundTasks={setBackgroundTasks}
          backgroundTasks={backgroundTasks}
          params={{ objectId: study.id }}
          backgroundTasksQuery={useGetStudyBackgroundTasksQuery}
        />
      </div>
      <div>
        <PageHeader
          className='desktop:px-8 px-4 pt-6'
          transparent
          filtersApplied={!!filtersHook.filters.length}
          renderTabs={() => (
            <div className='desktop:px-8 px-4'>
              <ParticipationStatusTabs
                current={currentStatus}
                counts={counts}
                study={study}
                onClick={unsetActiveParticipation}
                onSelect={setStatus}
              />
            </div>
          )}
          searchProps={{
            inputClassName: 'h-8 leading-4',
            addDebounce: true,
            onEnd: setQuery,
            onSearch: setSearchQuery,
            value: searchQuery
          }}
          renderFilters={() => (
            <div className='desktop:px-8 flex-1 px-4'>
              <TableFilters<Participation> hook={filtersHook} defaultShowInput={!filtersHook.filters.length} />
            </div>
          )}
          renderBulkActions={() => (
            <ParticipantsActions
              currentStatus={currentStatus}
              canSchedule={canSchedule}
              selectedIds={selected}
              participations={selectedParticipations}
              closeAll={closeAll}
              study={study}
              visibleColumns={visibleColumns}
              mode={mode}
              setMode={setMode}
              setLoading={setLoading}
              handleParticipationsUpdate={handleParticipationsUpdate}
              columnOptions={columnOptions}
              visibleColumnNames={visibleColumnNames}
              studyLimits={studyLimits}
              setAddCandidatesDefaultStudyLimit={setAddCandidatesDefaultStudyLimit}
              rows={rows}
              query={bulkQuery}
              allSelected={allSelected}
            />
          )}
          h1='Participants'
          team={team}
        />

        <div className='relative'>
          <div className='desktop:px-8 flex flex-col px-4 overflow-x-auto'>
            <div className='flex items-center py-2'>
              {(isLoading || isLoadingSubset) && <Spinner className='w-4 h-4 mr-2' />}
              <Text h='400' color='gray-500'>
                {(counts?.[currentStatus] || 0).toLocaleString()}{' '}
                {pluralize('participant', counts?.[currentStatus] || 0)} {selectedString}
                {showSelectAll && (
                  <button
                    className='p-0 m-0 text-indigo-600'
                    onClick={() => {
                      setAllSelected(true);
                      setShowSelectAll(false);
                    }}
                  >
                    Select all {(counts?.[currentStatus] || 0).toLocaleString()} instead
                  </button>
                )}
              </Text>
              <SelectSubsetDropdown subsetButtonIsHidden={subsetButtonIsHidden} onSelect={setSubset} />
              <div className='flex justify-end flex-1'>
                <Paginator
                  onClickNext={nextPage}
                  onClickPrevious={previousPage}
                  canClickNext={canNextPage}
                  canClickPrevious={canPreviousPage}
                  current={pageIndex + 1}
                  total={pageOptions.length || 1}
                />
                <SortDropdown
                  wrapperClass='ml-2'
                  options={columnOptions.filter((option) => !option.disableSort)}
                  value={sort.value}
                  onChange={onSortChange}
                />
              </div>
            </div>
            {!isLoading && !isErrorParticipations && Table}
            {isLoading && <TableSkeleton />}
          </div>
        </div>
      </div>
      {notification && <Notification {...notification} onClose={() => setNotification(null)} />}
      {mode === ADD_EXISTING && (
        <Modal fullScreen onClose={closeAll} closeOnEsc={false}>
          <div className='h-full mt-10 overflow-y-auto'>
            <div className='mb-10'>
              <AddCandidates
                study={study}
                teamId={teamId}
                onAdd={handleAddCustomer}
                refetchParties={refetchParties}
                studySegment={addCandidatesDefaultStudyLimit}
              />
            </div>
          </div>
        </Modal>
      )}

      <EmailSlideOuts
        mode={mode}
        study={study}
        participations={slideOutParties}
        allSelected={allSelected}
        onClose={closeAll}
        onSuccess={handleEmailSuccess}
        query={bulkQuery}
      />
      {mode === REVIEW_TIME_PROPOSAL && activeParticipation && (
        <ReviewTimeProposalSlideout
          participation={activeParticipation}
          onClose={closeAll}
          onSubmit={(backgroundTask) => {
            if (backgroundTask) {
              setBackgroundTasks([...backgroundTasks, backgroundTask]);
              refreshStudy();
            }
            closeAll();
          }}
        />
      )}

      {!enableInviteViaLinkSlideout && mode === INVITE_VIA_LINK && (
        <Modal onClose={closeAll} size='md'>
          <InviteLink study={study} />
        </Modal>
      )}
      {enableInviteViaLinkSlideout && mode === INVITE_VIA_LINK && (
        <InviteViaLinkSlideout study={study} onClose={closeAll} />
      )}

      {mode === ADD_MANUAL && (
        <AddCandidateSlideOut
          style='addCandidate'
          onClose={closeAll}
          studyId={study.id}
          onAdd={handleAddManual}
          team={team}
        />
      )}
      {mode === ADD_INTERVIEW && <NewInterview study={study} onClose={closeAll} />}
      {mode === EDIT_CUSTOM_ATTRIBUTES && (
        <ProfileContextProvider>
          <StudyAttrsSlideout study={study} setLoading={setLoading} onClose={closeAll} />
        </ProfileContextProvider>
      )}

      {activeParticipation?.customer && profileSlideoutOpen && (
        <ProfileContextProvider>
          <ProfileSlideout
            canUpdate={canUpdate}
            switchCandidate={switchCandidate}
            createAttr={create}
            key={`party-${activeParticipation.id}`}
            header={renderProfileSlideoutHeader()}
            activeParticipation={activeParticipation}
            candidate={activeParticipation.customer}
            onChangeCandidate={onChangeCandidate}
            open={profileSlideoutOpen}
            setLoading={setLoading}
            onClose={onCloseProfileSlideout}
            renderActions={renderProfileSlideoutActions(activeParticipation)}
            showSections={getProfileSlideOutSections({ study, activeParticipation })}
          />
        </ProfileContextProvider>
      )}
    </div>
  );
};
