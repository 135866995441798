import cn from 'classnames';
import React from 'react';

import { CalendarWithClockSVG, ChevronRightSVG, CogSVG } from '@components/svgs';
import { ErrorMessage, Text } from '@components/common';
import { capitalize, humanize } from '@components/utils';
import { AttendeesSVG } from '@components/svgs/Attendees';
import { AdditionalOptionsSVG } from '@components/svgs/AdditionalOptions';
import { INLINE_ERROR_MESSAGES } from 'components/shared/BookabilityAlerts/BookabilityAlerts';

export type PanelType = 'event_details' | 'attendees' | 'availability' | 'additional_options';

interface Props {
  onClick: (v: PanelType) => void;
  type: PanelType;
  className?: string;
  inlineErrors?: {
    bookability?: Bookability;
    rules: BookabilityRule[];
  };
}

const BUTTON_ICONS = {
  event_details: <CogSVG className='flex-shrink-0 w-6 h-6' />,
  attendees: <AttendeesSVG className='flex-shrink-0' />,
  availability: <CalendarWithClockSVG className='flex-shrink-0 w-6 h-6' />,
  additional_options: <AdditionalOptionsSVG className='flex-shrink-0' />
};

export const hasInlineError = (key: BookabilityRule, bookability?: Bookability) => !!bookability?.[key]?.[0];

export const renderInlineError = (key: BookabilityRule, bookability: Bookability = {}) => {
  const level = bookability[key]?.[0] || null;
  const extra = bookability[key]?.[1] || {};
  const errorMessage = INLINE_ERROR_MESSAGES[key] || '';
  const message = typeof errorMessage === 'string' ? errorMessage : errorMessage(extra);

  if (!level || !message) {
    return null;
  }

  return <ErrorMessage icon={level}>{message}</ErrorMessage>;
};

export const PanelButton: React.FC<Props> = ({ className, onClick, type, children, inlineErrors }) => {
  return (
    <button
      aria-label={type}
      className={cn(
        className,
        'text-left z-10 focus:ring focus:outline-none border-b border-gray-200 p-6 flex w-full cursor-pointer hover:bg-gray-50 hover:text-indigo-600 transition duration-150 ease-in-out'
      )}
      onClick={() => onClick(type)}
    >
      {BUTTON_ICONS[type]}
      <div className='flex-grow mx-3'>
        <div className='flex items-center justify-between mb-3'>
          <Text bold>{humanize(capitalize(type))}</Text>

          <ChevronRightSVG className='flex-shrink-0 w-4 h-4' />
        </div>
        <div className='flex flex-col space-y-2'>
          {inlineErrors && inlineErrors.rules.map((rule) => renderInlineError(rule, inlineErrors.bookability))}
          {children}
        </div>
      </div>
    </button>
  );
};
