import * as React from 'react';
import { useCallback } from 'react';
import pluralize from 'pluralize';
import { capitalizeAllWords } from '@components/utils';

import {
  InvalidCallLocationAlert,
  ModeratorsNeedCalendarAlert,
  ModeratorsNeedConferencingAlert,
  ModeratorsNeedZoomLocalRecordingSettingAlert,
  NeedsAvailabilityAlert,
  NeedsSlotsAlert,
  NoScreenerQuestionsAlert,
  NoSurveyQuestionsAlert,
  UnderParticipantLimitAlert,
  DateRangeInPast
} from './components';

type Props = {
  study: Study;
  bookability: Bookability;
  onStartPolling?: () => void;
};

export const INLINE_ERROR_MESSAGES: Partial<
  Record<BookabilityRule, string | ((e: BookabilityCase['extra']) => string)>
> = {
  moderators_need_calendar: (extra) => `${pluralize('calendars', extra?.user_ids?.length || 1, true)} disconnected`,
  moderators_need_conferencing: (extra) =>
    `${pluralize(
      capitalizeAllWords(extra?.platform?.replace(/_/g, ' ') || 'conferencing'),
      extra?.user_ids?.length || 1,
      true
    )} disconnected`,
  date_range_in_past: 'Date range in the past',
  needs_availability: 'No scheduling hours added',
  invalid_call_location: 'Invalid call location'
};

const COMPONENTS: Record<BookabilityRule, BookabilityRuleAlertComponent> = {
  moderators_need_calendar: ModeratorsNeedCalendarAlert,
  moderators_need_conferencing: ModeratorsNeedConferencingAlert,
  moderators_need_zoom_local_recording_setting: ModeratorsNeedZoomLocalRecordingSettingAlert,
  needs_slots: NeedsSlotsAlert,
  needs_availability: NeedsAvailabilityAlert,
  under_participant_limit: UnderParticipantLimitAlert,
  invalid_call_location: InvalidCallLocationAlert,
  no_screener_questions: NoScreenerQuestionsAlert,
  no_survey_questions: NoSurveyQuestionsAlert,
  date_range_in_past: DateRangeInPast
};

export const BookabilityAlerts: React.FC<Props> = ({ study, bookability, onStartPolling }) => {
  const rules = Object.keys(bookability);

  const renderRule = useCallback(
    (rule: BookabilityRule) => {
      const Component = COMPONENTS[rule];
      const level = bookability[rule]?.[0];
      const extra = bookability[rule]?.[1];
      return (
        level &&
        Component && (
          <Component key={rule} study={study} rule={rule} level={level} extra={extra} onClickCta={onStartPolling} />
        )
      );
    },
    [bookability]
  );

  const displayableRules = rules.filter((rule) => bookability[rule][0]);

  if (!displayableRules?.length) {
    return null;
  }

  return <div className='flex flex-col mb-4 space-y-4'>{displayableRules.map(renderRule)}</div>;
};
