import React, { VFC } from 'react';
import pluralize from 'pluralize';
import Tippy from '@tippyjs/react';

import { GridGallery, GridGalleryBlock, Text } from '@components/common';
import { MergeCells } from '@components/shared/Tiptap/icons';

interface Props {
  answer: ScreenerResponseAnswerValue<'card_sort'>;
  mergedCategories: { name: string; categories: string[] }[];
}

const BREAKPOINT_COLS = {
  768: 1,
  1024: 2,
  1280: 3,
  default: 3
};

export const ResponseDetails: VFC<Props> = ({ answer, mergedCategories }) => {
  return (
    <div className='flex-grow overflow-auto w-full'>
      <GridGallery breakpointCols={BREAKPOINT_COLS}>
        {answer.results.map(({ cards, name }) => {
          const mergedCategory = mergedCategories.find(({ categories }) => categories.includes(name));

          return (
            <GridGalleryBlock className='p-4 border rounded-md border-gray-200 bg-indigo-50' key={name}>
              <div className='mb-4'>
                <Text bold>{name}</Text>

                {mergedCategory && (
                  <div className='mt-2.5 flex items-center'>
                    <Tippy arrow={false} content='Raw categories that were merged.'>
                      <span>
                        <MergeCells className='text-gray-500' />
                      </span>
                    </Tippy>
                    <Text className='ml-2' h='400' color='gray-500'>
                      {mergedCategory.name}
                    </Text>
                  </div>
                )}
              </div>
              {cards?.map((card) => (
                <Text key={card} h='400' className='px-4 py-2 border bg-white border-gray-200 rounded-md mb-1'>
                  {card}
                </Text>
              ))}
              <Text h='200' color='gray-500 mt-4'>
                {pluralize('cards', cards?.length || 0, true)}
              </Text>
            </GridGalleryBlock>
          );
        })}
      </GridGallery>
    </div>
  );
};
