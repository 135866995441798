import { api } from 'api/reduxApi';
import cn from 'classnames';
import { DropdownItem } from 'components/common/DropdownCombobox';
import { Label } from 'components/fields';
import { useToaster } from 'components/stores/toaster';
import { ChangeIncentiveModal, useStudyFunder } from 'components/Wallet';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { Card, Loading } from '@components/common';
import { BookabilityAlerts } from '@components/shared/BookabilityAlerts';
import { ConnectedAccountSync } from '@components/shared/ConnectedAccountSync';
import { useStudyBookability } from '@components/StudyMessages/hooks/useStudyBookability';
import { findCurrentTimeZoneName } from '@components/utils/tz';
import { useUser } from '@hooks/useUser';

import { StepHelper, StepTitle } from '../../../shared';
import { TOMORROW } from '../../constants';
import { CalBasicSettings } from '../CalBasicSettings';
import { TimeZoneSelect } from '../NylasCalendar/components/TimeZoneSelect';
import { ConfirmChangeModal } from '../NylasCalendar/ConfirmChangeModal';
import { Preview } from '../NylasCalendar/Preview';
import { Sessions } from './components/Sessions';
import * as toasts from './toasts';

const renderTZ = ({ data: { abbreviation, name } }: DropdownItem): string => `${abbreviation} (${name})`;

type Props = {
  study: Study;
  onBack: () => void;
  studyShowTitle?: boolean;
  readOnly?: boolean;
  isLoading?: boolean;
  onSave: (study: { id: number } & Partial<Study>) => void;
};

export const SessionsCalendar: React.FC<Props> = ({ study, isLoading, onSave, studyShowTitle, readOnly, onBack }) => {
  const user = useUser();
  const showToast = useToaster();
  const [fundStudy, setFundStudy] = useState<'add' | 'remove' | null>(null);
  const [confirmValue, setConfirmValue] = useState<Calendar | null>();
  const [updateRepoSession, { isError: updateError, isLoading: updating }] = api.useUpdateRepoSessionMutation();

  const [sessions, setSessions] = useState<RepoSession[]>([]);
  const { data = [], isError: fetchError, isSuccess } = api.useGetRepoSessionsQuery({ studyId: study.id });

  const [createRepoSession, { isLoading: creating }] = api.useCreateRepoSessionMutation();
  const [deleteRepoSession, { isError: deleteError, isLoading: deleting }] = api.useDestroyRepoSessionMutation();

  const [selectedSession, setSelectedSession] = useState<string | null>(null);

  const limitCount = (study.session_capacity || 0) * sessions.length;

  const funder = useStudyFunder({ study });

  const createSession = async (props?: Partial<RepoSession>) => {
    try {
      const res = await createRepoSession({
        title: `Session #${sessions.length + 1}`,
        kind: 'interview',
        project_id: study.id,
        ...(props || {})
      }).unwrap();
      return res;
    } catch {
      showToast(toasts.failedCreate());
    }
  };

  const onCreate = async (props?: Partial<RepoSession>) => {
    if (study.state === 'draft' || study.incentive_method !== 'tremendous') {
      createSession(props);
    } else {
      const wallet = await funder.prepare({
        participant_limit: limitCount + (study?.session_capacity || 0),
        incentive: study.incentive,
        incentive_method: study.incentive_method
      });
      if (wallet) {
        setFundStudy('add');
      } else {
        createSession(props);
      }
    }
  };

  const deleteSession = async (uuid: string) => {
    if (study.state === 'draft' || study.incentive_method !== 'tremendous') {
      setSessions(sessions.filter((s) => s.uuid !== uuid));
      deleteRepoSession({ uuid, study_id: study.id });
    } else {
      const wallet = await funder.prepare({
        participant_limit: limitCount - (study?.session_capacity || 0),
        incentive: study.incentive,
        incentive_method: study.incentive_method
      });
      if (wallet) {
        setSelectedSession(uuid);
        setFundStudy('remove');
      } else {
        deleteRepoSession({ uuid, study_id: study.id });
      }
    }
  };

  const updateSession = ({ uuid, session_at }: RepoSession) => {
    session_at?.setMilliseconds(0);
    setSessions(sessions.map((s) => (s.uuid === uuid ? { ...s, session_at } : s)));
    updateRepoSession({ uuid, session_at });
  };

  const [timeSlot, setTimeSlot] = useState<RecurringTimeSlot | OneOffTimeSlot>({
    start_time: '07:00',
    end_time: '19:00',
    days: [1, 2, 3, 4, 5],
    timezone: user.time_zone || findCurrentTimeZoneName(),
    future_limit: 2,
    call_buffer: 0,
    start_date: TOMORROW.toISOString(),
    ...(study.time_slot_settings as any)
  } as OneOffTimeSlot);

  const { bookability } = useStudyBookability({ studyId: study.id, event: 'calendar' });

  const changeTimeSlot = (attr: string, val: string | number | number[]) => {
    const newVal = { ...timeSlot, [attr]: val };
    setTimeSlot(newVal);
    onSave({ id: study.id, time_slot_settings: newVal });
  };

  useEffect(() => {
    if (isSuccess) setSessions(data);
  }, [data]);

  useEffect(() => {
    if (updateError) {
      showToast(toasts.failedUpdate());
    }
  }, [updateError]);

  useEffect(() => {
    if (deleteError) {
      showToast(toasts.failedDelete());
    }
  }, [deleteError]);

  useEffect(() => {
    if (fetchError) {
      showToast(toasts.failedFetch());
    }
  }, [fetchError]);

  return (
    <>
      {study.nylas_calendar_id && (
        <div className='top-6 right-12 desktop:flex absolute justify-end'>
          <Preview study={study} onSave={onSave}>
            Preview
          </Preview>
        </div>
      )}
      {confirmValue && (
        <ConfirmChangeModal
          onCancel={() => setConfirmValue(null)}
          onConfirm={() => {
            onSave({ id: study.id, calendar_id: confirmValue.id });
            setConfirmValue(null);
          }}
        />
      )}

      <div className={cn(studyShowTitle && 'pt-gutter', 'tablet:px-0 tablet:px-0 max-w-2xl px-6 mx-auto text-center')}>
        <StepTitle>Calendar</StepTitle>
        <StepHelper mb='6'>This determines the available focus group sessions that participants can book.</StepHelper>
      </div>

      {bookability && (
        <div className='max-w-2xl mx-auto'>
          <BookabilityAlerts study={study} bookability={bookability} />
        </div>
      )}

      <ConnectedAccountSync provider='nylas' />

      <Card className='max-w-2xl mx-auto mt-6 mb-10'>
        <div className='desktop:my-0 w-full mr-3'>
          <div className='relative h-full'>
            {isLoading && <Loading absolute />}

            <div className='max-w-64 w-full'>
              <CalBasicSettings
                onBack={onBack}
                study={study}
                onSave={onSave}
                readOnly={readOnly}
                setConfirmValue={setConfirmValue}
              />
            </div>
            <div className='h500-bold block mb-2'>Sessions</div>
            <span className='h400 block mb-4 text-gray-500'>
              These are the focus group sessions that participants can book.
            </span>
            <Sessions
              study={study}
              deleteSession={deleteSession}
              updateSession={updateSession}
              sessions={sessions}
              createSession={onCreate}
              duration={study.duration_in_minutes || 15}
            />
            <Label className='mb-3'>Time zone</Label>
            <TimeZoneSelect timeSlot={timeSlot} changeTimeSlot={changeTimeSlot} renderSelected={renderTZ} />
          </div>
        </div>
      </Card>
      {fundStudy === 'add' && (
        <ChangeIncentiveModal
          study={study}
          funder={funder}
          limitCount={limitCount + (study?.session_capacity || 0)}
          onCancel={() => setFundStudy(null)}
          onConfirm={() => {
            setFundStudy(null);
            createSession();
          }}
        />
      )}
      {fundStudy === 'remove' && (
        <ChangeIncentiveModal
          study={study}
          funder={funder}
          limitCount={limitCount - (study?.session_capacity || 0)}
          onCancel={() => setFundStudy(null)}
          onConfirm={() => {
            setFundStudy(null);
            selectedSession && deleteSession(selectedSession);
            setSelectedSession(null);
          }}
        />
      )}
    </>
  );
};
