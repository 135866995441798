import React, { useEffect, useRef } from 'react';
import { Button, Input, Popper } from '@components/common';
import { useTrapFocus } from '@hooks/useTrapFocus';
import mergeRefs from 'react-merge-refs';
import { useSearchWithinFilter } from '@components/CandidatesApp/CandidatesIndex/hooks';
import cn from 'classnames';

export interface InputWithMultiselectDropdownProps {
  disabled?: boolean;
  error: boolean;
  options: DropdownOption[];
  onChange: (value: string[]) => void;
  onSearch?: (searchValue: string) => void;
  value: DropdownOption[];
  placeholder?: string;
  limit?: number;
  allOptionsValue?: string;
}

export const InputWithMultiselectDropdown: React.FC<InputWithMultiselectDropdownProps> = ({
  disabled,
  error,
  options,
  onChange,
  onSearch,
  value,
  placeholder,
  limit,
  allOptionsValue
}) => {
  const { ref: trapRef } = useTrapFocus();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLInputElement>(null);

  const [selected, setSelected] = React.useState<string[]>([]);

  const toggleFilter = (option: string) => {
    if (option === allOptionsValue) {
      setSelected(selected.includes(allOptionsValue) ? [] : [allOptionsValue]);
      return;
    }
    const newValue = selected?.includes(option)
      ? selected.filter((opt) => opt !== option)
      : (selected || []).concat(option);

    setSelected(newValue);
  };

  useEffect(() => {
    setSelected(value.map(({ value }) => value) || []);
  }, [value]);

  const { searchValue, setSearchValue, filteredOptions } = useSearchWithinFilter<DropdownOption>({
    options,
    getFilteredOptions: (option, search) => option.label.toLocaleLowerCase().includes(search?.toLocaleLowerCase() || '')
  });

  return (
    <Popper
      syncWidth
      isDisabled={disabled}
      renderOnBodyRoot={false}
      onOpen={() => requestAnimationFrame(() => dropdownRef.current?.focus())}
      onClose={() => requestAnimationFrame(() => ref.current?.focus())}
      content={({ closePopper }) => (
        <div
          role='menu'
          ref={mergeRefs([trapRef, dropdownRef])}
          className='w-full bg-white border border-gray-200 rounded shadow-lg overflow-hidden'
        >
          <ul className='w-full max-h-60 overflow-y-auto flex flex-col'>
            {filteredOptions.map((option) => {
              const isDisabled =
                disabled ||
                (!selected?.includes(option.value) && selected.length === limit) ||
                (selected.includes(allOptionsValue as string) && option.value !== allOptionsValue);

              return (
                <li key={option.value}>
                  <label
                    className='cursor-pointer px-4 py-1.5 w-full hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 flex items-center text-sm leading-5 text-gray-700 normal-case'
                    htmlFor={option.value}
                  >
                    <input
                      id={option.value}
                      name={option.label}
                      data-testid={option.value}
                      type='checkbox'
                      className='form-checkbox ml-1 mr-2'
                      onChange={() => toggleFilter(option.value)}
                      value={option.value}
                      checked={selected?.includes(option.value)}
                      disabled={isDisabled}
                    />
                    <div className={cn('flex truncate items-center space-x-2', isDisabled && 'opacity-75')}>
                      <span className='h400 truncate'>{option.label}</span>
                      {option.annotation}
                    </div>
                  </label>
                </li>
              );
            })}
          </ul>
          <div className='flex items center justify-between px-4 py-2 border-t border-gray-200'>
            <Button medium link onClick={() => setSelected([])}>
              Clear
            </Button>
            <Button
              medium
              primary
              onClick={() => {
                onChange(selected);
                closePopper();
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      )}
      offset={[4, 0]}
      placement='bottom-start'
      zIndex={50}
      closeOnButtonClick={false}
      closeOnEscape
      closeOnClickOutside
    >
      <div>
        <Input
          disabled={disabled}
          error={error}
          ref={ref}
          placeholder={placeholder}
          value={searchValue}
          onChange={(v) => {
            setSearchValue(v);
            onSearch?.(v);
          }}
          className='w-full'
        />
      </div>
    </Popper>
  );
};
