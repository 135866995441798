import { BLOCK_SETTINGS_FORM_DEFAULT_VALUES } from '../constants';

import { isBlockOfKind } from './isBlockOfKind';

import * as Enums from '../types/enums';
import * as Forms from '../types/forms';
import * as Models from '../types/models';

export const populateFormData = (block?: Models.Block): Forms.Data | void => {
  if (!block) return;

  const defaultConfig = BLOCK_SETTINGS_FORM_DEFAULT_VALUES[block.kind];

  const result: Forms.Data = {
    config: defaultConfig ?? null,
    description: block.description,
    required: block.required,
    title: block.title
  };

  if (isBlockOfKind(block, Enums.Kind.prototypeTest)) {
    result.config = {
      url: block.blockable.url,
      vendor: block.blockable.vendor,
      title: block.blockable.title,
      paths: block.blockable.paths
    };
  } else if (isBlockOfKind(block, Enums.Kind.permissions)) {
    result.config = {
      camera: block.blockable.camera,
      microphone: block.blockable.microphone,
      screen: block.blockable.screen
    };
  } else if (isBlockOfKind(block, Enums.Kind.linearScale)) {
    result.config = { options: block.blockable.options ?? defaultConfig.options };
  } else if (isBlockOfKind(block, Enums.Kind.multiSelect)) {
    result.config = { options: block.blockable.options ?? defaultConfig.options };
  } else if (isBlockOfKind(block, Enums.Kind.singleSelect)) {
    result.config = { options: block.blockable.options ?? defaultConfig.options };
  } else if (isBlockOfKind(block, Enums.Kind.websiteTest)) {
    result.config = { url: block.blockable.url };
  } else if (isBlockOfKind(block, Enums.Kind.cardSort)) {
    result.config = { ...defaultConfig, ...block.blockable };
  } else if (isBlockOfKind(block, Enums.Kind.treeTest)) {
    result.config = { ...defaultConfig, ...block.blockable };
  }

  return result;
};
