import { uid } from '@components/utils';

export const DEFAULT_QUESTION: ScreenerField = {
  id: uid(),
  label: '',
  helper: '',
  field_type: 'yes_no',
  required: false,
  candidate_attr: '',
  options: null as any,
  other: false,
  position: 1,
  layout: null,
  skip_logic: null,
  meta: {},
  next_screener_field_id: null,
  external_field_id: null
};

export const attrMappings: Omit<Record<ScreenerFieldType, Attr_['attr_type']>, 'start_loom' | 'stop_loom'> = {
  multiple_choice: 'multiple_choice',
  single_choice: 'text',
  short_text: 'text',
  long_text: 'text',
  email: 'text',
  number: 'number',
  website: 'url',
  yes_no: 'boolean',
  number_range: 'number',
  // watch_loom: null,
  task: null as any,
  info: null as any,
  date: 'datetime',
  location: 'text',
  prototype_test: null as any,
  card_sort: null as any,
  tree_test: null as any
};

export const SURVEY_TITLES = {
  panel: 'Set up your panel signup form',
  survey: 'Create your survey',
  unmoderated_test: 'Let’s set up your unmoderated usability test'
};

export const SCREENER_TITLE = 'Set up your screener';
