import cn from 'classnames';
import React, { ChangeEventHandler, forwardRef, HTMLAttributes, useState } from 'react';

import { Text } from '@components/common';
import { ArtifactHit } from '@components/RepositoryApp/types';
import { ChevronDownSVG, ChevronUpSVG, CloseSVG, DragSVG, PlusSVG } from '@components/svgs';
import { secondsToTimestamp, truncate } from '@components/utils';
import { useStudies } from '@hooks/useStudies';
import Tippy from '@tippyjs/react';

import { useHighlightReelsContext } from '../../../hooks/useHighlightReelsContext';
import { Highlight } from 'components/shared/Highlight';
import { getClipDataFromHit } from '../utils';
import { useAccount } from '@hooks/useAccount';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  hit: ArtifactHit;
  selectedHits?: string[];
  isActive?: boolean;
  setSelectedHits?: (hits: string[]) => void;
  moveDown?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  moveUp?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Clip = forwardRef<HTMLDivElement, Props>(
  ({ selectedHits = [], setSelectedHits, moveUp, moveDown, className, hit, isActive, ...rest }, ref) => {
    const [bodyExpanded, setBodyExpanded] = useState(false);

    const {
      getUserById,
      account: { team }
    } = useAccount();

    const users = team
      .filter(({ id }) => hit.study_user_ids.includes(id))
      ?.map((user) => user.name)
      ?.join(', ');

    const clip = getClipDataFromHit(hit);

    const creator = getUserById(hit.owner_id);

    const { studies } = useStudies();

    const studyTitles = studies
      ?.filter(({ id }) => hit.study_ids.includes(id))
      ?.map(({ title }) => title)
      ?.join(', ');

    const { artifactIds, playerIsLoading, setArtifactIds, setIsDirty, setClipForDetails } = useHighlightReelsContext();

    const isSelected = selectedHits.includes(hit.objectID);

    const onCheckboxChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      if (e.target.checked) {
        setSelectedHits?.([...selectedHits, hit.objectID]);
      } else {
        setSelectedHits?.(selectedHits.filter((objectID) => objectID !== hit.objectID));
      }
    };

    const onRemove = (e) => {
      e.stopPropagation();
      setArtifactIds(artifactIds.filter((objectID) => objectID !== hit.objectID));
      setIsDirty?.(true);
    };

    const onAdd = (e) => {
      e.stopPropagation();
      setArtifactIds([...artifactIds, hit.objectID]);
      setIsDirty?.(true);
    };

    const getClipBody = (body: string) => (
      <span className='h400 mt-2 leading-7'>
        {body.length > 180 ? (bodyExpanded ? body : truncate(body || '', 180)) : body}
        {body.length > 180 && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              setBodyExpanded(!bodyExpanded);
            }}
            className='focus:outline-none h400 ml-2 text-indigo-600'
          >
            {bodyExpanded ? 'Show less' : 'more'}
          </button>
        )}
      </span>
    );

    const renderActions = () => (
      <div className='flex justify-between'>
        {isActive ? (
          <>
            <DragSVG className='mr-2' />
            <Tippy arrow={false} content='Re-order down'>
              <button
                className='hover:bg-gray-50 flex items-center justify-center w-6 h-6 mr-2 rounded-full'
                onClick={moveDown}
                aria-label='Re-order down'
              >
                <ChevronDownSVG />
              </button>
            </Tippy>
            <Tippy arrow={false} content='Re-order up'>
              <button
                className='hover:bg-gray-50 flex items-center justify-center w-6 h-6 mr-2 rounded-full'
                onClick={moveUp}
                aria-label='Re-order up'
              >
                <ChevronUpSVG />
              </button>
            </Tippy>
            <button className='focus:outline-none ml-auto' aria-label='Remove highlight' onClick={onRemove}>
              <CloseSVG />
            </button>
          </>
        ) : (
          <>
            <input
              onClick={(e) => e.stopPropagation()}
              type='checkbox'
              onChange={onCheckboxChange}
              checked={isSelected}
            />
            <Tippy className='bg-gray-500 rounded-lg' content='Add to reel' arrow={false}>
              <button className='focus:outline-none ml-auto text-indigo-600' aria-label='Add highlight' onClick={onAdd}>
                <PlusSVG />
              </button>
            </Tippy>
          </>
        )}
      </div>
    );

    return (
      <div
        ref={ref}
        onClick={() => setClipForDetails?.({ ...hit, users, studyTitles, isActive })}
        className={cn({ 'cursor-pointer': !playerIsLoading, 'cursor-wait': playerIsLoading }, className)}
        {...rest}
      >
        <Highlight
          renderTranscript={() => (
            <>
              {hit.document_fragment_speakers?.map(({ content, speaker, start_at }) => (
                <div className='mb-2'>
                  <div className='flex items-center my-2 space-x-2'>
                    <div className='text-sm font-medium'>{speaker || 'Unnamed candidate'}</div>

                    <Text color='gray-500' className='text-sm'>
                      {secondsToTimestamp(start_at / 1000, 4)}
                    </Text>
                  </div>

                  {getClipBody(content)}
                </div>
              ))}
            </>
          )}
          renderActions={renderActions}
          sessionTitle={studyTitles}
          clip={clip}
          creator={creator}
          // TODO: pass clip.highlight.ai through here
        />
      </div>
    );
  }
);
