import { useAccount } from 'hooks/useAccount';
import * as React from 'react';
import { useState } from 'react';

import { Alert, Card, Loading, Text } from '@components/common';
import { usePermission } from '@hooks/usePermission';
import { useUser } from '@hooks/useUser';

import { useStudySettings } from '../hooks/useStudySettings';
import { Setting } from './Setting';
import { EMAIL_KEYS, NON_OWNER_EMAIL_KEYS, SLACK_KEYS } from './Setting/constants';
import { StudyUsersModal } from './StudyUsers/components';

interface Props {
  study: Study;
}

function isRelevant(key: string, study: Study) {
  switch (key) {
    case 'participation.upcoming_reminder':
    case 'participation.booked':
    case 'participation.booked_reminder':
      return study.style === 'video_call';
    case 'participation.started_reminder':
      return !study.moderated;
    case 'screener_response.create':
      return study.has_screener || ['panel', 'survey', 'unmoderated_test'].includes(study.style);
    case 'incentive.redeemed':
    case 'project.funded':
      return study.incentive_method === 'tremendous' && study.incentive && study.incentive > 0;
    case 'project.closed':
      return study.style !== 'panel';
    case 'transcript.ready':
      return study.style === 'video_call';
    default:
      return true;
  }
}

export const NotificationSettings: React.FC<Props> = ({ study }) => {
  const canUpdate = usePermission<Study>('updateStudy')(study);

  const { loading, settings, handleToggleSetting } = useStudySettings(study);

  const {
    account: { has_slack: hasSlack }
  } = useAccount();
  const user = useUser();
  const isStudyOwner = study.owner_id === user.id;
  const emailArrayToConsider = isStudyOwner ? EMAIL_KEYS : NON_OWNER_EMAIL_KEYS;
  const emailKeys = emailArrayToConsider.filter((k) => isRelevant(k, study));
  const slackKeys = SLACK_KEYS.filter((k) => isRelevant(k, study));
  const isStudyUser = study.user_ids.includes(user.id);
  const [showStudyUsers, setShowStudyUsers] = useState(false);

  return (
    <div className='px-page py-gutter'>
      <Text h='700' bold className='mb-2'>
        Notifications
      </Text>
      <Card className='p-6'>
        {loading && <Loading absolute />}

        <Text h='500' bold>
          Email me when…
        </Text>
        {!isStudyUser && (
          <Alert type='info' className='mt-2'>
            <span>
              To sign up for email notifications,{' '}
              <span
                className='hover:text-indigo-600 font-bold underline cursor-pointer'
                onClick={() => setShowStudyUsers(true)}
              >
                become a study collaborator.
              </span>
            </span>
          </Alert>
        )}
        <div className='divide-y divide-gray-200'>
          {emailKeys.map((key) => (
            <Setting
              key={key}
              disabled={!isStudyUser}
              study={study}
              settingKey={key}
              value={isStudyUser ? Boolean(settings.email[key]) : false}
              onToggle={() => handleToggleSetting('email', key)}
            />
          ))}
        </div>
        {hasSlack && slackKeys.length > 0 && (
          <div className='py-6'>
            <h4 className='text-xl font-bold text-gray-700'>Post to Slack when…</h4>
            {!canUpdate && (
              <Alert type='info' className='my-3'>
                As an Observer, you can't update these settings.
              </Alert>
            )}
            <div className='divide-y divide-gray-200'>
              {slackKeys.map((key) => (
                <Setting
                  disabled={!canUpdate}
                  study={study}
                  settingKey={key}
                  value={!!settings.slack[key]}
                  onToggle={() => handleToggleSetting('slack', key)}
                />
              ))}
            </div>
          </div>
        )}
        {showStudyUsers && <StudyUsersModal study={study} onClose={() => setShowStudyUsers(false)} />}
      </Card>
    </div>
  );
};
