import cn from 'classnames';
import * as React from 'react';

import { CheckMark2SVG, ErrorSvg, WarningSVG } from '@components/svgs';

const renderATag: StepperProps['component'] = (props) => <a {...props} />; // eslint-disable-line jsx-a11y/anchor-has-content

export interface StepperProps {
  steps: {
    id: string;
    name: string;
    href: string;
    status: 'complete' | 'error' | 'warning' | 'current' | 'upcoming';
  }[];
  component?: React.FC<{ href: string; className: string }>;
}

export const Stepper: React.FC<StepperProps> = ({ steps, component: Link = renderATag }) => (
  <nav className='bg-white' aria-label='Progress'>
    <ol className='tablet:flex tablet:divide-y-0 px-page -ml-6 border-b border-gray-300 divide-y divide-gray-300'>
      {steps.map((step, stepIdx) => (
        <Link
          key={step.name}
          href={step.href}
          className='tablet:flex-1 tablet:flex relative'
          aria-current={stepIdx === 1 ? 'step' : null}
        >
          <li
            key={step.name}
            className={cn({
              'group flex items-center w-full': ['complete', 'warning'].includes(step.status),
              'flex items-center px-6 py-3 text-sm font-medium': step.status === 'current',
              'group flex items-center': step.status === 'upcoming'
            })}
          >
            <span
              className={cn('flex items-center', {
                'flex items-center px-6 py-3 text-sm font-medium': step.status !== 'current'
              })}
            >
              <span
                className={cn('flex items-center justify-center flex-shrink-0 w-6 h-6 rounded-full', {
                  'border-2 border-indigo-600': step.status === 'current',
                  'group-hover:bg-indigo-800 bg-indigo-600': step.status === 'complete',
                  'group-hover:border-gray-400 border-2 border-gray-300': step.status === 'upcoming'
                })}
              >
                {step.status === 'upcoming' && (
                  <span className='group-hover:text-gray-900 h400 text-gray-500'>{step.id}</span>
                )}
                {step.status === 'current' && <span className='h400 text-indigo-600'>{step.id}</span>}
                {step.status === 'complete' && <CheckMark2SVG className='w-4 h-4 text-white' aria-hidden='true' />}
                {step.status === 'error' && <ErrorSvg className='w-4 h-4 text-red-600' />}
                {step.status === 'warning' && <WarningSVG className='w-4 h-4 text-yellow-600' />}
              </span>
              <span
                className={cn('ml-4 text-sm font-medium text-gray-900', {
                  'group-hover:text-gray-900 text-gray-500': ['upcoming'].includes(step.status)
                })}
              >
                {step.name}
              </span>
            </span>
          </li>

          {stepIdx !== steps.length - 1 ? (
            <>
              {/* Arrow separator for lg screens and up */}
              <div className='tablet:block absolute top-0 right-0 hidden w-5 h-full' aria-hidden='true'>
                <svg className='w-full h-full text-gray-300' viewBox='0 0 22 80' fill='none' preserveAspectRatio='none'>
                  <path
                    d='M0 -2L20 40L0 82'
                    vectorEffect='non-scaling-stroke'
                    stroke='currentcolor'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
            </>
          ) : null}
        </Link>
      ))}
    </ol>
  </nav>
);
