import { getDateFromString, getHours, getStringFromDate, getTime, HourMin } from '../../NylasCalendar/utils';
import * as React from 'react';
import { CloseSVG, PlusSVG } from '@components/svgs';
import { AvailabilitySlot } from '../components/AvailabilitySlot';
import { uniqueId } from '@components/utils';
import tinytime from 'tinytime';
import { RecurringSelect } from '../../RecurringSelect';
import { useDeviceType } from '@hooks/useDeviceType';
import Tippy from '@tippyjs/react';
import { track } from '@components/tracking';

const shortDateTemplate = tinytime('{MM} {DD}');

interface Props {
  startHour: number;
  endTime: HourMin;
  onChange: (events: SlotInstance[]) => void;
  day: string;
  currentSlots: SlotInstance[];
  initialDuration: number;
  allSlots: SlotInstance[];
  date: Date;
  disabled?: boolean;
  unavailable?: boolean;
}

export const AvailabilityRow: React.FC<Props> = ({
  startHour,
  endTime,
  onChange,
  day,
  currentSlots,
  initialDuration,
  allSlots,
  date,
  disabled,
  unavailable
}) => {
  const { hour: endHour, min: endMin } = endTime;

  const { isMobile } = useDeviceType();

  const handleCreateSlot = () => {
    const id = uniqueId();

    const lastEvent = currentSlots[currentSlots.length - 1];

    const lastEventMinutes = getHours(lastEvent?.time || '0:00') * 60;

    const newEventMinutes = lastEventMinutes + (lastEvent?.duration || 0) + 15;

    const netHours = Math.floor(newEventMinutes / 60);

    const netMinutes = newEventMinutes % 60;

    const currentDate = lastEvent?.date
      ? getDateFromString(lastEvent.date).setHours(netHours, netMinutes)
      : new Date(date).setHours(netHours, netMinutes);

    const endDate = currentDate + initialDuration * 60 * 1000;

    const maxDate = lastEvent?.date
      ? getDateFromString(lastEvent.date).setHours(endHour, endMin)
      : new Date(date).setHours(endHour, endMin);

    if (lastEvent?.date && endDate > maxDate) {
      return;
    }

    const time = lastEvent ? getTime({ hour: netHours, min: netMinutes }) : `${String(startHour).padStart(2, '0')}:00`;

    const newSlot: SlotInstance = {
      id,
      day: new Date(date).getDay() as Slot['day'],
      date: getStringFromDate(date),
      time,
      duration: initialDuration
    };

    track('availability_slot_created', { place: 'availability_panel' });

    onChange([...allSlots, newSlot]);
  };

  const updateSlot = (event: SlotInstance) => {
    track('availability_slot_updated', { place: 'availability_panel' });
    const newEvents = allSlots.map((src) => (src.id === event.id ? event : src));

    onChange(newEvents);
  };

  const handleDeleteSlot = (id: string) => {
    track('availability_slot_deleted', { place: 'availability_panel' });
    onChange(allSlots.filter((e) => e.id !== id));
  };

  return (
    <div className='flex'>
      {!isMobile && <div className='h400 w-9 flex items-center flex-shrink-0 h-8'>{day}</div>}

      <div className='h400 w-14 whitespace-nowrap flex items-center flex-shrink-0 h-8'>
        {shortDateTemplate.render(date)}
      </div>
      <div className='min-w-80 flex flex-col w-full space-y-2'>
        {(!currentSlots.length || unavailable) && (
          <div className='flex items-center justify-between w-full'>
            <div className='h400 flex items-center flex-grow flex-shrink-0 h-8 mx-1 text-gray-500'>Unavailable</div>
            {!unavailable && (
              <Tippy content='Add availability' arrow={false}>
                <button
                  onClick={handleCreateSlot}
                  aria-label='Add period'
                  className='justify-self-end hover:text-indigo-600 hover:bg-gray-50 p-2 ml-2 mr-1 rounded-full cursor-pointer'
                >
                  <PlusSVG className='h-3.5 w-3.5' />
                </button>
              </Tippy>
            )}
            <div className='w-7 h-7 flex-shrink-0 mx-px' />
          </div>
        )}
        {!unavailable &&
          currentSlots.map((slot, i) => {
            return (
              <div className='flex items-start w-full' key={slot.id}>
                <RecurringSelect slot={slot} updateSlot={updateSlot} date={date} disabled={disabled} />
                <AvailabilitySlot
                  onChange={(newSlot) => updateSlot({ ...slot, ...newSlot })}
                  slot={slot}
                  endHour={endHour}
                  startHour={startHour}
                  disabled={disabled}
                />
                <div className='flex items-center justify-end flex-grow'>
                  {slot.id === currentSlots[0]?.id ? (
                    <Tippy content='Add availability' arrow={false}>
                      <button
                        disabled={disabled}
                        onClick={handleCreateSlot}
                        aria-label='Add slot'
                        className='hover:text-indigo-600 hover:bg-gray-50 p-2 ml-2 mr-1 rounded-full cursor-pointer'
                      >
                        <PlusSVG className='h-3.5 w-3.5' />
                      </button>
                    </Tippy>
                  ) : (
                    <div className='w-7 flex-shrink-0 h-5 ml-3' />
                  )}
                  <Tippy content='Remove availability' arrow={false}>
                    <button
                      disabled={disabled}
                      onClick={() => handleDeleteSlot(slot.id)}
                      aria-label='Delete slot'
                      className='hover:text-indigo-600 hover:bg-gray-50 p-2 rounded-full cursor-pointer'
                    >
                      <CloseSVG className='h-3.5 w-3.5' />
                    </button>
                  </Tippy>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
