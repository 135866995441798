import React from 'react';
import Tippy from '@tippyjs/react';

import { Modal, ModalHeading } from '@components/common';
import { PersonSVG, VideoCameraSVG } from '@components/svgs';

import { ResponseDetails } from './ResponseDetails';

interface Props {
  response: ScreenerResponseAnswerValue<'card_sort'>;
  onClose: () => void;
  mergedCategories: { name: string; categories: string[] }[];
}

export const ResponseDetailsModal: React.FC<Props> = ({ mergedCategories, response, onClose }) => {
  return (
    <Modal size='xl' onClose={onClose}>
      <div className=''>
        <ModalHeading>Cards & categories</ModalHeading>
        <div className='flex items-center mb-6 mt-3'>
          <PersonSVG className='mr-1 text-gray-500' />
          <div className='text-gray-500 mr-2'>{response.participation_name || 'Unnamed'}</div>
          <Tippy
            aria={{ content: 'labelledby', expanded: 'auto' }}
            appendTo={() => document.body}
            interactive
            arrow={false}
            content='View full session room.'
          >
            <a href={`/sessions/${response.session_uuid}`} target='_blank'>
              <VideoCameraSVG className='w-4 h-4' />
            </a>
          </Tippy>
        </div>
      </div>
      <div className='flex-grow pl-0 overflow-auto'>
        <ResponseDetails answer={response} mergedCategories={mergedCategories} />
      </div>
    </Modal>
  );
};
