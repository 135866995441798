import * as React from 'react';

import { BookabilityRuleAlert } from './BookabilityRuleAlert';

export const NeedsAvailabilityAlert: BookabilityRuleAlertComponent = ({ study, level, onClickCta }) => (
  <BookabilityRuleAlert
    level={level}
    heading='No scheduling hours added'
    message='Participants will see an empty calendar with no available times.'
    ctas={[
      {
        children: 'Edit calendar',
        href: `/studies/${study.id}/calendar`,
        onClick: onClickCta
      }
    ]}
  />
);
