import React, { forwardRef, HTMLAttributes, useMemo, useState } from 'react';
import { Table } from '@components/shared/Table';
import { getTableColumnDefs } from '@components/ScreenerResults/components/CardSortResponse/components/helpers/getTableColumnDefs';
import { ResponseDetailsModal } from '@components/ScreenerResults/components/CardSortResponse/components/ResponseDetailsModal';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  answers: NonNullable<ScreenerResponseAnswerValue<'card_sort'>>[];
  setPreview: (answers: NonNullable<ScreenerResponseAnswerValue<'card_sort'>>) => void;
}

export const ResponsesTable: React.FC<Props> = ({ answers, setPreview }) => {
  const columns = useMemo(() => getTableColumnDefs({ setPreview }), []);

  return (
    <div className='relative max-w-full overflow-auto'>
      <Table
        columnPinning={{ left: ['participation_name'] }}
        data={answers}
        columns={columns}
        className='w-full bg-white border border-b-0 border-gray-200 table-fixed'
      />
    </div>
  );
};
