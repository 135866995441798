import { getTimeZones, TimeZone } from '@vvo/tzdb';
export type TimezoneDesc = Omit<
  TimeZone,
  'group' | 'continentCode' | 'continentName' | 'countryName' | 'countryCode' | 'mainCities'
>;

const makeTimezoneDesc = ({
  continentCode,
  continentName,
  countryName,
  countryCode,
  mainCities,
  ...rest
}: TimeZone): TimezoneDesc => rest;

const timezones: TimezoneDesc[] = getTimeZones()
  .sort((a: TimeZone, b: TimeZone) => a.currentTimeOffsetInMinutes - b.currentTimeOffsetInMinutes)
  .map(makeTimezoneDesc);

const browserTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

const timezoneHumanize = (tzName: string): string => tzName.replace('/', ' / ').replace('_', ' ');

const timezoneFromName = (tzName: string): TimezoneDesc | undefined => {
  const timezone = getTimeZones().find((tz) => [tz.name, ...tz.group].includes(tzName));
  if (timezone) return makeTimezoneDesc(timezone);
};

const timezoneOffset = (tzFormat: string): string => tzFormat.split(' ')[0] || '';

export { timezones, browserTimezone, timezoneHumanize, timezoneFromName, timezoneOffset };
