import * as React from 'react';
import { useMemo } from 'react';

import { Loading, Text, Tooltip } from '@components/common';
import { CopyURLButton } from '@components/shared/CopyURLButton';
import { useGetWhoamiQuery } from '@components/stores/whoami';
import { LinkSVG } from 'components/svgs';

type InviteTabs =
  | 'apply'
  | 'start_survey'
  | 'start_online_task'
  | 'start_unmoderated_test'
  | 'start_signup_form'
  | 'start_screener'
  | 'book'
  | 'join';

function getLinkTypes(study: Study): string[] {
  const tabs: string[] = [];

  if (study.style === 'panel') {
    tabs.push('join', 'start_signup_form');

    if (study.has_screener) {
      tabs.push('start_screener');
    }

    return tabs;
  }

  tabs.push('apply');

  if (study.has_screener) {
    tabs.push('start_screener');
  }

  if (study.moderated) {
    tabs.push('book');
  } else {
    tabs.push('start_' + study.style);
  }
  return tabs;
}

const LINK_LABELS: { [key in InviteTabs]: string } = {
  start_survey: 'Survey',
  start_online_task: 'Task',
  start_unmoderated_test: 'Unmoderated test',
  apply: 'Landing page',
  book: 'Scheduling page',
  join: 'Landing page',
  start_screener: 'Screener',
  start_signup_form: 'Signup form'
};

const LINK_HELPERS: { [key in InviteTabs]: string } = {
  start_survey: 'Direct link to complete the survey.',
  start_online_task: 'Direct link to complete the task.',
  start_unmoderated_test: 'Direct link to complete the unmoderated test.',
  apply: 'Direct link to view the landing page.',
  book: 'Direct link to schedule time.',
  join: 'Direct link to view the landing page.',
  start_screener: 'Direct link to complete the screener.',
  start_signup_form: 'Direct link to complete the signup form.'
};

type Props = {
  study: Study;
};
export const ShareableLinks: React.FC<Props> = ({ study }) => {
  const { data: whoami, isLoading } = useGetWhoamiQuery();

  const { public_path } = study;

  const links = getLinkTypes(study);

  const publicBaseURL = whoami?.account_public_domain?.base_url;
  const baseURL = publicBaseURL ? publicBaseURL : window.location.protocol + '//' + window.location.host;
  const publicUrl = baseURL + '/' + public_path;
  const directUrl = publicUrl + '/direct';
  const screenerUrl = publicUrl + '/apply';

  const linkUrls = useMemo<Record<InviteTabs, string>>(
    () => ({
      apply: publicUrl,
      start_survey: directUrl,
      start_online_task: directUrl,
      start_unmoderated_test: directUrl,
      start_screener: screenerUrl,
      book: directUrl,
      join: publicUrl,
      start_signup_form: directUrl
    }),
    [publicUrl, directUrl, screenerUrl]
  );

  if (isLoading) {
    return (
      <div className='h-56'>
        <Loading absolute />
      </div>
    );
  }

  return (
    <div className='flex flex-col pb-6 space-y-10'>
      {links.map((link) => (
        <div key={link}>
          <Text bold mb='2'>
            {LINK_LABELS[link]}
          </Text>
          <Text mb='2' h='400' color='gray-500'>
            {LINK_HELPERS[link]}
          </Text>
          <div className='bg-gray-100 border border-gray-200 rounded-md px-4 py-2.5 flex items-center space-x-2'>
            <Text truncate className='flex-1' h='400'>
              {linkUrls[link]}
            </Text>
            <CopyURLButton
              as='button'
              className='hover:bg-gray-200 hover:text-indigo-600 flex items-center justify-center rounded-full'
              icon='link'
              text={linkUrls[link]}
            >
              <LinkSVG />
            </CopyURLButton>
          </div>
        </div>
      ))}
    </div>
  );
};
