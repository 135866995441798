import { api } from 'api/reduxApi';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Alert, Loading } from '@components/common';
import { useGetWhoamiQuery } from '@components/stores/whoami';
import { track } from '@components/tracking';
import { CopyURLButton } from '@components/shared/CopyURLButton';
import { useToaster } from '@stores/toaster';

interface Props {
  study: Study;
}

interface LinkProps {
  onClick: () => void;
  selected: boolean;
}
interface TabProps {
  url: string;
  tab: string;
  warning?: boolean;
  img?: string;
}
const Link: React.FC<LinkProps> = ({ onClick, selected, children }) => {
  const classes = selected ? 'text-indigo-600 border-indigo-600 border-b-2 -mb-2' : 'text-gray-400';
  return (
    <div onClick={onClick} className={classes + ` font-bold mr-6 cursor-pointer`}>
      {children}
    </div>
  );
};

const Tab: React.FC<TabProps> = ({ url, tab, img, warning = false, children }) => {
  return (
    <div>
      <div className='relative mb-4'>
        <input
          type='text'
          disabled={true}
          className='bg-gray-50 w-full px-4 py-2 text-gray-700 border border-gray-200 rounded-md'
          value={url}
        />
        <div className='h-8 absolute top-0 bottom-0 m-auto right-4'>
          <CopyURLButton
            icon='link'
            noTippy
            medium
            onCopy={() => track('copied_study_invite_link')}
            name='Copy link'
            text={url}
          >
            Copy link
          </CopyURLButton>
        </div>
      </div>
      {warning ? <Alert type='warning'>{children}</Alert> : <p className='text-sm text-gray-500'>{children}</p>}
      {img && <img alt={`Thumbnail of the public ${TAB_LABELS[tab]} page`} src={img} className='shadow-lg' />}
    </div>
  );
};

type InviteTabs =
  | 'apply'
  | 'start_survey'
  | 'start_online_task'
  | 'start_unmoderated_test'
  | 'start_signup_form'
  | 'start_screener'
  | 'book'
  | 'join';

function getTabs(study: Study): string[] {
  const tabs: string[] = [];

  if (study.style === 'panel') {
    tabs.push('join', 'start_signup_form');

    if (study.has_screener) {
      tabs.push('start_screener');
    }
    return tabs;
  }
  tabs.push('apply');

  if (study.has_screener) {
    tabs.push('start_screener');
  }

  if (study.moderated) {
    tabs.push('book');
  } else {
    tabs.push('start_' + study.style);
  }
  return tabs;
}

const TAB_LABELS: { [key in InviteTabs]: string } = {
  start_survey: 'Survey',
  start_online_task: 'Task',
  start_unmoderated_test: 'Unmoderated test',
  apply: 'Landing Page',
  book: 'Scheduling page',
  join: 'Landing Page',
  start_screener: 'Screener',
  start_signup_form: 'Signup form'
};

const APPLY_TEXT = 'Anyone who receives this link can apply to your study.';
const PARTICIPATE_TEXT = 'Anyone who receives this link can participate.';

export const InviteLink: React.FC<Props> = ({ study }) => {
  const { data: whoami, isLoading, isError: whoamiError } = useGetWhoamiQuery();

  const { public_path, landing_page, state } = study;
  const { data: slots, isError: slotsError } = api.useGetStudySlotsBalanceQuery(study.id);

  const showToast = useToaster();

  const tabs = getTabs(study);

  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const publicBaseURL = whoami?.account_public_domain?.base_url;
  const baseURL = publicBaseURL ? publicBaseURL : window.location.protocol + '//' + window.location.host;
  const publicUrl = baseURL + '/' + public_path;
  const directUrl = publicUrl + '/direct';
  const screenerUrl = publicUrl + '/apply';

  const mustApply = study.has_screener;

  useEffect(() => {
    track('viewed_study_invite_modal');
  });

  useEffect(() => {
    if (whoamiError) {
      showToast({
        heading: 'Failed to load!',
        icon: 'error',
        text: 'Something went wrong loading the account settings. Please try again later.'
      });
    }
  }, [whoamiError]);

  useEffect(() => {
    if (slotsError) {
      showToast({
        heading: 'Failed to load!',
        icon: 'error',
        text: 'Something went wrong loading the study slots. Please try again later.'
      });
    }
  }, [slotsError]);

  if (!slots || isLoading) {
    return (
      <div className='h-56'>
        <Loading absolute />
      </div>
    );
  }

  const hasLimit = state == 'active' && !slots.limit_reached;
  // this should probably be more explicit.
  const canBook = state == 'active' && !slots.no_slots;
  return (
    <div>
      <h3 className='mb-6 text-2xl text-gray-900'>Invite via Link</h3>
      {tabs.length > 1 && (
        <div className='flex mb-6'>
          {tabs.map((tab) => (
            <Link key={tab} onClick={() => setCurrentTab(tab)} selected={currentTab === tab}>
              {TAB_LABELS[tab]}
            </Link>
          ))}
        </div>
      )}
      {currentTab === 'apply' && (
        <Tab tab={currentTab} url={publicUrl} img={landing_page?.screenshot_url}>
          {mustApply ? APPLY_TEXT : PARTICIPATE_TEXT}
        </Tab>
      )}
      {currentTab === 'start_screener' && (
        <Tab tab={currentTab} url={screenerUrl} img={landing_page?.direct_screenshot_url}>
          Anyone who receives this link can start the screener.
        </Tab>
      )}
      {currentTab === 'join' && (
        <Tab tab={currentTab} url={publicUrl} img={landing_page?.screenshot_url}>
          Anyone who receives this link can join your panel.
        </Tab>
      )}
      {['start_survey', 'start_online_task', 'start_unmoderated_test'].includes(currentTab) && (
        <Tab tab={currentTab} url={directUrl} img={landing_page?.direct_screenshot_url}>
          {PARTICIPATE_TEXT}
        </Tab>
      )}
      {currentTab === 'book' && hasLimit && canBook && (
        <Tab tab={currentTab} url={directUrl} img={landing_page?.direct_screenshot_url}>
          Anyone who receives this link can book time on your calendar.
        </Tab>
      )}
      {currentTab === 'book' && hasLimit && !canBook && (
        <Tab tab={currentTab} url={directUrl} warning img={landing_page?.direct_screenshot_url}>
          <span>
            There are no slots available to book. Consider updating your{' '}
            <RouterLink to={`/studies/${study.id}/calendar`}>calendar settings.</RouterLink>
          </span>
        </Tab>
      )}
      {currentTab === 'book' && !hasLimit && <p> Study has no available slots to be booked.</p>}
      {currentTab === 'start_signup_form' && (
        <Tab tab={currentTab} url={directUrl} img={landing_page?.direct_screenshot_url}>
          <p>Anyone who receives this link can complete the signup form.</p>
        </Tab>
      )}
    </div>
  );
};
