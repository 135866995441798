import * as Enums from './types/enums';

export const TITLE_HELPERS: Partial<Record<Enums.Kind, string>> = {
  [Enums.Kind.prototypeTest]: 'Give participants a task to accomplish with the prototype.'
};

export const TITLE_EXAMPLES: Partial<Record<Enums.Kind, string>> = {
  [Enums.Kind.prototypeTest]: 'Example: Book a flight',
  [Enums.Kind.websiteTest]: 'Example: Book a flight'
};

export const DESCRIPTION_HELPERS: Partial<Record<Enums.Kind, string>> = {
  [Enums.Kind.prototypeTest]: 'Give participants a scenario to assume while trying to accomplish the task.',
  [Enums.Kind.websiteTest]: 'Give participants a scenario to assume while trying to accomplish the task.'
};

export const DESCRIPTION_EXAMPLES: Partial<Record<Enums.Kind, string>> = {
  [Enums.Kind.prototypeTest]:
    "Example: Imagine you're looking to buy flight tickets for a round-trip vacation to Barcelona this summer.",
  [Enums.Kind.websiteTest]:
    "Example: Imagine you're looking to buy flight tickets for a round-trip vacation to Barcelona this summer."
};

export const RESPONSE_LABELS: Partial<Record<Enums.Kind, string>> = {
  [Enums.Kind.info]: '',
  [Enums.Kind.permissions]: '',
  [Enums.Kind.prototypeTest]: '',
  [Enums.Kind.thankYou]: '',
  [Enums.Kind.welcome]: '',
  [Enums.Kind.websiteTest]: '',
  [Enums.Kind.cardSort]: '',
  [Enums.Kind.treeTest]: ''
};

export const RESPONSE_HELPERS: Partial<Record<Enums.Kind, string>> = {};

export const RESPONSE_EXAMPLES: Partial<Record<Enums.Kind, string>> = {};

export const TITLE_PLACEHOLDER: Partial<Record<Enums.Kind, string>> = {
  [Enums.Kind.prototypeTest]: 'Write your task...'
};

export const PROTOTYPE_TEST_TOOLTIP =
  'Currently, participants will only be able to complete prototypes from a desktop device. Test links cannot be opened on mobile or tablet devices.';

export const DEFAULT_RESPONSE_LABEL = 'Response';

export const DEFAULT_DESCRIPTION_PLACEHOLDER = 'Give testers details to complete the task...';

export const DEFAULT_TITLE_PLACEHOLDER = 'Write your question...';
