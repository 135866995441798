import * as React from 'react';
import Tippy from '@tippyjs/react';
import { kFormat } from '@components/utils';

interface Props {
  request: ExternalCandidatesRequest;
}
export const AudienceEstimate: React.FC<Props> = ({ request }) => {
  if (request.attrs.eligible_audience === undefined) return null;

  return (
    <Tippy content='Audience estimates show how many active panelists will be invited to engage with your study. The number of panelists that apply will depend on factors including the incentive offered, the quality of your screener, and clarity of the project title and description.'>
      <span className='text-sm text-gray-500'>Audience estimate: {kFormat(request.attrs.eligible_audience)}</span>
    </Tippy>
  );
};
