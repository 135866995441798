import { useDeleteBrandMutation, useGetBrandQuery, useUpdateBrandMutation } from '@components/BrandingApp/api';
import { useToaster } from '@stores/toaster';
import { useEffect, useState } from 'react';
import * as toasts from '@components/BrandingApp/toasts';

type Hook = (team_id?: string) => {
  brand: Brand;
  initialBrand?: Brand;
  setBrand: (v: Brand) => void;
  logoFile?: File;
  setLogoFile: (v: File) => void;
  deleteBrand: () => void;
  updateBrand: () => void;
  isLoading: boolean;
  isFetching: boolean;
};
export const useBrand: Hook = (team_id) => {
  const { data: initialBrand, isError, isLoading } = useGetBrandQuery({ team_id });

  const [destroy, { isLoading: deleting, isSuccess: deleteSuccess, isError: deleteError }] = useDeleteBrandMutation();
  const [update, { isLoading: updating, isSuccess: updateSuccess, isError: updateError }] = useUpdateBrandMutation();

  const [brand, setBrand] = useState<Brand>();
  const [logoFile, setLogoFile] = useState<File>();

  const showToast = useToaster();

  const deleteBrand = () => destroy({ team_id });
  const updateBrand = () => update({ brand: brand as Brand, logo: logoFile });

  // get
  useEffect(() => {
    if (isError) {
      showToast({
        heading: 'Could not get brand colors',
        text: 'Something went wrong. Please try again or contact support.',
        icon: 'error'
      });
    }
  }, [isError]);

  // delete
  useEffect(() => {
    if (deleteSuccess) {
      showToast(toasts.SUCCESS_RESET);
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (deleteError) {
      showToast(toasts.FAILED_RESET);
    }
  }, [deleteError]);

  // update
  useEffect(() => {
    if (updateSuccess) {
      showToast(toasts.SUCCESS_UPDATE);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (updateError) {
      showToast(toasts.FAILED_UPDATE);
    }
  }, [updateError]);

  const css = `
  .brand h1:not(.no-branding),
  .brand h2:not(.no-branding),
  .brand h3:not(.no-branding),
  .brand h4:not(.no-branding),
  .brand h5:not(.no-branding),
  .brand p:not(.no-branding),
  .brand li:not(.no-branding),
  .brand label:not(.no-branding),
  .brand span:not(.no-branding):not(.btn-custom-brand > span),
  .brand div:not(.no-branding):not(.tippy-content):not(.tippy-arrow):not(.tippy-content *),
  .brand ol:not(.no-branding) {
    color: ${brand?.text_color};
  }
  .tippy-content {
    color: #fff;
  }
  .text-custom-brand {
    color: ${brand?.text_color};
  }
      
  .text-custom-brand-secondary {
    color: ${brand?.secondary_text_color} !important;
  }

  .input-custom-brand {
    color: ${brand?.button_color};
  }

  .text-custom-brand-button {
    color: ${brand?.button_text_color};
  }
  
  .bg-custom-brand-button {
    background-color: ${brand?.button_color};
  }

  .border-custom-brand { 
    border-color: ${brand?.button_color};
  }

  .focus-border-custom-brand:focus {
    border-color: ${brand?.button_color};
    --tw-ring-color: ${brand?.button_color};
  }

  .border-custom-brand-secondary {
    border-color: ${brand?.button_color};
    opacity: 0.8;
  }

  .btn-custom-brand {
    background-color: ${brand?.button_color};
    border-color: transparent;
    color: ${brand?.button_text_color};
  }
  .btn-custom-brand > svg {
    color: ${brand?.button_text_color};
  }
  .btn-custom-brand:active {
    background-color: ${brand?.button_color};
    color: ${brand?.button_text_color};
  }
  .btn-custom-brand:hover {
    background-color: ${brand?.button_color};
    filter: brightness(85%);
    color: ${brand?.button_text_color};
  }

  .btn-custom-brand:focus {
    border-color:${brand?.button_color};
    outline-color: ${brand?.button_color};
    color: ${brand?.button_text_color};
    outline-offset: 4px;
  }

  .btn-custom-brand-secondary {
    background-color: white;
    border: 1px solid ${brand?.secondary_text_color};
    color: ${brand?.text_color};
  }
  .btn-custom-brand-secondary > svg {
    color: ${brand?.text_color};
  }
  .btn-custom-brand-secondary:hover {
    background-color: ${brand?.button_color};
    color: ${brand?.button_text_color};
  }
  .btn-custom-brand-secondary:hover  span {
    color: ${brand?.button_text_color};
  }
  .btn-custom-brand-secondary:hover  svg {
    color: ${brand?.button_text_color};
  }

  .hover-bg-custom-brand-button:hover { 
    background-color: ${brand?.button_color};
  }

  .hover-text-custom-brand-button:hover { 
    color: ${brand?.button_text_color};
  }
  
  .focus-ring-custom-brand:focus {
    --tw-ring-color: ${brand?.button_color};
  }
  
  .logo-align-brand {
    text-align: ${brand?.logo_alignment};
  }

  .btn-custom-brand > span,
  .btn-custom-brand > svg,
  .btn-custom-brand > span:hover,
  .btn-custom-brand > svg:hover {
    color: ${brand?.button_text_color};
  }
`;

  const styleTag = document.createElement('style');
  styleTag.innerHTML = css;

  useEffect(() => {
    if (brand) {
      document.head.appendChild(styleTag);
    }
    return () => {
      if (brand) {
        document.head.removeChild(styleTag);
      }
    };
  }, [brand]);

  useEffect(() => {
    if (initialBrand) {
      setBrand(initialBrand);
    }
  }, [initialBrand]);

  return {
    isLoading: updating || deleting,
    isFetching: isLoading,
    updateBrand,
    initialBrand,
    brand: brand || ({} as Brand),
    deleteBrand,
    setBrand,
    logoFile,
    setLogoFile
  };
};
