import React from 'react';

import { Table } from '@components/shared/Table';
import { ColumnDef } from '@tanstack/react-table';
import { ChevronDownSVG } from '@components/svgs';
import { ProgressBar, Text, Tooltip } from '@components/common';
import { average, pct } from '@components/utils';
import cn from 'classnames';
import pluralize from 'pluralize';
import { CardsTableData } from '@components/ScreenerResults/components/CardSortResponse/components';
import { renderMergedCategories } from '@components/ScreenerResults/components/CardSortResponse/utils';

export type DefinitionFnReturn = ColumnDef<CardsTableData>[];

export type DefinitionFn = (args: {
  mergedCategories: { name: string; categories: string[] }[];
  answers: NonNullable<ScreenerResponseAnswerValue<'card_sort'>>[];
}) => DefinitionFnReturn;

export const getCardsTableColumnDefs: DefinitionFn = ({ answers, mergedCategories }) => {
  const columns: DefinitionFnReturn = [
    {
      id: 'card',
      accessorKey: 'card',
      cell: (props) => (
        <Table.Cell<CardsTableData>
          {...props}
          className={cn('h400 w-full flex bg-white items-center', {
            'h-11': !props.row.getIsExpanded(),
            'py-3': props.row.getIsExpanded()
          })}
          render={({ row }) => (
            <div className='flex flex-col px-2 truncate'>
              <div className='truncate'>{row.original.card || 'Unnamed'}</div>
              {row.getIsExpanded() && (
                <div className='flex flex-col space-y-3 mt-6'>
                  <Text bold h='200'>
                    Categories
                  </Text>
                  {row.original.categories.map(({ name }) => (
                    <Text h='400' className='flex items-center flex-nowrap'>
                      <span>{name}</span>
                      {renderMergedCategories({ category: name, mergedCategories })}
                    </Text>
                  ))}
                </div>
              )}
            </div>
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader {...props} disableHide>
          Card
        </Table.ColumnHeader>
      )
    },
    {
      id: 'categories_number',
      accessorFn: (row) => row.categories.length,
      cell: (props) => (
        <Table.Cell<CardsTableData>
          {...props}
          className={cn('h400 w-full flex bg-white items-center', {
            'h-11': !props.row.getIsExpanded(),
            'py-3': props.row.getIsExpanded()
          })}
          render={({ row }) => (
            <div className='flex flex-col px-2'>
              <div className='truncate'>{pluralize('category', row.original.categories.length || 0, true)}</div>
              {row.getIsExpanded() && (
                <div className='flex flex-col space-y-3 mt-6'>
                  <div className='flex items-center space-x-2'>
                    <div className='h200 font-bold'>Frequency</div>
                    <Tooltip>The number of responses where this card was put into this category</Tooltip>
                  </div>
                  {row.original.categories.map(({ frequency }) => (
                    <Text h='400'>{frequency}</Text>
                  ))}
                </div>
              )}
            </div>
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader {...props} disableHide sortIconType='number'>
          Number of categories
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => (
        <Table.Cell<CardsTableData>
          render={({ row }) => (
            <div className='flex flex-col px-2 flex-1'>
              <div className='flex flex-1 items-center'>
                <div className='flex-grow'>
                  <ProgressBar
                    size='sm'
                    progress={pct(average(row.original.categories.map(({ avgAgreement }) => avgAgreement)), 1)}
                  />
                </div>
                <div className='ml-4 w-8 h400 whitespace-nowrap'>
                  {pct(average(row.original.categories.map(({ avgAgreement }) => avgAgreement)), 1)} %
                </div>
              </div>
              {row.getIsExpanded() && (
                <div className='flex flex-col space-y-3 mt-6'>
                  <div className='flex items-center space-x-2'>
                    <div className='h200 font-bold'>Avg. Position</div>
                    <Tooltip>The average position that the card occupied in a category</Tooltip>
                  </div>
                  {row.original.categories.map(({ avgPosition }) => (
                    <Text h='400'>{avgPosition}</Text>
                  ))}
                </div>
              )}
            </div>
          )}
          {...props}
          className={cn('h400 w-full flex bg-white items-center', {
            'h-11': !props.row.getIsExpanded(),
            'py-3': props.row.getIsExpanded()
          })}
        />
      ),
      id: 'avg_agreement',
      accessorFn: (row) => average(row.categories.map(({ avgAgreement }) => avgAgreement)),
      header: (props) => (
        <Table.ColumnHeader {...props} disableHide sortIconType='number'>
          <div className='flex items-center flex-nowrap space-x-1'>
            <span>Avg. Agreement</span>
            <Tooltip content='The average agreement is calculated as a simple average between the agreement rates of all cards inside a category'></Tooltip>
          </div>
        </Table.ColumnHeader>
      )
    },
    {
      cell: (props) => (
        <Table.Cell<CardsTableData>
          {...props}
          render={({ row }) =>
            row.getCanExpand() && (
              <div className='px-2 flex w-full items-center justify-end'>
                <button
                  aria-label={row.getIsExpanded() ? 'Collapse' : 'Extend'}
                  onClick={row.getToggleExpandedHandler()}
                  className='focus:ring focus:ring-blue focus:outline-none hover:bg-gray-50 bg-white rounded-full flex-shrink-0 p-2'
                >
                  <ChevronDownSVG className={cn('w-4 h-4 transform', { ['rotate-180']: row.getIsExpanded() })} />
                </button>
              </div>
            )
          }
          className={cn('h400 flex flex-col items-center bg-white', {
            'h-11 justify-center': !props.row.getIsExpanded(),
            'py-3 align-top': props.row.getIsExpanded()
          })}
        />
      ),
      id: 'expand',
      header: (props) => <Table.ColumnHeader {...props} isStatic />
    }
  ];

  return columns;
};
