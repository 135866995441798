import cn from 'classnames';
import React, { HTMLAttributes, useEffect, useMemo, useState } from 'react';

import {
  ColumnOrderState,
  DeepKeys,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  PaginationState,
  RowSelectionState,
  useReactTable,
  VisibilityState
} from '@tanstack/react-table';

import { composeEventHandlers } from '@helpers/composeEventHandlers';

import { Cell, Checkbox, ColumnHeader } from './components';
import { columnSorting, getPreviousCellSize, updateCellData } from './helpers';
import { Provider } from './hooks';
import { Context, OnUpdateCellParams, Props } from './types';

const TableComponent = <D extends Record<string, any> = Record<string, any>>({
  data: initialData,
  columns,
  columnPinning = { left: [], right: [] },
  editableColumns,
  columnOrder: initialColumnOrder,
  visibleColumns,
  isCompact,
  currentPage,
  pageSize = 100,
  sortBy: initialSortBy,
  manualPagination,
  manualSorting,
  selectedRows,
  rowIdentifier,
  onUpdateCell,
  onSort,
  onSelectRow,
  onColumnOrderChange,
  onColumnVisibilityChange,
  cellClassName,
  ...rest
}: Props<D> & HTMLAttributes<HTMLTableElement>) => {
  const [data, setData] = useState<D[]>(initialData);
  const [errors, setErrors] = useState<Context<D>['errors']>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(visibleColumns ?? {});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(initialColumnOrder ?? []);
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize });
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [key, setKey] = useState(0);

  const context: Context<D> = useMemo(
    () => ({
      errors,
      editableColumns,
      isCompact,
      setErrors
    }),
    [errors, editableColumns, isCompact, setErrors]
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowCanExpand: (row) => true,
    getExpandedRowModel: getExpandedRowModel(),
    getRowId: (row, index) => {
      if (rowIdentifier && rowIdentifier in row) return row[rowIdentifier as string];
      else return index;
    },
    initialState: { columnPinning },
    columnResizeMode: 'onChange',
    meta: {
      updateCellData: composeEventHandlers(
        (params: OnUpdateCellParams<D>) => onUpdateCell?.({ ...params, errors, setErrors }),
        (params: OnUpdateCellParams<D>) => setData(updateCellData<D>(data, params))
      ),
      onSort: composeEventHandlers(onSort, (sortBy: CollectionView['sort']) => columnSorting<D>(sortBy, table))
    },
    state: {
      columnVisibility,
      columnOrder,
      pagination,
      rowSelection
    },
    manualPagination,
    manualSorting,
    onRowSelectionChange: (updater) => {
      if (typeof updater === 'function') {
        const state = updater(rowSelection);
        onSelectRow?.(state);
        setRowSelection(state);
      }
    },
    onColumnVisibilityChange: (updater) => {
      if (typeof updater === 'function') {
        const state = updater(columnVisibility);
        onColumnVisibilityChange?.(state as Record<DeepKeys<D>, boolean>);
        setColumnVisibility(state);
      }
    },
    onColumnOrderChange: (updater) => {
      if (typeof updater === 'function') {
        const state = updater(columnOrder);
        onColumnOrderChange?.(state);
        setColumnOrder(state);
      }
    }
  });

  useEffect(() => {
    if (visibleColumns) {
      setColumnVisibility(visibleColumns);
    }
  }, [visibleColumns]);

  useEffect(() => {
    if (initialColumnOrder) {
      setColumnOrder(initialColumnOrder);
    }
  }, [initialColumnOrder]);

  useEffect(() => {
    if (selectedRows) {
      setRowSelection(selectedRows);
    }
  }, [selectedRows]);

  useEffect(() => {
    if (typeof currentPage === 'number') {
      setPagination({ ...pagination, pageIndex: currentPage });
    }
  }, [currentPage]);

  useEffect(() => {
    if (initialSortBy) {
      columnSorting<D>(initialSortBy, table);
    }
  }, [initialSortBy]);

  useEffect(() => {
    setData(initialData);
    setKey((prev) => prev + 1);
  }, [initialData]);

  return (
    <Provider<D> value={context}>
      <table {...rest} key={key}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{
                    width: header.getSize(),
                    left: getPreviousCellSize<D>(headerGroup.headers, header)
                  }}
                  className={cn(
                    {
                      'sticky z-10': header.column.getIsPinned(),
                      'right-0': header.column.getIsPinned() === 'right'
                    },
                    'p-0'
                  )}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  style={{
                    width: cell.column.getSize(),
                    left: getPreviousCellSize<D>(row.getVisibleCells(), cell)
                  }}
                  className={cn(
                    {
                      'sticky z-10': cell.column.getIsPinned(),
                      'right-0': cell.column.getIsPinned() === 'right'
                    },
                    'p-0',
                    cellClassName
                  )}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Provider>
  );
};

export const Table = TableComponent as typeof TableComponent & {
  Cell: typeof Cell;
  ColumnHeader: typeof ColumnHeader;
  Checkbox: typeof Checkbox;
};

Table.Cell = Cell;
Table.ColumnHeader = ColumnHeader;
Table.Checkbox = Checkbox;
