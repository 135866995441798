import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

type Params = {
  hasMore: boolean;
  refineNext: () => void;
};

type Hook = {
  sentinel: (node?: Element | null) => void;
};

export const useInfiniteScroll = ({ hasMore, refineNext }: Params): Hook => {
  const { ref, inView } = useInView({ triggerOnce: false, skip: !hasMore });

  const intervalRef = useRef<number | null>(null);

  const clearRefineInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  useEffect(() => {
    if (inView && hasMore) {
      if (!intervalRef.current) {
        intervalRef.current = window.setInterval(refineNext, 1000);
      }
    } else {
      clearRefineInterval();
    }

    return clearRefineInterval;
  }, [inView, hasMore, refineNext]);

  return { sentinel: ref };
};
