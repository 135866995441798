import cn from 'classnames';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';

import { Text } from '@components/common';
import { ErrorSvg } from '@components/svgs';

interface Props {
  consentCheckboxes: ConsentCheckbox[];
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
}

const ConsentItem: React.FC<{
  consentCheckbox: ConsentCheckbox;
  errors: UseFormMethods['errors'];
  register: UseFormMethods['register'];
}> = ({ consentCheckbox, errors, register }) => {
  const { key, pre_html, label, required, format, html_id: htmlId, html_name: name } = consentCheckbox;
  const error = (errors.consent || {})[key];
  const inputClass = cn('h-4 w-4  focus-ring-custom-brand input-custom-brand border-gray-300 rounded mt-1', {
    'border-red-600 focus:border-red-300 focus:ring-red': error
  });
  return (
    <>
      {pre_html && <div dangerouslySetInnerHTML={{ __html: pre_html }} />}
      <li className='flex flex-row items-start space-x-2 text-lg' key={'li' + htmlId}>
        <input id={htmlId} type='checkbox' className={inputClass} name={name} ref={register({ required })} />
        <label htmlFor={htmlId} className={cn('text-base font-normal', { 'text-red-600': error })}>
          {format == 'html' ? <div dangerouslySetInnerHTML={{ __html: label }} /> : label}
        </label>
      </li>
    </>
  );
};

export const ConsentCheckboxes: React.FC<Props> = ({ consentCheckboxes, register, errors }) => {
  return (
    <div>
      <div className='relative mt-6'>
        {errors.consent && (
          <Text color='red-600' h='400' className='-top-5 absolute flex items-center col-span-3'>
            <ErrorSvg className='mr-2' />
            Please accept the required terms of participation.
          </Text>
        )}
        <ul className='space-y-2'>
          {consentCheckboxes.map((consentCheckbox) => (
            <ConsentItem consentCheckbox={consentCheckbox} errors={errors} register={register} />
          ))}
        </ul>
      </div>
    </div>
  );
};
