import React, { useEffect } from 'react';
import { StepHelper, StepTitle } from './shared';

import { ExternalCandidatesRequestForm } from '@components/StudiesApp/components/StudyPublished/pages/ExternalCandidatesRequest/components/ExternalCandidatesRequestForm';
import { useRecruitingRequest } from '@components/StudiesApp/components/StudyDraft/hooks/useRecruitingRequest';
import { useDebouncedCallback } from 'use-debounce';
import { Loading } from '@components/common';

export const Recruiting: React.FC<PageProps> = ({ study }) => {
  const { requestInitialized, request, handleNew, handleChange } = useRecruitingRequest({ study: study });

  const { callback: debouncedHandleChange } = useDebouncedCallback(handleChange, 1000);

  useEffect(() => {
    if (requestInitialized && !request) {
      handleNew();
    }
  }, [requestInitialized]);

  if (requestInitialized && request) {
    return <ExternalCandidatesRequestForm study={study} request={request} onChange={debouncedHandleChange} />;
  }

  return <Loading absolute />;
};
