import cn from 'classnames';
import { ProfileContextProvider } from 'hooks/useProfileContext';
import { useUser } from 'hooks/useUser';
import { uniq } from 'lodash';
import { Resizable } from 're-resizable';
import * as React from 'react';
import { createContext, MutableRefObject, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Route, Routes, useParams, useSearchParams } from 'react-router-dom';

import { AI, AIBots, HighlightSuggestion, useLazySuggestions } from '@api/chat-gpt';
import { changeParticipationStatus, getParticipation } from '@api/queries';
import { api } from '@api/reduxApi';
import { Alert, Loading, Tabs, Text, Toggle } from '@components/common';
import { PageTitle } from '@components/common/helmets';
import { Item, Menu } from '@components/common/Menu';
import { Preview } from '@components/Loom';
import { useTranscriptStatus } from '@components/RepoSessionApp/RecordingWidget/hooks/useTranscriptStatus';
import { BackgroundTaskStatus } from '@components/shared/BackgroundTaskStatus';
import { CalendarEventSlideOut } from '@components/shared/CalendarEventSlideOut';
import { ParticipationPill } from '@components/shared/ParticipationPill';
import { RestrictedAction } from '@components/shared/RestrictedAction';
import { Summary } from '@components/shared/Summary';
import { Chapters } from '@components/shared/Summary';
import { Tiptap, useTiptapFromDocumentId } from '@components/shared/Tiptap';
import { useDestroyDocumentHighlightMutation, useGetRecordingQuery } from '@components/shared/Tiptap/api';
import { DeleteRecordingModal, ResetTranscriptModal } from '@components/shared/Tiptap/components';
import { MenuBarButton } from '@components/shared/Tiptap/components/MenuBarButton';
import { useModalManager, useTagAiSuggestions, useTranscript } from '@components/shared/Tiptap/hooks';
import * as Icons from '@components/shared/Tiptap/icons';
import { useToaster } from '@components/stores/toaster';
import { CancelSlideOut, ThanksSlideOut } from '@components/StudyMessages';
import { DownloadSVG, SplitPageSVG } from '@components/svgs';
import { track } from '@components/tracking';
import { compact } from '@components/utils';
import { UseVideoPlayer } from '@components/VideoPlayer';
import { useAccount } from '@hooks/useAccount';
import { useDeviceType } from '@hooks/useDeviceType';
import { useFeature } from '@hooks/useFeature';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { usePermission } from '@hooks/usePermission';
import { useAiChat, useShowAiChatBubble } from '@stores/aiChat';

import { HighlightsTab, TranscriptTab, ResponsesTab, LiveStreamWrapper } from './components';
import { Header } from './Header';
import { scrollToHighlight } from './helpers';
import { PageBody, PageLayout, PageLeft, PageRight } from './Layout';
import { PageSkeleton } from './PageSkeleton';
import { Actions as ProfileSlideoutActions, ProfileSlideout, ProfileSlideoutProps } from './ProfileSlideout';
import { RecordingWidget } from './RecordingWidget';
import * as toasts from './toasts';
import { track as partyTrack } from './tracking';
import { ErrorBoundary } from 'components/ErrorBoundary';

type Slideout = 'profile' | 'complete' | 'canceled';

interface Context {
  sessionMembers: { owner: User | null | undefined; candidate: Candidate | null };
  widgetWidth: string | number;
  videoPlayerRef: MutableRefObject<UseVideoPlayer | null>;
}

export const SessionContext = createContext<Context>({} as Context);
export const useSessionContext = () => {
  const sessionContext = useContext<Context>(SessionContext);

  if (!sessionContext) throw new Error();

  return sessionContext;
};

type Tab = 'transcript' | 'highlights' | 'notes' | 'summary' | 'responses';

export const SessionPage: React.FC = () => {
  useShowAiChatBubble();
  const { uuid } = useParams() as { uuid: string };

  const [backgroundTask, setBackgroundTask] = useState<BackgroundTask>();

  const {
    account: { id: accountId, team, ai_enabled: aiEnabled }
  } = useAccount();
  const { data: session, isLoading, isError: sessionFetchError, refetch } = api.useGetRepoSessionQuery({ uuid: uuid });

  const {
    study,
    recording,
    participation: sessionParty = null,
    candidate: sessionCandidate = null,
    creator,
    document_id: documentId = null,
    uuid: sessionUuid
  } = session ?? {};

  const studyId = study?.id;

  const { abort: abortTagSuggestions } = useTagAiSuggestions({ studyId });

  const user = useUser();

  const { id: currentUserId } = user;

  const { data } = api.useGetStudyUsersQuery(studyId as number, { skip: !studyId });
  const { data: recordingData } = useGetRecordingQuery({ id: recording?.id as number }, { skip: !recording?.id });
  const { data: liveStream } = api.useGetRepoSessionsLiveStreamQuery({ uuid }, { skip: !uuid });
  const [updateRepoSession, { isSuccess, isError, isLoading: isUpdating }] = api.useUpdateRepoSessionMutation();
  const [destroyDocumentHighlight] = useDestroyDocumentHighlightMutation();

  const [liveStreamLocked, setLiveStreamLocked] = useState<boolean>(false);
  const [slideOut, setSlideOut] = useState<Slideout | null>();
  const [participation, setParticipation] = useState<Participation | null>(sessionParty);
  const [candidate, setCandidate] = useState<Candidate | null>(sessionCandidate);
  const [saving, setSaving] = useState(false);
  const [activeTab, setActiveTab] = useState<Tab>('transcript');
  const [noStudyModalIsOpen, setNoStudyModalIsOpen] = useState<boolean>(false);
  const [emptySummaryModalIsOpen, setEmptySummaryModalIsOpen] = useState<boolean>(false);
  const [askedForSummary, setAskedForSummary] = useState<boolean>(false);

  const [allTagIds, setAllTagIds] = useState<number[]>([]);
  const [appliedFilters, setAppliedFilters] = useState<number[]>([]);
  const [liveStreamStatus, setLiveStreamStatus] = useState<RepoSessionsLiveStream['status'] | undefined>(
    liveStream?.status
  );
  const [touchedSummary, setTouchedSummary] = useState<boolean>(false);
  const [aiSummary, setAiSummary] = useState<AI.UseQueryResult<string> | undefined>();
  const [scroll, setScroll] = useState<number>();
  const [summaryScroll, setSummaryScroll] = useState<number>();

  const [widgetWidth, setWidgetWidth] = useLocalStorage<number | string>('session-page-sides-ratio');
  const [hidePageRight, setHidePageRight] = useLocalStorage('hide-session-page-right');

  const videoPlayerRef = useRef<UseVideoPlayer | null>(null);
  const tabWrapperRef = useRef<HTMLDivElement>(null);
  const summaryRef = useRef<HTMLDivElement>(null);

  const { activeModal, closeModal, setActiveModal } = useModalManager<'destroyRecording' | 'resetTranscript'>();

  const [search] = useSearchParams();
  const tsStr = search.get('ts');
  const ts = tsStr ? parseInt(tsStr) : null;
  const [videoTrack, setVideoTrack] = useState<boolean>(!!ts);

  const showToast = useToaster();

  const { isMobile, isTablet, device } = useDeviceType();

  const canUpdate = usePermission<RepoSession>('updateRepo')();

  // this is almost definitely wrong and shoud be abstracted into a single function that we refactor later.
  const isModerator = data && data.some((user) => user.user_id === currentUserId && user.role === 'moderator');
  const upcomingOrLiveInterview: boolean = !!liveStream && !['ended', 'failed'].includes(liveStream.status);

  const tiptap = useTiptapFromDocumentId({
    documentId: documentId as number,
    studyId,
    slideoutView: true,
    liveUpdate: true,
    readonly: !canUpdate || (liveStreamLocked && !isModerator),
    config: {
      import_document: useFeature('import_document'),
      highlight: {
        enable: true,
        menuPlacement: isMobile || isTablet ? 'top-start' : 'left-start',
        readOnly: !useFeature('non_video_highlights')
      },
      link: { enable: true },
      image: { enable: true },
      templates: true,
      artifacts: true,
      placeholder: 'Write learnings, embed content, and so much more…',
      tables: true,
      columns: true
    }
  });

  // TODO: an we just use session.creator
  const owner = study?.owner || creator;

  const sessionMembers = {
    owner,
    candidate
  };

  const defaultSpeakers = useMemo(() => {
    let list: (TeamUser | Candidate)[] = [...team];

    if (sessionMembers.owner) {
      list = list.filter((u) => u.id !== sessionMembers.owner?.id);
      list.unshift(sessionMembers.owner);
    }

    if (sessionMembers.candidate) {
      list = list.filter((u) => u.id !== sessionMembers.candidate?.id);
      list.unshift(sessionMembers.candidate);
    }

    return list;
  }, [sessionMembers, team]);

  const tiptapWithTranscript = useTiptapFromDocumentId({
    documentId: recording?.transcript?.document_id as number,
    recordingId: recording?.id,
    studyId,
    sessionUuid,
    config: {
      highlight: { enable: true, menuPlacement: isMobile || isTablet ? 'top-start' : 'left-start' },
      transcript: { defaultSpeakers }
    },
    readonly: !canUpdate || (liveStreamLocked && !isModerator),
    withoutPlayer: true
  });

  const { message: transcriptStatus } = useTranscriptStatus(recording?.transcript);

  const { ref, update } = useTranscript({
    disable: false,
    root: tiptapWithTranscript.editor?.view.dom,
    track: videoTrack,
    initialTs: ts
  });

  const summarySuggestionHook = useLazySuggestions<string, { context_type: string; id?: string; account_id: number }>(
    {
      id: AIBots.TranscriptTextSummary,
      context: { context_type: 'interview', id: sessionUuid, account_id: accountId }
    },
    { skip: !sessionUuid || !aiEnabled }
  );

  const highlightSuggestionsHook = useLazySuggestions<
    HighlightSuggestion,
    { repo_session_uuid?: string; study_id?: number | null }
  >(
    {
      id: AIBots.TranscriptHighlights,
      context: { repo_session_uuid: sessionUuid, study_id: studyId }
    },
    { skip: !sessionUuid || !studyId || !aiEnabled }
  );

  const aiChat = useAiChat();

  useEffect(() => {
    document.body.classList.add('overflow-hidden');

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedUpdate());
    }
  }, [isError]);

  useEffect(() => {
    if (sessionFetchError) {
      showToast(toasts.failedFetchingSession());
    }
  }, [sessionFetchError]);

  useEffect(() => {
    if (sessionCandidate !== candidate) {
      setCandidate(sessionCandidate);
    }
  }, [sessionCandidate]);

  useEffect(() => {
    if (liveStream) {
      setLiveStreamLocked(liveStream?.status == 'live');
      setLiveStreamStatus(liveStream?.status);
    }
  }, [liveStream]);

  useEffect(() => {
    if (tiptap?.editor) {
      const readonly = !canUpdate || (liveStreamLocked && !isModerator);
      tiptap.editor.setEditable(!readonly);
    }
  }, [canUpdate, liveStreamLocked, isModerator]);

  useEffect(() => {
    if (isMobile) {
      setWidgetWidth('100%');
    }
  }, [isMobile]);

  const onResize = () => {
    if (scroll) {
      tabWrapperRef.current?.scrollTo({ top: scroll });
    }
    if (summaryScroll) {
      summaryRef.current?.scrollTo({ top: summaryScroll });
    }
  };

  useEffect(() => {
    if (tiptap.editor) {
      const trackProps = {
        layout: 'page',
        artifact_type: 'session',
        session_type: participation ? 'interview' : 'recording',
        session_uuid: uuid,
        is_creator: currentUserId === session?.creator.id,
        is_guest: false,
        has_recording: (recording?.mux_video?.status || '') === 'ready',
        has_transcript: (recording?.transcript?.state || '') === 'ready',
        has_notes: tiptap.editor.state && tiptap.editor.state.doc.content.size > 20,
        has_candidate: !!candidate,
        has_party: !!participation,
        sample: participation?.study?.sample
      };
      track('viewed_session', trackProps);
    }
  }, [!!tiptap.editor]);

  useEffect(() => {
    setCandidate(sessionCandidate);
  }, [sessionCandidate]);

  useEffect(() => {
    setParticipation(sessionParty);
  }, [sessionParty]);

  useEffect(() => {
    if (!isMobile && !isTablet && activeTab === 'summary') {
      setActiveTab('transcript');
    }
  }, [isMobile, isTablet]);

  useEffect(() => {
    if (session && !session.summary && !touchedSummary) {
      handleFetchSummarySuggestions();
    }
  }, [session]);

  useEffect(() => {
    if (ts && tiptapWithTranscript.editor?.view) {
      scrollToHighlight(tiptapWithTranscript.editor.view.dom, ts);
    }
  }, [tiptapWithTranscript.editor?.view]);

  // methods and handlers
  const onScroll = () => {
    if (isMobile) return;

    if (tabWrapperRef.current?.scrollTop) {
      setScroll(tabWrapperRef.current.scrollTop);
    }
  };

  const onSummaryScroll = () => {
    if (isMobile) return;

    if (summaryRef.current?.scrollTop) {
      setSummaryScroll(summaryRef.current.scrollTop);
    }
  };

  const onFilterClick = (tag: number) => {
    setAppliedFilters((prev) => (prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]));
  };

  const markAs = async (status: string) => {
    setSaving(true);
    try {
      const party = await changeParticipationStatus(participation?.id, status);

      if (party) {
        setParticipation(party);
        partyTrack(party)('marked_interview_candidate_as', { marked_as: status });
      }
    } catch (e) {
      console.error(e);
    }

    setSaving(false);
  };

  const handleUpdateSuccess = async () => {
    setSaving(true);
    setSlideOut(null);
    const resp = await getParticipation(participation?.id);
    setParticipation(resp);
    setSaving(false);
  };

  const handleRecordingCreate = (recording: Recording) => {
    updateRepoSession({ uuid: session?.uuid ?? '', recording_id: recording.id });
  };

  const updateSession = (params: Partial<Omit<RepoSession, 'uuid'>>) => {
    updateRepoSession({ uuid: session?.uuid ?? '', ...params });
  };

  const markAsComplete = () => {
    setSlideOut('complete');
  };

  const markAsNoShow = () => markAs('no_show');

  const markAsCanceled = () => setSlideOut('canceled');

  const onProfileClose = () => setSlideOut(null);

  const onCandidateClick = () => {
    setSlideOut('profile');
    participation && partyTrack(participation)('opened_candidate_profile', {});
  };

  const onWidgetResize = (_e, _direction, ref) => {
    setWidgetWidth(ref.offsetWidth);
  };

  const getTabs = (): Tab[] =>
    compact([
      (isMobile || isTablet) && 'summary',
      'transcript',
      session?.kind === 'unmoderated_test' && 'responses',
      'highlights',
      'notes'
    ]);

  const handleTabSelection = (value: Tab) => {
    if (!isMobile) {
      setScroll(0);
    }

    setActiveTab(value);

    if (value === 'summary') {
      handleFetchSummarySuggestions();
    } else {
      // if (studyId) {
      //   summarySuggestionHook.clear();
      // }
    }
  };

  const handleStopAi = () => {
    abortTagSuggestions();
  };

  const handleFetchSummarySuggestions = async (showModal = false) => {
    setAiSummary(undefined);

    if (!aiEnabled) return;

    if (isMobile || isTablet) {
      setActiveTab('summary');
    }

    setAskedForSummary(true);

    const { data } = await summarySuggestionHook.fetch();

    setAiSummary(data);

    if ((!data || data.length === 0) && showModal) {
      setEmptySummaryModalIsOpen(true);
    }
  };

  const handleItemSelect = (value: 'destroyRecording' | 'resetTranscript' | 'downloadTranscript') => {
    if (value === 'downloadTranscript') {
      if (recording?.transcript) {
        const downloadUrl = `/transcripts/${recording.transcript.id}/download`;
        window.open(downloadUrl, '_blank');
      }
    } else {
      setActiveModal(value);
    }
  };

  const { data: highlights } = api.useGetHighlightsFromDocumentQuery(documentId as number, { skip: !documentId });
  const { data: clips } = api.useGetHighlightsFromDocumentQuery(recording?.transcript?.document_id as number, {
    skip: !recording?.transcript?.document_id
  });

  useEffect(() => {
    if (clips) {
      const tags = clips.reduce((acc, curr) => [...acc, ...curr.tag_ids], []);

      setAllTagIds(uniq(tags));
    }
  }, [clips]);

  const filteredHighlights: Highlight[] = useMemo(() => {
    const records = [...(clips || []), ...(highlights || [])];

    if (!appliedFilters.length || appliedFilters.length === allTagIds.length) {
      return records;
    }

    return records.filter((clip) => clip.tag_ids.some((tagId) => appliedFilters.includes(tagId)));
  }, [clips, appliedFilters, highlights]);

  const maxWidgetWidth = device.select({ MOBILE: '100%', TABLET: '100%', DEFAULT: '75%' });

  const minWidgetWidth = device.select({ TABLET: '40%', DEFAULT: '25%' });

  const widgetSize = { width: isMobile || isTablet ? '100%' : widgetWidth || '50%', height: 'auto' };

  const resizeStatus = {
    left: !isMobile && !isTablet
  };

  let profileSlideOutActions: ProfileSlideoutActions | undefined;

  if (participation?.status === 'booked') {
    profileSlideOutActions = { markAs: { onClick: markAs } };
  }

  const showSections: ProfileSlideoutProps['showSections'] = {
    about: true,
    interviewDetails: participation ? { participation } : undefined,
    surveyScreenerResponses: participation?.study?.survey_screener && {
      participationId: participation.id,
      studyId: participation.project_id,
      screenerId: participation.study.survey_screener.id
    },
    generalSections: true,
    calendarEvents: participation?.study?.style === 'video_call',
    bookingDetails: participation?.study?.style === 'video_call'
  };

  let videoType: 'recording' | 'livestream' | 'loom' = 'recording';

  if (participation?.loom_recording_id) {
    videoType = 'loom';
  } else if (upcomingOrLiveInterview) {
    videoType = 'livestream';
  }

  // render funcs
  const ProfileSlideOutHeader = () => (
    <div className='flex items-center space-x-2'>
      {participation && participation.study?.title && (
        <span className='block text-lg text-gray-700'>{participation.study.title}</span>
      )}
      <ParticipationPill participation={participation as any} />
    </div>
  );

  const renderSummary = () => {
    return session ? (
      <section contentEditable={false} className='px-6 mt-6'>
        {session.chapters != null && session.chapters.chapters != null && (
          <>
            <Text bold className='mt-1 mb-2'>
              Chapters
            </Text>
            <Chapters chapters={session.chapters.chapters} />
          </>
        )}
        <Text bold className='mt-1 mb-2'>
          Summary
        </Text>
        <Summary
          readOnly={!canUpdate}
          loading={isLoading}
          aiSummary={aiSummary}
          session={session}
          onChange={() => setTouchedSummary(true)}
        />
      </section>
    ) : null;
  };

  const renderTabContent = (tab: Tab) => {
    const tabContentClassName = 'w-full h-full overflow-y-scroll';
    switch (tab) {
      case 'highlights':
        return (
          <div onScroll={onScroll} ref={tabWrapperRef} className={cn('flex-1', tabContentClassName)}>
            <HighlightsTab
              appliedFilters={appliedFilters}
              highlights={filteredHighlights}
              onFilter={onFilterClick}
              allTagIds={allTagIds}
              recording={recordingData}
            />
          </div>
        );
      case 'summary':
        return renderSummary();
      case 'transcript':
        return (
          <>
            <div className='flex items-center justify-end w-full px-4 py-2 border-b border-gray-200'>
              <Text h='400' className='mr-2'>
                Auto-scroll
              </Text>
              <Toggle on={videoTrack} onToggle={setVideoTrack} />
              {recording?.mux_video?.playback_id && (
                <Menu
                  className='overflow-hidden text-sm bg-white border border-gray-200 rounded-md shadow-lg'
                  popperProps={{ zIndex: 30 }}
                  onItemSelect={handleItemSelect}
                  renderTrigger={({ isOpen }) => (
                    <MenuBarButton className='w-6 h-6 ml-2' isActive={isOpen} aria-label='Recording options'>
                      <Icons.Ellipsis />
                    </MenuBarButton>
                  )}
                >
                  {transcriptStatus === 'ready' && (
                    <RestrictedAction feature='transcript_download'>
                      {({ may }) => (
                        <Item
                          className='hover:bg-indigo-600 hover:text-white flex items-center px-4 py-2'
                          value='downloadTranscript'
                          isDisabled={!may}
                        >
                          <DownloadSVG className='mr-2' />
                          Download transcript
                        </Item>
                      )}
                    </RestrictedAction>
                  )}
                  {canUpdate && (
                    <>
                      <Item
                        className='hover:bg-indigo-600 hover:text-white flex items-center px-4 py-2'
                        value='resetTranscript'
                      >
                        <Icons.Reload className='mr-2' />
                        Restore original transcript
                      </Item>
                      <Item
                        className='xx-delete-video hover:bg-indigo-600 hover:text-white flex items-center px-4 py-2 text-red-600'
                        value='destroyRecording'
                      >
                        <Icons.Trash className='mr-2' />
                        Delete recording
                      </Item>
                    </>
                  )}
                </Menu>
              )}
            </div>
            <div ref={tabWrapperRef} className='w-full h-full overflow-y-scroll'>
              <TranscriptTab
                ref={ref}
                transcriptStatus={transcriptStatus}
                tiptap={tiptapWithTranscript}
                sessionUuid={sessionUuid}
                studyId={studyId}
                recording={recording || null}
              />
            </div>
            {recording && recording.transcript && (
              <ResetTranscriptModal
                clipsCount={clips?.length || 0}
                transcript={recording.transcript}
                isOpen={activeModal === 'resetTranscript'}
                onClose={closeModal}
              />
            )}

            {recording?.id && (
              <DeleteRecordingModal
                isOpen={activeModal === 'destroyRecording'}
                onClose={closeModal}
                recordingId={recording?.id}
              />
            )}
          </>
        );
      case 'notes':
        return (
          <div onScroll={onScroll} ref={tabWrapperRef} className={tabContentClassName}>
            <Tiptap {...tiptap.getTiptapProps()}>
              <tiptap.Menu
                {...tiptap.getMenuProps({ className: 'border-b border-gray-200 sticky top-0 bg-white z-10 px-4' })}
              />
              <div className='px-6 py-4'>
                <tiptap.Content {...tiptap.getContentProps()} />
              </div>
            </Tiptap>
          </div>
        );
      case 'responses':
        return (
          <ResponsesTab
            className={tabContentClassName}
            screenerId={study?.survey_screener?.id}
            studyId={studyId}
            participation={participation}
          />
        );
    }
  };

  if (isLoading) {
    return <PageSkeleton />;
  }

  const aiChatContext: AiChatContext | null = session
    ? { context_name: session.title, context_type: 'interview', id: session.uuid }
    : null;

  return (
    <PageLayout>
      <Routes>
        <Route path='/calendar_events/:id' element={<CalendarEventSlideOut />} />
      </Routes>
      {saving && <Loading absolute />}
      {slideOut === 'complete' && study && participation && (
        <ThanksSlideOut
          study={study}
          participations={[participation]}
          selectedParticipationIds={[participation.id]}
          onSuccess={handleUpdateSuccess}
          onClose={() => setSlideOut(null)}
        />
      )}
      {slideOut == 'canceled' && study && participation && (
        <CancelSlideOut
          study={study}
          participations={[participation]}
          onClose={() => setSlideOut(null)}
          onSuccess={handleUpdateSuccess}
        />
      )}
      {candidate && (
        <ProfileContextProvider>
          <ProfileSlideout
            activeParticipation={participation || undefined}
            canUpdate={canUpdate}
            header={ProfileSlideOutHeader}
            candidate={candidate}
            setLoading={setSaving}
            onChangeCandidate={setCandidate}
            open={slideOut === 'profile'}
            onClose={onProfileClose}
            actions={profileSlideOutActions}
            showSections={showSections}
          />
        </ProfileContextProvider>
      )}
      <PageTitle>{session?.title || 'Untitled session'}</PageTitle>
      <Header
        participation={participation}
        candidate={candidate}
        study={session?.study}
        track={track}
        owner_id={owner?.id ?? null}
        title={session?.title ?? null}
        markAsComplete={markAsComplete}
        markAsNoShow={markAsNoShow}
        markAsCanceled={markAsCanceled}
        onCandidateClick={onCandidateClick}
        session={session}
        updateSession={updateSession}
        canUpdate={canUpdate}
        aiChatContext={aiChatContext}
        onAddBackgroundTask={setBackgroundTask}
        renderBackgroundTask={
          backgroundTask
            ? () => (
                <BackgroundTaskStatus
                  backgroundTask={backgroundTask}
                  setBackgroundTask={setBackgroundTask}
                  onFinished={refetch}
                />
              )
            : undefined
        }
      />
      <PageBody>
        <SessionContext.Provider
          value={{
            sessionMembers: {
              owner,
              candidate
            },
            widgetWidth: widgetWidth || '50%',
            videoPlayerRef
          }}
        >
          <PageLeft onScroll={onSummaryScroll} ref={summaryRef}>
            <div
              className={cn('flex flex-col flex-1 rounded', {
                'items-center': hidePageRight
              })}
            >
              <ErrorBoundary>
                {videoType === 'loom' &&
                  (participation?.loom_recording_id ? (
                    <Preview flexible id={participation?.loom_recording_id} />
                  ) : (
                    <Text>No screen recording</Text>
                  ))}
                {videoType === 'livestream' && liveStream && (
                  <LiveStreamWrapper
                    liveStream={liveStream}
                    setLiveStreamLocked={setLiveStreamLocked}
                    setLiveStreamStatus={setLiveStreamStatus}
                  />
                )}
                {videoType === 'recording' && (
                  <RecordingWidget
                    key={participation?.id}
                    transcriptStatus={transcriptStatus}
                    canUpload={canUpdate}
                    participation={participation}
                    onCreateRecording={handleRecordingCreate}
                    initialRecording={recording || null}
                    track={track}
                    owner={owner}
                    isOwner={owner?.id === currentUserId}
                    renderSummary={renderSummary}
                    restrictWidth={hidePageRight}
                    update={update}
                    initialTime={ts ? ts / 1000 : undefined}
                    study={study}
                  />
                )}
              </ErrorBoundary>
            </div>
            {hidePageRight && !isMobile && !isTablet && (
              <button
                className='focus:ring-indigo top-4 right-12 hover:text-indigo-600 absolute p-2 bg-white'
                onClick={() => setHidePageRight(!hidePageRight)}
              >
                <SplitPageSVG />
              </button>
            )}
          </PageLeft>
          {(!hidePageRight || isMobile || isTablet) && (
            <Resizable
              size={widgetSize}
              onResizeStop={onWidgetResize}
              onResize={onResize}
              maxWidth={maxWidgetWidth}
              minWidth={minWidgetWidth}
              enable={resizeStatus}
            >
              <PageRight>
                {liveStreamLocked && (
                  <Alert
                    type='warning'
                    className='m-4'
                    heading='Editing notes currently restricted.'
                    dismissKey={`repo-session-notes-editing-${sessionUuid}`}
                  >
                    This interview is currently in progress. To prevent overwrites, editing of the interview notes is
                    currently restricted to study moderators.
                    <br /> Please ensure that only one moderator is editing the document at a time.
                  </Alert>
                )}
                <div className='desktop:h-full flex flex-col overflow-hidden'>
                  <div className='flex items-center justify-between px-4 border-b border-gray-200'>
                    <Tabs<Tab>
                      current={activeTab}
                      labels={{
                        transcript: 'Transcript',
                        highlights: 'Highlights',
                        notes: 'Notes',
                        summary: 'Summary',
                        responses: 'Responses'
                      }}
                      onSelect={handleTabSelection}
                      className='flex items-center'
                      tabs={getTabs()}
                    />
                    {!isMobile && !isTablet && (
                      <button
                        className='focus:ring-indigo hover:text-indigo-600'
                        onClick={() => setHidePageRight(!hidePageRight)}
                      >
                        <SplitPageSVG />
                      </button>
                    )}
                  </div>
                  {renderTabContent(activeTab)}
                </div>
              </PageRight>
            </Resizable>
          )}
        </SessionContext.Provider>
      </PageBody>
    </PageLayout>
  );
};
