import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@components/common';
import { Preview } from '@components/Loom';
import { SDKResult } from '@loomhq/loom-sdk';
import { createPopper } from '@popperjs/core';

import { QuestionCard, QuestionInner, QuestionNumber, QuestionTitle } from './QuestionCard';

const LOOM_CONTAINER_SELECTOR = 'loom-sdk-record-overlay-shadow-root-id';
const LOOM_CONTROLS_SELECTOR = '[aria-label="Recorder controls"]';

export const InsertLoom: React.FC<{
  number: number;
  onClick?: () => void;
  isActive: boolean;
  status: string;
  recordingId: string | null;
  button: ReturnType<SDKResult['configureButton']>;
}> = ({ status, number, isActive, button, onClick, recordingId }) => {
  // TODO: rather than watching params, this should just use a provider.
  const { t } = useTranslation('InsertLoom');
  const bubblePlacementRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (!bubblePlacementRef.current || status !== 'started') return;

    const loomControls = document
      .getElementById(LOOM_CONTAINER_SELECTOR)
      ?.shadowRoot?.querySelector(LOOM_CONTROLS_SELECTOR);

    createPopper(bubblePlacementRef.current, loomControls as HTMLElement, { placement: 'right-start' });
  }, [status]);

  let body: React.ReactNode;
  switch (status) {
    case 'done':
      body = (
        <>
          <Text mb='2'>{t('done')}</Text>
        </>
      );
      break;
    case 'started':
      body = (
        <>
          <Text>{t('started')}</Text>
        </>
      );
      break;
    case 'canceled':
      // TODO: jump back to first question
      body = (
        <>
          <Text>{t('canceled')}</Text>
        </>
      );
      break;
    case undefined:
    case null:
      body = (
        <>
          <Text>{t('null')}</Text>
        </>
      );
      break;
    default:
      body = <></>;
  }
  return (
    <QuestionCard isActive={isActive} onClick={onClick}>
      <QuestionNumber number={number} />
      <QuestionInner>
        <QuestionTitle>{t('add_recording')}</QuestionTitle>
        <div className='w-full mt-5'>
          {body}
          {recordingId && <Preview id={recordingId} />}
        </div>
        <div className='w-px h-12 mt-4' id='loom-sdk-controls-drop-area' ref={bubblePlacementRef}></div>
      </QuestionInner>
    </QuestionCard>
  );
};
