import React, { VFC } from 'react';
import { ProgressBar, Text } from '@components/common';
import { Droppable } from 'react-beautiful-dnd';
import { pct } from '@components/utils';
import { CardItem } from '../shared';
import { CardsListZDS } from './components';
import { CardSortResult } from '@components/Unmoderated/types';

interface Props {
  cards: string[];
  categories: CardSortResult[];
  onCategorySelect: (params: {
    card: string;
    newCategory: string;
    initialCategory?: string;
    categories: CardSortResult[];
  }) => void;
  onCategoryCreate: (categories: CardSortResult[]) => CardSortResult[];
  totalCards: number;
}

export const CardList: VFC<Props> = ({ onCategoryCreate, cards, totalCards, categories, onCategorySelect }) => {
  return (
    <Droppable droppableId='cards-list'>
      {(dropProvided) => (
        <section
          id='card_sort-cards-list'
          ref={dropProvided.innerRef}
          {...dropProvided.droppableProps}
          className='flex-shrink-0 h-screen w-96 bg-white px-6 py-4 border-r border-gray-200 overflow-auto'
        >
          <Text h='600' bold>
            Cards
          </Text>
          <Text h='400' className='mt-1'>
            Drag and drop or Move each card to the category they belong.
          </Text>
          <div className='flex items-center space-x-2 mt-3'>
            <Text h='400'>{cards.length} left</Text>
            <div className='flex-1'>
              <ProgressBar
                size='xs'
                bars={[
                  {
                    progress: pct(totalCards - cards.length || 0, totalCards || 0),
                    color: 'gray-700',
                    tooltip: `${totalCards - cards.length} cards sorted`
                  }
                ]}
              />
            </div>
          </div>
          <div className='flex flex-col mt-3'>
            {!cards.length ? (
              <CardsListZDS />
            ) : (
              cards.map((card, i) => (
                <CardItem
                  key={card}
                  card={card}
                  categories={categories}
                  onCategorySelect={onCategorySelect}
                  onCategoryCreate={onCategoryCreate}
                  index={i}
                />
              ))
            )}
          </div>
        </section>
      )}
    </Droppable>
  );
};
