import React, { forwardRef, HTMLAttributes, useRef } from 'react';
import cn from 'classnames';
import mergeRefs from 'react-merge-refs';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { DraggableAttributes } from '@dnd-kit/core';
import { DragSVG, MaximizeSVG, MinimizeSVG } from '@components/svgs';
import { Text } from '@components/common';

import { useDraggableCardContext } from '../hooks/useDraggableCardContext';

type DraggableProps = {
  attributes?: DraggableAttributes;
  listeners?: SyntheticListenerMap;
};

type Props = PropsWithChildrenFn<{ draggableProps: DraggableProps } & HTMLAttributes<HTMLDivElement>, boolean>;

export const Header = forwardRef<HTMLDivElement, Props>(({ className, children, draggableProps, ...rest }, ref) => {
  const innerRef = useRef<HTMLDivElement>(null);

  const {
    state: { isMinimized },
    setIsMinimized
  } = useDraggableCardContext();

  return (
    <header
      data-testid='draggable-card-header'
      ref={mergeRefs([innerRef, ref])}
      className={cn(
        'flex flex-row items-center justify-between bg-white',
        {
          'p-6': !isMinimized,
          'px-4 py-3': isMinimized
        },
        className
      )}
      {...rest}
    >
      <div className={isMinimized ? 'truncate' : undefined}>
        {typeof children === 'function' ? children(isMinimized) : children}
      </div>

      <div className='flex items-center space-x-2'>
        {isMinimized ? (
          <button
            className='flex-nowrap text-custom-brand-secondary flex items-center space-x-1'
            aria-label='Expand card'
            type='button'
            onClick={() => setIsMinimized(false)}
          >
            <MaximizeSVG />
            <Text h='200' className='text-custom-brand-secondary'>
              Maximize
            </Text>
          </button>
        ) : (
          <button
            className='flex-nowrap text-custom-brand-secondary flex items-center space-x-1'
            aria-label='Minimize card'
            type='button'
            onClick={() => setIsMinimized(true)}
          >
            <MinimizeSVG />
            <Text h='200' className='text-custom-brand-secondary'>
              Minimize
            </Text>
          </button>
        )}

        <button
          aria-label='Drag card'
          type='button'
          className='flex-nowrap text-custom-brand-secondary flex items-center space-x-1 cursor-move'
          style={{ touchAction: 'none' }}
          {...draggableProps.attributes}
          {...draggableProps.listeners}
        >
          <DragSVG />
          <Text h='200' className='text-custom-brand-secondary'>
            Move
          </Text>
        </button>
      </div>
    </header>
  );
});
