import { useMemo } from 'react';

import { api as reduxApi } from '@api/reduxApi';
import { useCachedPagedQuery } from '@hooks/useCachedPagedQuery';
import { useScreenerResults } from '@hooks/useScreenerResults';

import { useAccount } from './useAccount';
import { useFeature } from '@hooks/useFeature';
import { KeyState } from 'components/stores/cache';

export type UseParticipations = {
  initialFetchDone: boolean;
  isFetching: boolean;
  isError: boolean;
  participations?: Participation[];
  totalRecords?: number;
  refetch: () => void;
  updateParticipation: (participation: Participation) => void;
  counts?: ParticipationCounts;
  totalPages?: number;
  status: KeyState<Participation>['status'];
};

// TODO: Get rid of this and just let the backend decide.
const FIELDS = [
  'id',
  'project_id',
  'customer_id',
  'status',
  'external_status',
  'extra',
  'name',
  'token',
  'first_name',
  'last_name',
  'email',
  'phone_number',
  'opted_in',
  'created_at',
  'consented',
  'updated_at',
  'booked_at',
  'completed_at',
  'interview_at',
  'moderator_id',
  'interview_path',
  'public_path',
  'loom_recording_id',
  'requested_at',
  'applied_at',
  'shortlisted_at',
  'rejected_at',
  'no_show_at',
  'opted_out_at',
  'consented_at',
  'started_at',
  'canceled_at',
  'deleted_at',
  'invited_at',
  'thanked_at',
  'reschedule_requested_at',
  'customer',
  'can_request_reschedule',
  'cancelation_reason',
  'time_proposal',
  'rsvp',
  'waitlisted_at',
  'recruiting_source',
  'ideal_pre_screener_response',
  'pre_screener_response_match_score',
  'rating',
  'incentive_in_whole_currency',
  'eligible'
];

interface Props {
  studyId: number;
  preScreenerId?: number;
  surveyScreenerId?: number;
  skip?: boolean;
  pollingEnabled?: boolean;
}

export const useParticipations = ({
  studyId,
  pollingEnabled = true,
  preScreenerId,
  surveyScreenerId,
  skip
}: Props): UseParticipations => {
  const pollingFeatureEnabled = useFeature('participations_polling');

  const { data, totalRecords, isFetching, status, refetch, initialFetchDone, updateRecord } =
    useCachedPagedQuery<Participation>({
      key: `participations-study-${studyId}-cached`,
      query: reduxApi.useGetParticipationsPagedQuery,
      params: {
        study_id: studyId,
        fields: FIELDS
      },
      options:
        pollingEnabled && pollingFeatureEnabled
          ? {
              pollingInterval: 30000,
              refetchOnReconnect: true,
              skipPollingIfUnfocused: true,
              skip
            }
          : { refetchOnFocus: true }
    });

  const { results: preScreenerResults, isLoading: loadingPreScreenerResults } = useScreenerResults(
    studyId,
    preScreenerId
  );
  const { results: surveyScreenerResults, isLoading: loadingSurveyScreenerResults } = useScreenerResults(
    studyId,
    surveyScreenerId
  );

  const {
    account: { team }
  } = useAccount();

  const resultsMap = useMemo(() => {
    const map = new Map();
    if (loadingPreScreenerResults || loadingSurveyScreenerResults) {
      return map;
    }

    const screenerResults: ScreenerResponseResults[] = [];
    screenerResults.push(preScreenerResults);
    screenerResults.push(surveyScreenerResults);

    screenerResults.forEach((results) => {
      if (results.individual) {
        results.individual.forEach((result) => {
          const mapped = result.answers_json.reduce((m, { field_id, value, ideal }) => {
            m[field_id] = { value, ideal };
            return m;
          }, {});
          map.set(result.participation.id, { ...map.get(result.participation.id), ...mapped });
        });
      }
    });

    return map;
  }, [loadingPreScreenerResults, loadingSurveyScreenerResults]);

  const participations: Participation[] = useMemo(() => {
    return (data || [])
      .filter(({ deleted_at }) => !deleted_at)
      .map((participation) => ({
        ...participation,
        screenerResults: resultsMap.get(participation.id),
        moderator: team.find(({ name, id }) => (participation.moderator_id === id ? { name, id } : null))
      }));
  }, [data, resultsMap, team]);

  return {
    totalRecords,
    refetch,
    isError: status === 'error',
    initialFetchDone: initialFetchDone,
    isFetching: isFetching,
    participations,
    updateParticipation: updateRecord,
    status
  };
};
