import * as React from 'react';

import * as sections from './sections';

type Props = {
  editMode: boolean;
  bookingDetail: CalendarBookingDetail;
};

export const BookingDetailsForm: React.VFC<Props> = ({ editMode, bookingDetail }) => (
  <div>
    <sections.SessionDetails editMode={editMode} bookingDetail={bookingDetail} />
  </div>
);
