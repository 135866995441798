import qs from 'qs';

import * as builders from '@api/builders';
import api from '@api/client';
import * as Sentry from '@sentry/react';

import { buildAccount } from './testHelpers';

export interface ApiError {
  status: number;
  data?: Record<string, any>;
}

type ApiEndpoint<R, A = unknown, P = unknown> = (arg?: A, props?: P) => Promise<R>;
type ApiEndpointWithError<R, A = unknown, P = unknown> = (arg?: A, props?: P) => Promise<[ApiError, R]>;

// until builders.js is typed
type Builder<R> = (json: any) => R;
const buildCustomer = builders.buildCustomer as Builder<Candidate>;
const buildParticipation = builders.buildParticipation as Builder<Participation>;
const buildWhoami = builders.buildWhoami as Builder<Whoami>;
const buildRecord = builders.buildRecord as Builder<Record<string, any>>;
const buildScreener = builders.buildScreener as Builder<Screener>;
const buildScreenerField = builders.buildScreenerField as Builder<ScreenerField>;
const buildHighlight = builders.buildHighlight as Builder<Highlight>;
const buildInsight = builders.buildInsight as Builder<Insight>;
const buildCustomerImport = builders.buildCustomerImport as Builder<CustomerImport>;
const buildRecording = builders.buildRecording as Builder<Recording>;
const buildTranscript = builders.buildTranscript as Builder<Transcript>;
const buildConsentForm = builders.buildConsentForm as Builder<ConsentForm>;
const buildIncentive = builders.buildIncentive as Builder<Incentive>;

const action = <R, A = unknown, P = unknown>(clientMethod: (path: string, params: A) => Promise<Response>) => {
  return function (url: string, params: A, builder: Builder<R>): Promise<R> {
    return new Promise((resolve, reject) => {
      clientMethod(url, params).then((resp) => {
        let json = {} as any;
        const status = resp.status.toString();
        const isOk = status[0] === '2' || status === '304';
        if (status === '401') {
          resolve(null as any);
          window.localStorage.removeItem('ajs_user_id');
          window.localStorage.removeItem('ajs_user_traits');
          window.location.assign('/users/sign_in');
          return;
        }
        if (status === '500') {
          resolve(null as any);
          return;
        }
        if (!builder) {
          return isOk ? resolve(null as any) : resolve(null as any); //??
        }
        try {
          json = resp
            .json()
            .then((json) => {
              // This is no good as it actually was return a resp when we often check if(resp)
              // isOk ? resolve(builder(json.data)) : resolve(json)
              isOk ? resolve(builder(json)) : resolve(null as any);
            })
            .catch((e) => resolve(null as any));
        } catch (e) {
          Sentry.captureException(e);
          isOk ? resolve(builder({})) : resolve(null as any);
        }
      });
    });
  };
};

const actionWithError = <R, A = unknown, P = unknown>(clientMethod: (path: string, params: A) => Promise<Response>) => {
  return function (url: string, params: A, builder: Builder<R>): Promise<[ApiError, R]> {
    return new Promise((resolve, reject) => {
      clientMethod(url, params).then((resp) => {
        let json = {} as any;
        const status = resp.status.toString();
        const isOk = status[0] === '2' || status === '304';
        if (status === '500') {
          resolve([{ status: 500 }, null as any]);
          return;
        }
        if (!builder) {
          return isOk
            ? resolve([null as any, null as any])
            : resolve([{ status: parseInt(status), data: json }, null as any]);
        }
        // IS there any reason we have two catch blocks here?
        try {
          json = resp
            .json()
            .then((json) => {
              isOk
                ? resolve([null as any, builder(json)])
                : resolve([{ status: parseInt(status), data: json }, null as any]);
            })
            .catch((e) => {
              // This is fine for now but may causes issues in future by not calling building
              isOk
                ? resolve([null as any, null as any])
                : resolve([{ status: parseInt(status), data: {} }, null as any]);
            });
        } catch (e) {
          if (process.env.NODE_ENV === 'development') {
            throw e;
          } else {
            Sentry.captureException(e);
            isOk ? resolve([null as any, builder({})]) : resolve([{ status: parseInt(status) }, null as any]);
          }
        }
      });
    });
  };
};

const map = (fn: (data: any) => any) => (data: any[]) => data.map(fn);

const get = <R, A = unknown>(url: string, builder: Builder<R>) =>
  action<R, A>(api.get)(url, null as any, (j) => builder(j.data));
const getWithParams = <R, A = unknown, P = unknown>(url: string, args: A, builder: Builder<R>) =>
  action<R, string, P>(api.get)(url, qs.stringify(args, { encode: false, arrayFormat: 'brackets' }), (j) =>
    builder(j.data)
  );
const put = <R, A = unknown, P = unknown>(url: string, args: A, builder: Builder<R>) =>
  action<R, A, P>(api.put)(url, args, (j) => builder && builder(j.data));
const post = <R, A = unknown, P = unknown>(url: string, args: A, builder: Builder<R>) =>
  action<R, A, P>(api.post)(url, args, (j) => builder && builder(j.data));
const delete_ = <R, A = unknown, P = unknown>(url: string, args: A, builder: Builder<R>) =>
  action<R, A, P>(api.delete)(url, args, (j) => builder && builder(j.data));

const getWithError = <R, A = unknown>(url: string, builder: Builder<R>) =>
  actionWithError<R, A>(api.get)(url, null as any, (j) => builder(j.data));
const postWithError = <R, A = unknown, P = unknown>(url: string, args: A, builder: Builder<R>) =>
  actionWithError<R, A, P>(api.post)(url, args, (j) => builder && builder(j.data));
const putWithError = <R, A = unknown, P = unknown>(url: string, args: A, builder?: Builder<R>) =>
  actionWithError<R, A, P>(api.put)(url, args, builder && (((j) => builder(j.data)) as any));
const deleteWithError = <R>(url: string, builder?: Builder<R>) =>
  actionWithError<R, null, null>(api.delete)(url, null, null as any);

const returnData = <R>(data: R): R => data;

export const getWhoami: ApiEndpoint<Whoami> = () => get(`/whoami`, buildWhoami);

//-----------------
// WALLET
//-----------------
export const createWalletInvoice: ApiEndpointWithError<any, { amount_in_cents: number; memo: string }> = (invoice) =>
  postWithError('/wallet/invoices', { invoice }, returnData);

export const createWalletDeposit: ApiEndpointWithError<any, { amount_in_cents: number; processor_id: string }> = (
  deposit
) => postWithError('/wallet/deposits', { deposit }, returnData);

export type StudyAllocationProps = Partial<Pick<Study, 'incentive' | 'participant_limit' | 'incentive_method'>>;

type StudyIncentiveFields =
  | 'incentive'
  | 'incentive_method'
  | 'incentive_title'
  | 'incentive_coupons'
  | 'participant_limit';

export const updateStudyIncentive: ApiEndpointWithError<Study, number, Partial<Pick<Study, StudyIncentiveFields>>> = (
  id,
  study
) => putWithError(`/studies/${id}/incentive`, { study });

export interface ConfirmStudyAllocationProps {
  study_id: number;
  usd_amount_in_cents: number;
  method: AllocationMethod;
  processor_id?: string;
}

//-----------------
// Candidates
//-----------------
// export const searchCandidates: ApiEndpoint<Candidate[], {q?:string}> = (rp) => getWithParams(`/candidates`, (q ? {q} : null), map(buildCustomer))
export type WithPagy<R> = {
  data: R;
  meta: {
    page: number; // current page
    pages: number; // how many pages
    size: number; // how many on the page
    count: number; // total records
  };
};
export type WithPagyCountless<R> = {
  data: R;
  meta: {
    page: number; // current page
    pages: number; // how many pages
    size: number; // how many on the page
  };
};
export type PagyRequest<T> = T & {
  q?: string;
  page?: number;
  sort?: string;
  sortDesc?: boolean;
  filters?: string[];
  op?: FilterOp;
};
export type Paged<R> = WithPagy<R[]>;
export type PagedCountless<R> = WithPagyCountless<R[]>;

export const getPagedCandidates: ApiEndpoint<
  Paged<Candidate>,
  { page?: number; items?: number; size: number } | any
> = ({ page, items }) =>
  action<Paged<Candidate>>(api.get)('/candidates', qs.stringify({ page, items }), (resp) => ({
    data: resp.data.map(buildCustomer),
    meta: resp.meta
  }));

export const getCandidates: ApiEndpoint<Candidate[], { study_id?: number; q?: string; size: number }> = (props) =>
  getWithParams(`/candidates`, props, map(buildCustomer));
export const getCandidate: ApiEndpoint<Candidate, number> = (id) => get(`/candidates/${id}`, buildCustomer);
export const createCandidate: ApiEndpoint<Candidate, Partial<Candidate>> = (customer) =>
  post(`/candidates`, { customer }, buildCustomer);
export const updateCandidate: ApiEndpoint<Candidate, number, Partial<Candidate>> = (id, customer) =>
  put(`/candidates/${id}`, { customer }, buildCustomer);
export const bulkCandidatesUpdate: ApiEndpoint<BackgroundTask> = (ids, customer) =>
  put(`/candidates/bulk`, { ids, customer }, returnData);

export const getCandidateParticipations: ApiEndpoint<Participation[], number> = (id) =>
  get(`/candidates/${id}/participations`, map(buildParticipation));

// TODO: update the type here...
export const getCandidateHighlights: ApiEndpoint<any[], number> = (id) =>
  get(`/candidates/${id}/highlights`, map(buildRecord));

//-----------------
// Participations
//-----------------
export const getParticipations: ApiEndpoint<Participation[], number, { candidate_id?: number }> = (
  project_id,
  props = {}
) => getWithParams(`/participations`, { project_id, candidate_id: props.candidate_id }, map(buildParticipation));
export const getParticipation: ApiEndpoint<Participation, number> = (id) =>
  get(`/participations/${id}`, buildParticipation);

export const getParticipationSessionInfo: ApiEndpoint<{ uuid: string; recording_id: number }, number | string> = (id) =>
  get(`/participations/${id}/session_info`, (data) => data);
export const getRecordingSessionUuid: ApiEndpoint<string, number | string> = (id) =>
  get(`/recordings/${id}/session_uuid`, (data) => data.uuid);
export const updateParticipationZoomMeeting: ApiEndpoint<Participation, number, { meeting_id: number; uuid: string }> =
  (id, zoom_meeting) => put(`/participations/${id}`, { zoom_meeting }, buildParticipation);
interface createParticipationProps {
  projectId: string;
  customerId: string;
  transition: 'shortlist' | 'complete';
  source: string;
  email?: string; // Not sure why the f this is here.
  repo_only?: boolean;
  force?: boolean;
}
export const createParticipation: ApiEndpoint<Participation, createParticipationProps | any> = (props) => {
  const { projectId: project_id, customerId: customer_id, transition, source, email, repo_only, force } = props;
  const params = { project_id, customer_id, transition, source, email, repo_only, force };
  return post('/participations', params, buildParticipation);
};

//-----------------
// Bulk Participations
//-----------------
export const changeParticipationStatus: ApiEndpoint<Participation, number, string> = (id, transition) =>
  post(`/participations/${id}/${transition}`, {}, buildParticipation);
export const bulkShortlist: ApiEndpoint<
  { background_task: BackgroundTask; count: number },
  number,
  { customer_ids: number[] } | any
> = (id, { customer_ids }) => post(`/studies/${id}/bulk_shortlist`, { customer_ids }, returnData);
export const bulkParticipationsAction: ApiEndpoint<
  { data?: Participation[]; meta: { count: number } },
  number,
  { action: ParticipationAction; ids?: number[]; query?: ServerFilterQuery; fields?: [] } | any
> = (id, { action, ids, fields, query }) =>
  post(`/studies/${id}/participations/bulk/${action}`, { ids, fields, query }, ({ data, meta }) => ({
    data: data.map(buildParticipation),
    meta
  }));

interface BatchParams {
  batch_on: boolean;
  batch_size?: number;
  batch_wait_hours?: number;
  batch_auto_restart?: boolean;
  batch_target?: number;
}

interface InviteParams {
  customer_ids: number[];
  invite: BatchParams;
  sender: EmailSender;
  send_now?: boolean;
  short_url?: boolean;
}
export type SentInvitesResponse = {
  study: Study;
  results: {
    added: number;
    sent: number;
    queued: number;
  };
};

export type SentScreenerResponse = {
  results: {
    sent: number;
  };
};
//-----------------
// Incentives
//-----------------

export const getIncentives: ApiEndpoint<Incentive[], null, null> = () => get('/incentives', map(buildIncentive));

//-----------------
// Highlights
//-----------------
type HighlightMemberArgs = { documentId: number; highlightId: number };
export const getHighlight: ApiEndpoint<Highlight, HighlightMemberArgs | any> = ({ documentId, highlightId }) =>
  get(`/documents/${documentId}/highlights/${highlightId}`, buildHighlight);
export const getHighlights: ApiEndpoint<Highlight[], number> = (id) =>
  get(`/documents/${id}/highlights`, map(buildHighlight));
export const createHighlight: ApiEndpoint<Highlight, number, any> = (id, body) =>
  post(`/documents/${id}/highlights`, { highlight: body }, buildHighlight);
export const updateHighlightTags: ApiEndpoint<Highlight, HighlightMemberArgs | any, any> = (
  { documentId, highlightId },
  tag_ids
) => put(`/documents/${documentId}/highlights/${highlightId}`, { highlight: { tag_ids } }, buildHighlight);
export const bulkUpdateHighlightsText: ApiEndpoint<null, number, any> = (documentId, objects) =>
  put(`/documents/${documentId}/bulk/highlights`, { highlights: objects }, returnData);

//-----------------
// Insights
//-----------------
export const getInsight: ApiEndpointWithError<Insight, string> = (slug) =>
  getWithError(`/stories/${slug}`, buildInsight);
export const createInsight: ApiEndpoint<Insight, Partial<Insight>> = (body?) =>
  post(`/stories`, { story: body }, buildInsight);
export const updateInsight: ApiEndpoint<Insight, string, any> = (slug, insight) =>
  put(`/stories/${slug}`, { story: insight }, buildInsight);

export const getStudyInsight: ApiEndpointWithError<Insight, number> = (id) =>
  getWithError(`/studies/${id}/story`, buildInsight);

//-----------------
// Customer imports
//-----------------
export const getCustomerImport: ApiEndpoint<CustomerImport, number> = (id) =>
  get(`/customer_imports/${id}`, buildCustomerImport);
export const createCustomerImport: ApiEndpoint<CustomerImport, null, FormData> = (params, body) =>
  post(`/customer_imports`, body, buildCustomerImport);
export const updateCustomerImport: ApiEndpointWithError<CustomerImport, number, any> = (id, customer_import) =>
  putWithError(`/customer_imports/${id}`, { customer_import }, buildCustomerImport);
export const runCustomerImport: ApiEndpoint<{ message: string }, number> = (id, customer_import) =>
  post(`/customer_imports/${id}/run`, { customer_import }, returnData);
export const deleteCustomerImport: ApiEndpoint<null, number> = (id) =>
  delete_(`/customer_imports/${id}`, null, null as any);

//-----------------
// Screeners
//-----------------
export const getScreeners: ApiEndpoint<Screener[]> = () => get('/screeners', map(buildScreener));

export const getScreenerResults: ApiEndpoint<any, number> = (id) => get(`/studies/${id}/screener/results`, returnData);

//-----------------
// Documents
//-----------------
export const getDocument: ApiEndpointWithError<ApiDocument, number> = (id) =>
  getWithError(`/documents/${id}`, builders.buildDocument);

export const updateDocument: ApiEndpointWithError<ApiDocument, number | null, ApiDocument['doc']> = (id, doc) =>
  putWithError(`/documents/${id}`, { document: { doc } }, builders.buildDocument);
export const updateDocumentNode = (id, node_index, node) =>
  putWithError(`/documents/${id}/update_node`, { node, node_index }, builders.buildDocument);
export const createDocument: ApiEndpointWithError<ApiDocument, number> = () =>
  postWithError(`/documents`, {}, builders.buildDocument);

//-----------------
// Emails
//-----------------
export const getStudyMessageTemplate: ApiEndpoint<StudyMessage, number, StudyMessageEvent> = (id, event) =>
  get(`/studies/${id}/message_templates/${event}`, returnData);

export const sendStudyMessage: ApiEndpoint<
  StudyMessage & { participation_ids: number[] },
  { id: number; studyId: number } | any,
  { participation_ids?: number[]; query?: ServerFilterQuery; sender: Partial<EmailSender> } | any
> = ({ id, studyId }, { participation_ids, sender, query }) =>
  post(`/studies/${studyId}/messages/${id}/send`, { participation_ids, sender, query }, returnData);

export const previewStudyMessage: ApiEndpoint<
  StudyMessage,
  { id: number; studyId: number } | any,
  { candidate_id?: number; sender?: EmailSender }
> = ({ id, studyId }, props) => post(`/studies/${studyId}/messages/${id}/preview`, props, returnData);

//-----------------
// Activities
//-----------------
export const getCandidateActivities: ApiEndpoint<PublicActivity[], number> = (id) =>
  get(`/activities?candidate_id=${id}`, map(buildRecord));
export const getStudyActivities: ApiEndpoint<PublicActivity[], number> = (id) =>
  get(`/activities?study_id=${id}`, map(buildRecord));

//-----------------
// Consent forms
//-----------------
export const getConsentForms: ApiEndpoint<ConsentForm[], void> = () => get(`/consent_forms`, map(buildConsentForm));
export const createConsentForm: ApiEndpointWithError<ConsentForm, { file?: File; teamId?: number | null }> = ({
  file,
  teamId
} = {}) => {
  const body = new FormData();
  body.append('consent_form[file]', file as any);
  if (teamId) body.append('consent_form[team_id]', teamId as any);
  return postWithError('/consent_forms', body, buildConsentForm);
};
export const updateConsentForm: ApiEndpointWithError<ConsentForm, number, Partial<ConsentForm>> = (id, consent_form) =>
  putWithError(`/consent_forms/${id}`, { consent_form }, buildConsentForm);
export const deleteConsentForms: ApiEndpointWithError<null, number> = (id) =>
  deleteWithError(`/consent_forms/${id}`, null as any);

//-----------------
// Nylas
//-----------------
export type GetNylasEventParams = {
  id: number;
  start_date: string;
  end_date: string;
  timezone: string;
  calendar_id: string;
};
export const getUserNylasEvents: ApiEndpoint<
  {
    events: NylasEvent[];
    user_id?: number;
    calendar_id?: string;
  },
  GetNylasEventParams
> = (params) =>
  getWithParams(`/users/${params?.id}/nylas/events`, params, (data) => ({
    events: data,
    user_id: params?.id,
    calendar_id: params?.calendar_id
  }));

export const updateNylasEvent: ApiEndpointWithError<null, { userId: number; eventId: string }, Record<string, any>> = (
  params,
  nylas_event
) => putWithError(`/users/${params?.userId}/nylas/events/${params?.eventId}`, { nylas_event }, null as any);

//-----------------
// Custom Attrs
//-----------------
export const getCustomAttrs: ApiEndpoint<Record<string, any>> = () => get(`/custom_attrs`, map(buildRecord));
export const createCustomAttr: ApiEndpoint<Attr_, Partial<Attr_>> = (params) =>
  post('/custom_attrs', params, (d) => buildRecord(d) as Attr_);
// Doesn't actually get used anywhere:
// export const updateCustomAttr: ApiEndpoint<Attr_, number, Partial<Attr_>> = (id, params) => put(`/custom_attrs/${id}`, params,  (d => buildRecord(d) as Attr_))

//-----------------
// Recordings
//-----------------
export const getRecording: ApiEndpointWithError<Recording, number> = (id) =>
  getWithError(`/recordings/${id}`, buildRecording);
export const updateRecording: ApiEndpoint<Recording, number, Partial<Recording>> = (id, recording) =>
  put(`/recordings/${id}`, { recording }, buildRecording);

export const createQuestion: ApiEndpointWithError<any, { paragraph_id: number }> = (params) =>
  postWithError(`/gpt3/questions/`, params, buildRecord);
export const updateQuestion: ApiEndpointWithError<any, number, Record<string, any>> = (id, question) =>
  putWithError(`/gpt3/questions/${id}`, { question }, buildRecord);

export const generateTranscript: ApiEndpointWithError<Transcript, number> = (id) =>
  postWithError(`/transcripts/${id}/generate`, {}, buildTranscript);
export const getTranscript: ApiEndpointWithError<Transcript, number> = (id) =>
  getWithError(`/transcripts/${id}`, buildTranscript);
export const resetTranscript: ApiEndpointWithError<Transcript, number> = (id) =>
  postWithError(`/transcripts/${id}/reset`, {}, buildTranscript);

export const getClips: ApiEndpointWithError<Clip[], number> = (id) =>
  getWithError(`/recordings/${id}/clips`, (data) => data.map(builders.buildClip));

//-----------------
// Tags
//-----------------

export const getTags: ApiEndpoint<Tag[]> = () =>
  get(
    `/tags`,
    map(({ attributes }) => attributes as Tag)
  );
export const createTag: ApiEndpoint<Tag, { tag: Partial<Tag> }> = (params) =>
  post(`/tags`, params, ({ attributes }) => attributes);
export const updateTag: ApiEndpoint<Tag[], { id: string; tag: Partial<Tag> }> = (params) =>
  put(
    `/tags/${params?.id}`,
    params?.tag,
    map(({ attributes }) => attributes as Tag)
  );
export const deleteTag: ApiEndpoint<Tag[], { id: string }> = (params) =>
  delete_(
    `/tags/${params?.id}`,
    null,
    map(({ attributes }) => attributes as Tag)
  );

//-----------------
// Plans
//-----------------
export const getPlans: ApiEndpoint<Plan[]> = () => get('/plans', (resp) => resp.map((d) => d.attributes));

//---------------
// Invitations
//-----------------
export const getInvitations: ApiEndpointWithError<any[]> = () => getWithError(`/account_invitations`, (data) => data);
export const getInvitation: ApiEndpointWithError<any, string> = (token) =>
  getWithError(`/account_invitations/${token}`, (data) => data);
export const createInvitation: ApiEndpointWithError<any, { invitation: any }> = (invitation) =>
  postWithError(`/account_invitations`, { invitation }, returnData);
export const updateInvitation: ApiEndpointWithError<any, { role: AccountRole }> = (token, invitation) =>
  putWithError(`/account_invitations/${token}`, { invitation }, returnData);
export const deleteInvitation: ApiEndpointWithError<number, string> = (token) =>
  deleteWithError(`/account_invitations/${token}`);
export const updateAccountUser: ApiEndpointWithError<any, { role: AccountRole }> = (id, account_user) =>
  putWithError(`/account_users/${id}`, { account_user }, returnData);

export const switchAccount: ApiEndpointWithError<GQAccount, number> = (id) =>
  postWithError(`/accounts/${id}/switch`, { id }, buildAccount);

export const updateSubscriptionSeats: ApiEndpointWithError<Plan, { seats: number }> = (params) =>
  postWithError(`/subscription/seats`, params, (data) => data as Plan);

export const createRoleRequest: ApiEndpointWithError<any, { role: AccountRole; message: string }> = (role_request) =>
  postWithError(`/role_requests`, { role_request }, returnData);

export const dismissRoleRequest: ApiEndpointWithError<any, string> = (id) =>
  deleteWithError(`/role_requests/${id}`, returnData);

export const destroyUserSession: ApiEndpointWithError<null> = () => deleteWithError(`/users/session`);
