import Tippy from '@tippyjs/react';
import cn from 'classnames';
import React, { FC, HTMLAttributes, MouseEvent } from 'react';

import { useMenuContext } from '../hooks/useMenuContext';

export interface Props extends HTMLAttributes<HTMLLIElement> {
  value?: string;
  isDisabled?: boolean;
  tooltip?: string;
}

export const Item: FC<Props> = ({
  tooltip,
  value,
  isDisabled = false,
  children,
  onClick,
  onKeyDown,
  className,
  ...rest
}) => {
  const { setSelectedItem, closeMenu } = useMenuContext();

  const handleOnClick = (event: MouseEvent<HTMLLIElement>) => {
    if (!isDisabled && value) {
      setSelectedItem(value);
      closeMenu();
      onClick?.(event);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLLIElement>) => {
    if (!isDisabled && value) {
      if (event.key === 'Enter') {
        setSelectedItem(value);
        closeMenu();
      }

      onKeyDown?.(event);
    }
  };

  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-noninteractive-element-to-interactive-role*/
  return (
    <Tippy content={tooltip} arrow={false} disabled={!tooltip}>
      <li
        role='button'
        className={cn('list-none cursor-pointer', className)}
        onClick={handleOnClick}
        onKeyDown={handleKeyDown}
        {...rest}
      >
        {children}
      </li>
    </Tippy>
  );
};
