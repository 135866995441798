import React, { VFC, useEffect, useState } from 'react';
import cn from 'classnames';
import { Control } from 'react-hook-form';

import { Button } from '@components/common';
import { FigmaPrototype } from '@components/common/FigmaPrototype';
import { Portal } from '@components/shared/Portal';
import { Models, Enums, getBlockLabel } from '@components/SurveyBuilder';
import { useDeviceType } from '@hooks/useDeviceType';

import { usePrototypeTaskTracking } from '../hooks/usePrototypeTaskTracking';
import { usePrototypeTaskValidation } from '../hooks/usePrototypeTaskValidation';
import { useUnmoderatedContext } from '../hooks/useUnmoderatedContext';

import { PrototypeTestConfirmationModal } from './PrototypeTestConfirmationModal';
import { ResponsiveCard } from './ResponsiveCard';

import * as Types from '../types';

interface Props {
  block: Models.Block<Enums.Kind.prototypeTest>;
  control: Control<Types.FormData>;
  iframePortalSelector: string;
  onComplete?: () => void;
  onSubmit: () => Promise<void>;
}

export const PrototypeTestTask: VFC<Props> = ({ block, control, iframePortalSelector, onComplete, onSubmit }) => {
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingModalOpen, setLoadingModalOpen] = useState<boolean>(false);
  const [interactionModalOpen, setInteractionModalOpen] = useState<boolean>(false);

  const { isComplete, onIframePrototypeNodeChange } = usePrototypeTaskValidation({ block });
  const { events, onMousePress, onNodeChange } = usePrototypeTaskTracking();
  const { blocks } = useUnmoderatedContext();
  const { isMobile } = useDeviceType();

  const startingNodeId = block.blockable.paths?.[0]?.steps?.find((step) => !step.last)?.external_element_id;

  const handleOnLoad = () => {
    setIsLoading(false);
  };

  const handleOnEndTask = () => {
    if (isLoading) {
      setLoadingModalOpen(true);
    } else if (!isTouched) {
      setInteractionModalOpen(true);
    } else {
      onSubmit?.();
    }
  };

  useEffect(() => {
    control.register('prototype_test_task');
  }, [control]);

  useEffect(() => {
    control.setValue('prototype_test_task', { isComplete, events });

    if (isComplete) {
      onComplete?.();
    }
  }, [control, events, isComplete]);

  return (
    <>
      <ResponsiveCard
        blockKind={getBlockLabel(block.kind)}
        blockPosition={block.position}
        description={block.description}
        title={block.title}
        isComplete={isComplete}
        totalBlocks={blocks.length}
        renderFooter={() =>
          isComplete ? (
            <Button aria-label='Continue' className='btn-custom-brand' type='submit' icon='arrowRight' small noStyle>
              Continue
            </Button>
          ) : (
            <Button
              aria-label='End task'
              className='btn-custom-brand'
              type='button'
              icon='checkInCircle'
              onClick={handleOnEndTask}
              small
              noStyle
            >
              End task
            </Button>
          )
        }
      >
        <Portal selector={iframePortalSelector}>
          <div data-testid='prototype-test-task-wrapper' className={cn('h-full w-full', { 'pb-16': isMobile })}>
            <FigmaPrototype
              className='w-full h-full'
              hideUi
              hideHotspots
              nodeId={startingNodeId}
              data-testid='prototype-test-task-iframe'
              onPrototypeFirstEvent={handleOnLoad}
              onPrototypePresentedNodeChanged={(data) => {
                onIframePrototypeNodeChange(data);
                onNodeChange?.(data);
              }}
              onPrototypeMousePress={(data) => {
                onMousePress?.(data);
                setIsTouched(true);
              }}
              src={block.blockable.url ?? ''}
            />
          </div>
        </Portal>
      </ResponsiveCard>

      {loadingModalOpen && (
        <PrototypeTestConfirmationModal
          text='Are you sure? The prototype test is still loading.'
          onClose={() => setLoadingModalOpen(false)}
          onConfirm={onSubmit}
        />
      )}

      {interactionModalOpen && (
        <PrototypeTestConfirmationModal
          text='We detected that there was no interaction with the prototype. Are you sure you want to end the task?'
          onClose={() => setInteractionModalOpen(false)}
          onConfirm={onSubmit}
        />
      )}
    </>
  );
};
