import cn from 'classnames';
import { ChevronDown } from '@components/shared/Tiptap/icons';
import { composeEventHandlers } from '@helpers/composeEventHandlers';
import { useDeviceType } from '@hooks/useDeviceType';
import React, { useEffect, useMemo, useState } from 'react';
import tinytime from 'tinytime';

import { Button, Card, Popper, Text } from '@components/common';
import { BookingCalendar } from '@components/shared/BookingCalendar';
import { CalendarSVG, ChevronDownSVG } from '@components/svgs';

const dateTemplate = tinytime('{MM} {DD}');
const dateTemplateYear = tinytime('{MM} {DD}, {YYYY}');

export type InterviewDates = {
  startDate: Date;
  endDate: Date | null;
};

interface Props {
  disabled?: boolean;
  isDraft: boolean;
  initialState: InterviewDates;
  onChange: (value: Omit<InterviewDates, 'schedulingStyle'>) => void;
  continuous?: boolean;
  error?: boolean;
}

const DatePicker = ({
  startDate,
  endDate,
  className,
  onChange
}: {
  startDate: Date;
  endDate: Date | null;
  className?: string;
  onChange: (start: Date, end?: Date) => void;
}) => {
  return (
    <Popper
      zIndex={20}
      className='shadow-lg'
      placement='bottom-start'
      offset={[0, 10]}
      renderOnBodyRoot={false}
      closeOnClickOutside
      content={({ closePopper }) => (
        <Card noPadding>
          <section className='p-6 pt-4'>
            <BookingCalendar
              double={false}
              startDate={startDate}
              endDate={endDate}
              onChange={composeEventHandlers(onChange, closePopper)}
              isRange={false}
            />
          </section>
        </Card>
      )}
    >
      <button aria-label='Date picker' className={cn('focus:outline-none flex items-center text-sm', className)}>
        {dateTemplateYear.render(endDate ? endDate : startDate)}
        <ChevronDown className='ml-3' />
      </button>
    </Popper>
  );
};

export const InterviewDatesDropdown: React.FC<Props> = ({ disabled, initialState, continuous, onChange, error }) => {
  const [startDate, setStartDate] = useState<Date>(initialState.startDate);
  const [endDate, setEndDate] = useState<Date | null>(initialState.endDate);

  const { isMobile } = useDeviceType();

  const dateStr = useMemo(() => {
    const separator = continuous ? 'until' : '-';
    const start = dateTemplate.render(startDate);
    const end = endDate ? dateTemplate.render(endDate) : 'canceled';

    return `${start} ${separator} ${end}`;
  }, [startDate, endDate, initialState, continuous]);

  const handleSubmit = () => {
    const startChanged = initialState.startDate.getTime() !== startDate.getTime();
    const endChanged = initialState.endDate?.getTime() !== endDate?.getTime();

    if (startChanged || endChanged) {
      onChange({
        startDate,
        endDate
      });
    }
  };

  const onBookingCalendarChange = (start: Date, end?: Date): void => {
    setStartDate(start);
    if (end) {
      setEndDate(end);
    } else {
      setEndDate(null);
    }
  };

  useEffect(() => {
    setStartDate(initialState.startDate);
  }, [initialState.startDate]);

  useEffect(() => {
    setEndDate(initialState.endDate);
  }, [initialState.endDate]);

  useEffect(() => {
    if (continuous && startDate.getTime() !== initialState.startDate.getTime()) {
      onChange({
        startDate,
        endDate: null
      });
    }
  }, [startDate]);

  return (
    <div className='relative'>
      <Popper
        zIndex={20}
        placement='bottom-start'
        offset={[0, 10]}
        content={({ closePopper }) => (
          <Card noPadding className='shadow-lg'>
            {continuous ? (
              <div className='p-6 pt-4'>
                <Text h='400' className='mb-2' bold>
                  Starts
                </Text>
                <DatePicker startDate={startDate} endDate={null} onChange={setStartDate} />
                <Text h='400' className='mt-6 mb-2' bold>
                  Ends
                </Text>
                <Text h='400'>Until canceled</Text>
              </div>
            ) : (
              <>
                <div className='p-6 pt-4'>
                  <BookingCalendar
                    double={!isMobile}
                    isRange
                    startDate={startDate}
                    endDate={endDate}
                    onChange={onBookingCalendarChange}
                  />
                </div>
                <div className='px-6 py-4 border-t border-gray-200'>
                  <div className='flex items-center'>
                    <div className='flex-1'>
                      <Text h='400' data-testid='interview_dates_dropdown_dates_selected'>
                        <span className='font-bold'>
                          {
                            // @ts-expect-error - endDate is always defined if study is not continuous
                            Math.floor(1 + (+endDate - +startDate) / (1000 * 60 * 60 * 24))
                          }
                        </span>{' '}
                        dates selected
                      </Text>
                    </div>
                    <Button
                      onClick={() => {
                        handleSubmit();
                        closePopper();
                      }}
                      primary
                    >
                      Done
                    </Button>
                  </div>
                </div>
              </>
            )}
          </Card>
        )}
        closeOnClickOutside
      >
        <button
          aria-label='Date select'
          disabled={disabled}
          className={cn('flex space-x-2 items-center h400 w-full border border-gray-200 rounded-md py-2.5 px-4 h-10', {
            'hover:bg-gray-50': !disabled,
            'bg-gray-50 text-gray-500': disabled,
            'border-red-600': error
          })}
        >
          <CalendarSVG className='text-gray-700' />

          <Text className='flex-1 text-left' h='400' truncate>
            {dateStr}
          </Text>
          {!disabled && <ChevronDownSVG className='justify-self-end w-4 h-4 text-gray-700' />}
        </button>
      </Popper>
    </div>
  );
};
