import React, { VFC } from 'react';

import { Button, Modal, ModalHeading, Text } from '@components/common';

interface Props {
  text: string;
  onClose: () => void;
  onConfirm: () => void;
}

export const PrototypeTestConfirmationModal: VFC<Props> = ({ text, onClose, onConfirm }) => {
  return (
    <Modal icon='danger' onClose={onClose} size='md'>
      <ModalHeading>Ready to end the task?</ModalHeading>

      <section className='mb-6 mt-3'>
        <Text className='text-sm'>{text}</Text>
      </section>

      <section className='flex items-center justify-end space-x-3'>
        <Button type='button' onClick={onClose}>
          Resume task
        </Button>
        <Button type='button' danger onClick={onConfirm}>
          End task
        </Button>
      </section>
    </Modal>
  );
};
