import React, { Fragment, VFC } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import cn from 'classnames';
import { PopperDropdown, PopperDropdownButton } from '@components/common';
import { MoveSVG, PlusSVG } from '@components/svgs';
import { CardSortResult } from '@components/Unmoderated/types';

interface Props {
  card: string;
  category?: string;
  categories: CardSortResult[];
  onCategorySelect: (params: {
    card: string;
    newCategory?: string;
    initialCategory?: string;
    categories: CardSortResult[];
  }) => void;
  onCategoryCreate?: (categories: CardSortResult[]) => CardSortResult[];
  index: number;
}

export const CardItem: VFC<Props> = ({
  onCategoryCreate,
  card,
  categories,
  category: initialCategory,
  onCategorySelect,
  index
}) => {
  const categoriesList = initialCategory ? categories.filter(({ name }) => name !== initialCategory) : categories;

  return (
    <Draggable key={card} draggableId={card} index={index}>
      {({ innerRef, draggableProps, dragHandleProps }, snapshot) => (
        <Fragment key={card}>
          <div
            key={card}
            ref={innerRef}
            {...draggableProps}
            {...dragHandleProps}
            className='bg-white mb-1 py-2 tablet:text-sm flex items-center border border-gray-200 rounded-md'
          >
            <div className='flex-1 px-4'>{card}</div>
            <div className='flex items-center h-full pr-3 space-x-1'>
              <PopperDropdown
                popperProps={{ placement: 'right-start' }}
                className='hover:bg-gray-100 flex items-center justify-center w-6 h-6 rounded-full'
                hideCaret
                textStyle
                tooltip='Move to category'
                small
                aria-label='Move to category'
                renderIcon={() => <MoveSVG className='w-full' />}
                dropdownClass='w-52'
              >
                {!categoriesList.length && onCategoryCreate && (
                  <PopperDropdownButton
                    aria-label='Move to new category'
                    className='flex items-center space-x-2'
                    onClick={() => {
                      const newCategories = onCategoryCreate(categories);
                      onCategorySelect({
                        card,
                        newCategory: newCategories[0].name,
                        initialCategory,
                        categories: newCategories
                      });
                    }}
                  >
                    <PlusSVG className='text-gray-700' />
                    <span className='text-gray-700 no-branding'>New category</span>
                  </PopperDropdownButton>
                )}
                {categoriesList.map((category) => (
                  <PopperDropdownButton
                    className='truncate'
                    key={category.name}
                    onClick={() => onCategorySelect({ card, newCategory: category.name, initialCategory, categories })}
                  >
                    {category.name}
                  </PopperDropdownButton>
                ))}
                {initialCategory && (
                  <PopperDropdownButton
                    color='indigo-600'
                    hoverColor='indigo-700'
                    className={cn(
                      categoriesList.length > 0 && 'border-t border-gray-200',
                      'truncate h200 flex items-center'
                    )}
                    onClick={() => onCategorySelect({ card, initialCategory, newCategory: undefined, categories })}
                  >
                    Remove from category
                  </PopperDropdownButton>
                )}
              </PopperDropdown>
            </div>
          </div>
          {snapshot.isDragging && <div className='h-11 w-full' />}
        </Fragment>
      )}
    </Draggable>
  );
};
