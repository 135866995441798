import pluralize from 'pluralize';
import React, { useEffect } from 'react';

import { api } from '@api/reduxApi';
import { Alert, Loading, SlideOut } from '@components/common';
import { usePlan } from '@hooks/usePlan';
import { useToaster } from '@stores/toaster';

import { StudyMessageSlideOut } from './StudyMessageSlideOut';
import { failedSendScreener, successSendScreener } from './toasts';
import { SendMessageParams } from './types';

interface Props {
  study: Study;
  participations: Participation[];
  onSuccess: (backgroundTask: BackgroundTask) => void;
  onClose: () => void;
  allSelected?: boolean;
  query?: ServerFilterQuery;
}

export const ParticipationsScreenerSlideOut: React.FC<Props> = ({
  query,
  study,
  participations,
  onClose,
  onSuccess
}) => {
  const showToast = useToaster();
  const { hasQuota, getQuota } = usePlan();
  const communicationMethod = study?.comms_medium || 'email';
  const [getData, { data, isSuccess, isLoading }] = api.useGetInviteSlideOutIdsMutation();
  // const [participationBulkScreenerInvite, { isLoading: isSending }] = api.useParticipationBulkScreenerInviteMutation();
  const [candidateBulkScreenerInvite, { isLoading: isSending }] = api.useCandidateBulkScreenerInviteMutation();

  useEffect(() => {
    getData({
      participationsIds: participations.map((p) => p.id),
      communicationMethod
    });
  }, []);

  const screener = study.pre_screener;

  async function handleSend({ batch, excludeIneligible, resend, message, sender }: SendMessageParams) {
    if (!screener) {
      showToast(failedSendScreener());
      return;
    }

    const candidateIds = participations.map((p) => p.customer_id);
    // const participationsIds = participations.map((p) => p.id);

    try {
      const batch_params = {
        batch_on: batch.on,
        batch_size: batch.size,
        batch_wait_hours: batch.wait_hours,
        batch_target: batch.target
      };

      const backgroundTask: BackgroundTask = await candidateBulkScreenerInvite({
        batch: batch_params,
        screenerId: screener.id,
        exclude_ineligible: excludeIneligible,
        resend,
        customer_ids: candidateIds,
        message_id: message && message.custom ? message.id : null,
        sender
      }).unwrap();

      showToast(successSendScreener());
      onSuccess(backgroundTask);
    } catch (e) {
      showToast(failedSendScreener(e.data.error));
      onClose();
    }
  }

  const count = isSuccess && data ? data.inviteable_ids.length : 0;
  const resendCount = isSuccess && data ? data.reinviteable_ids.length : 0;
  const ineligibleCount = isSuccess && data ? data.ineligible_ids.length : 0;
  const contactableCount = isSuccess && data ? data.contactable_ids.length : 0;
  const selectedCount = participations.length;

  const hasInviteQuota = hasQuota('invites', count);

  if (!screener) {
    return (
      <SlideOut title='Screener invite' onClose={onClose} size='xl'>
        <div className='p-6'>
          <Alert type='warning'>Cannot send as there is no screener associated with this study</Alert>
        </div>
      </SlideOut>
    );
  }

  if (isLoading || !data) {
    return (
      <SlideOut title='Screener invite' onClose={onClose} size='xl'>
        <div className='p-6'>
          <Loading absolute />
        </div>
      </SlideOut>
    );
  }

  if (!hasInviteQuota) {
    return (
      <SlideOut title='Screener invite' onClose={onClose} size='xl'>
        <div className='p-6'>
          <Alert type='warning'>
            Cannot send all {pluralize('messages', count, true)} as this exceeds your remaining quota of{' '}
            {getQuota('invites')}
          </Alert>
        </div>
      </SlideOut>
    );
  }

  return (
    <StudyMessageSlideOut
      title='Screener invite'
      batchingTitle='screener invites'
      batchingTarget={true}
      batchable
      cta='Send Screener'
      study={study}
      event='screener'
      customizable
      totalCount={selectedCount}
      inviteableCount={count}
      ineligibleCount={ineligibleCount}
      contactableCount={contactableCount}
      resendCount={resendCount}
      previewCandidate={participations[0]?.customer}
      previewParticipation={participations[0]}
      onClose={onClose}
      onSend={handleSend}
    >
      <>{isSending && <Loading absolute />}</>
    </StudyMessageSlideOut>
  );
};
