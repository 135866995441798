import { BackgroundTaskStatus } from 'components/shared/BackgroundTaskStatus';
import React, { useEffect } from 'react';

interface Props {
  params: { objectId: number } | { actions: BackgroundTaskAction[] };
  setBackgroundTasks: (backgroundTasks: BackgroundTask[]) => void;
  backgroundTasks: BackgroundTask[];
  hidden?: boolean;
  onFinished: () => void;
  backgroundTasksQuery: (any) => any;
}

export const BackgroundTasks: React.FC<Props> = ({
  params,
  backgroundTasks,
  setBackgroundTasks,
  onFinished,
  backgroundTasksQuery,
  hidden
}) => {
  const { data } = backgroundTasksQuery(params);

  useEffect(() => {
    if (data && data.length > 0) {
      setBackgroundTasks(data);
    }
  }, [data]);

  const setBackgroundTask = (backgroundTask: BackgroundTask) => {
    const updatedBackgroundTasks = backgroundTasks.map((task) => {
      if (task.id === backgroundTask.id) {
        return backgroundTask;
      }
      return task;
    });
    setBackgroundTasks(updatedBackgroundTasks);
  };

  return (
    <>
      {backgroundTasks &&
        backgroundTasks.map((backgroundTask) => (
          <BackgroundTaskStatus
            key={backgroundTask.id}
            backgroundTask={backgroundTask}
            setBackgroundTask={setBackgroundTask}
            onFinished={onFinished}
            hidden={hidden}
          />
        ))}
    </>
  );
};
