import * as React from 'react';

import { Button, Modal, ModalHeading, ModalSubheading } from '@components/common';

import { UploadFileZone, UploadFileZoneProps } from './UploadFileZone';

export type Props = UploadFileZoneProps & {
  heading?: React.ReactNode;
  subheading?: string;
  open: boolean;
  onClose?: () => void;
};

export const UploadFileModal: React.FC<Props> = ({
  heading = 'Upload file',
  subheading,
  open,
  onClose,
  ...fileZoneProps
}) =>
  open ? (
    <Modal size='md' onClose={onClose}>
      <ModalHeading>{heading}</ModalHeading>
      {subheading && <ModalSubheading className='mt-2'>{subheading}</ModalSubheading>}

      <UploadFileZone {...fileZoneProps} />

      <div className='text-right'>
        <Button
          disabled={fileZoneProps.uploading}
          onClick={(e) => {
            e.stopPropagation();
            onClose?.();
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  ) : null;
