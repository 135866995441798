import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { Button, FormGroup, Select, SlideOut, Text } from '@components/common';
import { useStudyBookability } from '@components/StudyMessages/hooks/useStudyBookability';
import { BookabilityAlerts } from '@components/shared/BookabilityAlerts';
import { RestrictedButton } from '@components/shared/RestrictedButton';
import { useStudies } from '@hooks/useStudies';
import { useToaster } from '@stores/toaster';

import { ShareableLinks } from './ShareableLinks';

type Props = {
  onClose: () => void;
};

export const InviteViaLinkPanelSlideout: React.FC<Props> = ({ onClose }) => {
  const { panelStudies } = useStudies();
  const navigate = useNavigate();
  const showToast = useToaster();

  const [studyId, setStudyId] = useState<number | null>(null);

  const { bookability, startPolling } = useStudyBookability({
    studyId: studyId || 0,
    event: studyId ? 'invite' : undefined
  });

  const options =
    panelStudies?.map((study) => ({
      label: study.title,
      value: String(study.id)
    })) || [];

  const study = panelStudies?.find((s) => s.id === studyId);

  const [createStudy, { isLoading, isError }] = api.useCreateStudyMutation();

  useEffect(() => {
    if (isError) {
      showToast({
        icon: 'error',
        heading: 'Couldn’t create that study.',
        text: 'Please try again or contact support.'
      });
    }
  }, [isError]);

  const handleClickCreatePanelStudy = async () => {
    const study = await createStudy({ style: 'panel' }).unwrap();
    navigate(`/studies/${study.id}/edit`);
  };

  return (
    <SlideOut
      subtitle='Select which panel recruitment study to invite them to.'
      title='Invite via link'
      onClose={onClose}
      size='xl'
    >
      <div className='px-6 mt-2'>
        {panelStudies?.length === 0 && (
          <div className='text-center'>
            <svg
              className='mx-auto my-6'
              width='48'
              height='48'
              viewBox='0 0 48 48'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g id='File minus'>
                <path
                  id='Icon'
                  d='M28 4H12C10.9391 4 9.92172 4.42143 9.17157 5.17157C8.42143 5.92172 8 6.93913 8 8V40C8 41.0609 8.42143 42.0783 9.17157 42.8284C9.92172 43.5786 10.9391 44 12 44H36C37.0609 44 38.0783 43.5786 38.8284 42.8284C39.5786 42.0783 40 41.0609 40 40V16M28 4L40 16M28 4V16H40M18 30H30'
                  stroke='#6B7280'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </g>
            </svg>
            <Text bold mb='2'>
              No panel recruitment studies set up yet
            </Text>
            <Text h='400' color='gray-500' mb='6'>
              Please create a study in order to generate an invite link.
            </Text>
            <RestrictedButton action='Create panel recruitment study' permission='createStudy'>
              <Button loading={isLoading} onClick={handleClickCreatePanelStudy} primary>
                Create panel recruitment study
              </Button>
            </RestrictedButton>
          </div>
        )}
        {options.length > 0 && (
          <FormGroup>
            <Text as='label' bold>
              Select study
            </Text>
            <span className='h400 block mt-1 mb-3 text-gray-500'>We’ll provide the invite link to share.</span>
            <div className='flex flex-col'>
              <Select
                overflowClass='visible'
                options={options}
                onChange={(v) => setStudyId(Number(v))}
                value={String(studyId) || ''}
                renderLabel={(option) => <div>{option.label}</div>}
              />
            </div>
          </FormGroup>
        )}
        {study && bookability && (
          <div className='mb-6'>
            <BookabilityAlerts study={study} bookability={bookability} onStartPolling={startPolling} />
          </div>
        )}
        {study && <ShareableLinks study={study} />}
      </div>
    </SlideOut>
  );
};
