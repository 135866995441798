import { QuestionCard, QuestionInner, QuestionWrapper } from 'components/Public/GQSurvey/QuestionCard';
import * as React from 'react';

export const SurveyPreview: React.FC<{ brand: Brand; accountName: string }> = ({ brand, accountName }) => {
  const { logo } = brand;

  return (
    <div className='brand relative flex flex-col flex-1 h-full'>
      <div className='tablet:fixed tablet:z-10 tablet:border-b w-full pb-6 text-center bg-white border-gray-200'>
        {logo && (
          <div className={`block desktop:pt-8 desktop:px-12 p-4 w-full logo-align-brand bg-white`}>
            <div className='inline w-auto'>
              <img alt={`${accountName} logo`} className='desktop:max-h-32 max-h-24 w-auto inline' src={logo} />
            </div>
          </div>
        )}
        <span
          style={{ paddingTop: logo ? undefined : '24px' }}
          className='block text-center text-custom-brand text-xl font-bold'
        >
          Help us build a better product experience at {accountName}.
        </span>
      </div>
      <div className='flex-1 tablet:py-6 tablet:px-8 max-w-4xl pb-12 mx-auto tablet:py-48'>
        <form>
          <QuestionWrapper id={1}>
            <QuestionCard isActive>
              <div className='text-custom-brand w-8 text-xl font-bold'>1.</div>
              <QuestionInner>
                <div className='text-xl font-bold leading-normal text-custom-brand flex items-start space-x-1'>
                  <span>What’s your favorite place to work from?</span>
                </div>

                <div className='w-full mt-5'>
                  <label className='py-2 text-base font-normal normal-case cursor-pointer'>
                    <input
                      type='radio'
                      className=' focus-ring-custom-brand w-4 h-4 mr-2 input-custom-brand border-gray-400'
                    />
                    Home
                  </label>
                  <label className='py-2 text-base font-normal normal-case cursor-pointer'>
                    <input
                      type='radio'
                      className=' focus-ring-custom-brand w-4 h-4 mr-2 input-custom-brand border-gray-400'
                    />
                    Office
                  </label>
                  <label className='py-2 text-base font-normal normal-case cursor-pointer'>
                    <input
                      type='radio'
                      className=' focus-ring-custom-brand w-4 h-4 mr-2 input-custom-brand border-gray-400'
                    />
                    Other
                  </label>
                </div>
              </QuestionInner>
            </QuestionCard>
          </QuestionWrapper>

          <div className='tablet:flex-row tablet:p-0 flex flex-col items-center justify-end px-10'>
            <div className='flex-1'>
              <span className='text-custom-brand-secondary'>0 of 3</span>
            </div>

            <button className='btn-custom-brand block disabled:opacity-50 cursor-pointer inline-flex items-center justify-center relative focus:ring focus:ring-blue focus:outline-none font-medium bg-indigo-600 text-white text-sm leading-5 px-4 py-2.5 rounded-md tablet:w-auto tablet:mb-0mb-4'>
              Continue
            </button>
          </div>
        </form>
      </div>
      <div className='flex-0 monitor:px-36 tablet:px-16 desktop:px-20 monitor:px-24 tablet:pb-10 tablet:border-0 tablet:items-end flex items-center justify-between w-full px-10 py-4 text-center bg-white border-t border-gray-200'>
        <p className='h400 capitalize'>* Required</p>
        <div className='inline-block'>
          <a href='greatquestion.co' className='xx-powered-by flex flex-col justify-center text-center'>
            <span className='text-xs font-light text-gray-500 uppercase'>Powered by</span>
            <img src='/temp-assets/logo.png' className='w-auto h-6' alt='Great Question' />
          </a>
        </div>
      </div>
    </div>
  );
};
