import React, { useCallback, VFC } from 'react';

import { Button } from '@components/common';
import { Portal } from '@components/shared/Portal';
import { Enums, Models } from '@components/SurveyBuilder';

import { useUnmoderatedContext } from '../hooks/useUnmoderatedContext';

import { ResponsiveCard } from './ResponsiveCard';

interface Props {
  block: Models.Block<Enums.Kind.websiteTest>;
  iframePortalSelector: string;
}

export const WebsiteTestTask: VFC<Props> = ({ block, iframePortalSelector }) => {
  const { blocks } = useUnmoderatedContext();

  const openWebsiteInNewTab = () => {
    let { url } = block.blockable;

    if (url) {
      if (!/^https?:\/\//i.test(url)) {
        url = `https://${url}`;
      }

      window.open(url, '_blank');
    }
  };

  const footerRenderer = useCallback(() => {
    return (
      <div className='flex items-center space-x-2'>
        {!block.blockable.is_iframable && (
          <Button aria-label='Open website' type='button' icon='externalLink' onClick={openWebsiteInNewTab} small>
            Open website
          </Button>
        )}

        <Button aria-label='End task' className='btn-custom-brand' type='submit' icon='checkInCircle' small noStyle>
          End task
        </Button>
      </div>
    );
  }, []);

  return (
    <ResponsiveCard
      blockKind={block.kind}
      blockPosition={block.position}
      description={block.description}
      title={block.title}
      totalBlocks={blocks.length}
      renderFooter={footerRenderer}
    >
      {block.blockable.is_iframable && (
        <Portal selector={iframePortalSelector}>
          <iframe src={block.blockable.url ?? ''} title='Website task website' className='w-full h-full'></iframe>
        </Portal>
      )}
    </ResponsiveCard>
  );
};
