import cn from 'classnames';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

import { FormGroup, Input, Select, SelectOption, Text, Tooltip } from '@components/common';
import { studiesToOptions } from '@components/CandidatesApp/CandidatesIndex/ShortlistToStudy';
import { ContactAccess } from '@components/CandidatesApp/CandidatesShow/components/ContactAccess';
import { useCandidateAttrs } from '@hooks/useCandidateAttrs';
import { useFeature } from '@hooks/useFeature';
import { useStudies } from '@hooks/useStudies';

import { SelectUpdateExisting } from './SelectUpdateExisting';
import { AdditionalAttrsForm } from '../components/AdditionalAttrsForm';
import type { ValidationError } from './EditCustomerImportPage';

const EMAIL_OPTION: SelectOption = { label: 'Email', value: 'email' };
const uniqueIdOptions: (attrs: Attr_[]) => SelectOption[] = (attrs) => {
  return [EMAIL_OPTION].concat(attrs.filter((a) => a.unique).map((a) => ({ label: a.name, value: `extra:${a.name}` })));
};

type Props = {
  customerImport: CustomerImport;
  validationErrors?: ValidationError[];
  onChange: (customerImport: CustomerImport) => void;
};

export const SettingsPanel: React.FC<Props> = ({ customerImport, validationErrors, onChange }) => {
  const enableTeams = useFeature('teams');
  const { studies, loading: studiesLoading } = useStudies();
  const { allAttrs, isLoading: attrsLoading } = useCandidateAttrs();
  const hasUniqueCustomAttrs = useFeature('unique_custom_attrs');

  const handleChangeName = (name: string) => {
    onChange({ ...customerImport, name });
  };

  const handleChangeStudyId = (project_id: string) => {
    const studyId: number | null = project_id === '' || !project_id ? null : Number(project_id);

    onChange({ ...customerImport, project_id: studyId });
  };

  const handleChangeIdAttr = (id_attr: string) => {
    const newAttrs: Partial<CustomerImport> = { id_attr };
    if (id_attr.startsWith('extra:')) {
      newAttrs.update_existing = true;
    }
    onChange({ ...customerImport, ...newAttrs });
  };

  return (
    <div className='p-6 bg-white border border-gray-200 rounded'>
      <Text h='300' mb='6'>
        Import settings
      </Text>

      <FormGroup>
        <div className='flex items-center mb-4 space-x-2'>
          <Text as='label' htmlFor='name' bold>
            Import name
          </Text>
          <Tooltip content='Helps you find the import history and filter by candidates in the import.' />
        </div>
        <Input id='name' value={customerImport.name || ''} onChange={handleChangeName} className='w-full' />
      </FormGroup>

      <FormGroup>
        <div className='flex items-center mb-4 space-x-2'>
          <Text as='label' htmlFor='update_existing' bold>
            Candidate update process
          </Text>
          <Tooltip content='If candidates already exist in the system, this determines whether their data will be updated or not.' />
        </div>
        <SelectUpdateExisting
          value={customerImport.update_existing}
          onChangeValue={(value) => {
            onChange({ ...customerImport, update_existing: value === 'true' });
          }}
        />
      </FormGroup>

      <FormGroup>
        <div className='flex items-center mb-4 space-x-2'>
          <Text as='label' htmlFor='contact_access' bold>
            Contact access
          </Text>
          <Tooltip content='This determines which users on your account can contact these candidates.' />
        </div>
        {enableTeams && (
          <ContactAccess
            className={cn('bg-white border border-gray-200', {
              'border-red-600':
                validationErrors?.includes('contact_access_needs_public') ||
                validationErrors?.includes('contact_access_missing_team')
            })}
            initialValue={customerImport.contact_access}
            initialTeamIds={customerImport.team_ids}
            onChangeValue={(contact_access) => {
              if (contact_access === 'public') {
                onChange({ ...customerImport, contact_access, team_ids: [], team_ids_str: '' });
              } else {
                onChange({ ...customerImport, contact_access });
              }
            }}
            onChangeTeamIds={(team_ids) => {
              onChange({ ...customerImport, team_ids, team_ids_str: team_ids.join(',') });
            }}
          />
        )}
      </FormGroup>

      <FormGroup>
        <div className='flex items-center mb-4 space-x-2'>
          <Text as='label' htmlFor='project_id' bold>
            Shortlist for study
          </Text>
          <Tooltip content='You can select a specific study where candidates will be added as shortlisted. Everyone will be added to Candidates panel as well.' />
          <Text color='gray-500'>(Optional)</Text>
        </div>
        <div className=''>
          {studiesLoading && <Skeleton className='bg-gray-50 h-12' />}
          {!studiesLoading && !customerImport.fixed_project_id && (
            <Select
              id='project_id'
              className='bg-white'
              maxH={60}
              options={studies ? studiesToOptions(studies, true) : []}
              value={customerImport.project_id ? String(customerImport.project_id) : 'null'}
              onChange={handleChangeStudyId}
              popperOptions={{ placement: 'bottom-start' }}
              placeholder='No study'
            />
          )}
          {!studiesLoading && customerImport.fixed_project_id && (
            <Text>{studies?.find(({ id }) => id === customerImport.project_id)?.title || 'No study'}</Text>
          )}
        </div>
      </FormGroup>
      {hasUniqueCustomAttrs && (
        <FormGroup>
          <div className='flex items-center mb-2 space-x-2'>
            <Text bold>Unique identifier</Text>
            <Tooltip content='We use Email as a default attribute for tracking, preventing duplicates, etc. You can select a different custom attribute to match candidates to your existing database.' />
          </div>
          {attrsLoading && <Skeleton className='bg-gray-50 h-12' />}
          {!attrsLoading && (
            <Select
              className='bg-white'
              options={uniqueIdOptions(allAttrs)}
              value={customerImport.id_attr}
              onChange={handleChangeIdAttr}
            />
          )}
        </FormGroup>
      )}
      <FormGroup>
        <div className='flex items-center mb-4 space-x-2'>
          <Text as='label' htmlFor='project_id' bold>
            Add attributes in bulk
          </Text>
          <Tooltip content='Here you can include more information on the imported customers that wasn’t included in your .CSV file.' />
          <Text color='gray-500'>(Optional)</Text>
        </div>
        {attrsLoading && <Skeleton className='bg-gray-50 h-12' />}
        {!attrsLoading && (
          <AdditionalAttrsForm
            disabled={false}
            value={customerImport.attrs}
            onChange={(attrs) => onChange({ ...customerImport, attrs })}
          />
        )}
      </FormGroup>
    </div>
  );
};
