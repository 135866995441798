import { IrregularCheckmarkSVG } from '@components/svgs';
import { Text } from '@components/common';
import React from 'react';

export const CardsListZDS = () => (
  <div className='my-16 flex flex-col items-center'>
    <IrregularCheckmarkSVG />
    <Text bold className='mt-4 text-center'>
      All cards sorted
    </Text>
    <Text h='200' className='mt-2 text-center'>
      Review everything and press "Continue" if satisfied.
    </Text>
  </div>
);
