import { Table } from 'components/shared/Table';
import React, { useMemo, useState } from 'react';
import { Text, Toggle, Tooltip } from '@components/common';
import cn from 'classnames';
import {
  AgreementMatrixData,
  buildAgreementMatrixData
} from '@components/ScreenerResults/components/CardSortResponse/components/helpers/buildAgreementMatrixData';
import { AgreementRate } from '@components/ScreenerResults/components/CardSortResponse/types';
import { ColumnDef } from '@tanstack/react-table';
import { getAgreementMatrixColumnDefs } from '@components/ScreenerResults/components/CardSortResponse/components/helpers/getAgreementMatrixColumnDefs';

interface Props {
  answers: ScreenerResponseAnswerValue<'card_sort'>[];
  answersWithMergedCategories: ScreenerResponseAnswerValue<'card_sort'>[];
  categories: string[];
  mergedCategoriesNames: string[];
  cards: string[];
  showMergedCategoriesToggle?: boolean;
  mergedCategories: { name: string; categories: string[] }[];
}

export type DefinitionFnReturn = ColumnDef<AgreementRate>[];

export type DefinitionFn = () => DefinitionFnReturn;

export const AgreementMatrix: React.FC<Props> = ({
  mergedCategoriesNames,
  answersWithMergedCategories,
  answers,
  categories,
  cards,
  showMergedCategoriesToggle,
  mergedCategories
}) => {
  const [showMergedCategories, setShowMergedCategories] = useState(mergedCategoriesNames.length > 0);
  const [showPercentage, setShowPercentage] = useState(false);

  const data = useMemo(() => {
    return buildAgreementMatrixData({
      answers: showMergedCategories ? answersWithMergedCategories : answers,
      categories: showMergedCategories ? mergedCategoriesNames : categories,
      cards
    });
  }, [cards, categories, answers, showMergedCategories, mergedCategoriesNames]);

  const columns = useMemo(
    () =>
      getAgreementMatrixColumnDefs({
        showPercentage,
        categories: showMergedCategories ? mergedCategoriesNames : categories,
        answers: showMergedCategories ? answersWithMergedCategories : answers,
        cards,
        mergedCategories
      }),
    [categories, showPercentage, answers, cards, showMergedCategories, mergedCategoriesNames, mergedCategories]
  );

  return (
    <div className='w-full'>
      <div className='mb-4 flex items-center'>
        <div className='flex items-center flex-1'>
          <Text bold className='mr-1'>
            Agreement matrix
          </Text>
          <Tooltip>
            The agreement matrix gives you a visual representation of how often users agree that a card belongs in each
            category.
          </Tooltip>
        </div>
        {showMergedCategoriesToggle && (
          <div className='flex items-center mr-2'>
            <Toggle
              on={showMergedCategories}
              onToggle={setShowMergedCategories}
              disabled={!mergedCategoriesNames.length}
            />
            <div className={cn('h400 mr-1 ml-2', !mergedCategoriesNames.length && 'text-gray-400')}>
              Show merged categories
            </div>
          </div>
        )}
        <div className='flex items-center'>
          <Toggle on={showPercentage} onToggle={setShowPercentage} />
          <div className='h400 mr-1 ml-2'>Show %</div>
          <Tooltip>Toggle between raw counts and counts as a percentage of total responses</Tooltip>
        </div>
      </div>

      <div className='relative max-w-full overflow-auto'>
        <Table<AgreementMatrixData> columnPinning={{ left: ['card'] }} data={data} columns={columns} />
      </div>
    </div>
  );
};
