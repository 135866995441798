import { useGetDocumentQuery } from './api';
import { TiptapHook, Props as TiptapProps, useTiptap } from './useTiptap';
import { useMemo, useEffect } from 'react';
import consumer from 'helpers/consumer';

type Props = TiptapProps & {
  documentId?: number;
  localDoc?: ApiDocument['doc'];
  liveUpdate?: boolean;
};

const DocumentChannel = (id, opts = {}) =>
  consumer.subscriptions.create(
    {
      channel: 'DocumentsChannel',
      document_id: id
    },
    opts
  );

export const useTiptapFromDocumentId = (props: Props): TiptapHook => {
  const { localDoc, documentId } = props;

  const { data, refetch, isUninitialized, isLoading } = useGetDocumentQuery(props.documentId as number, {
    skip: !documentId,
    refetchOnMountOrArgChange: true
  });

  const docData = useMemo(() => (localDoc ? { ...data, doc: localDoc } : data), [localDoc, data]) as ApiDocument;

  useEffect(() => {
    if (props.liveUpdate && props.documentId) {
      DocumentChannel(props.documentId).received = (data) => {
        if (!isUninitialized && !isLoading) {
          refetch();
        }
      };
    }
  }, [props.documentId, isUninitialized]);

  return useTiptap({
    ...props,
    data: docData
  });
};
