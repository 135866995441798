import { browserTimezoneDesc } from '@components/shared/TimezonePicker';
import * as React from 'react';
import { Link } from 'react-router-dom';
import tinytime from 'tinytime';

import { compact } from '@components/utils';
import { useAccount } from '@hooks/useAccount';

import { CANDIDATE_MESSAGE_EVENTS } from './constants';

const dateFormat = tinytime('{MMMM} {Do} at {h}:{mm}{a} {Z}');

interface Props {
  user: User;
  scope: string;
  activity: PublicActivity;
}

const ActivityInfo: React.FC<Props> = ({
  user,
  scope = 'candidate',
  activity: { key, customer, project, owner, parameters }
}) => {
  const {
    account: { team }
  } = useAccount();
  const byCustomer = owner && owner.type === 'Customer' && owner.id === customer.id;
  let customerLink: any = ' ';
  let studyContext: any = <></>;
  let studyLink: any = ' ';

  const screenerResponses = {
    pre_panel: () => <>Completed the screener{studyContext}</>,
    pre_video_call: () => <>Completed the screener{studyContext}</>,
    pre_survey: () => <>Completed the screener{studyContext}</>,
    pre_online_task: () => <>Completed the task{studyContext}</>,
    pre_unmoderated_test: () => <>Completed the screener{studyContext}</>,
    survey_panel: () => <>Joined the panel{studyContext}</>,
    survey_survey: () => <>Submitted the survey{studyContext}</>,
    survey_unmoderated_test: () => <>Completed the test{studyContext}</>
  };

  const studyUrl = project
    ? customer
      ? `/studies/${project.id}#candidates/${customer.id}`
      : `/studies/${project.id}`
    : '';

  const customerUrl = customer ? `/studies/${project?.id}#candidates/${customer.id}` : '';

  switch (scope) {
    case 'candidate':
      customerLink = `${customer?.first_name || 'them'} `;

      studyLink = project && (
        <Link className='cursor-pointer font-semibold mr-0.5' to={studyUrl}>
          {project.title}
        </Link>
      );
      studyContext = <> for the study {studyLink}</>;
      break;
    case 'participation':
      customerLink = `${customer?.first_name || 'them'} `;
      break;
    case 'study':
      customerLink = customer ? (
        <Link className='font-semibold cursor-pointer' to={customerUrl}>
          {customer?.name || 'a candidate'}
        </Link>
      ) : (
        ''
      );
      break;
  }

  const timeContext =
    parameters?.interview_at &&
    compact([
      ` for ${dateFormat.render(new Date(parameters.interview_at * 1000))}`,
      browserTimezoneDesc().abbreviation
    ]).join(' ');

  const modName = parameters?.moderator_id && team.find((u) => u.id === parameters.moderator_id)?.name;

  switch (key) {
    case 'participation.shortlisted':
      return (
        <>
          Shortlisted {customerLink}
          {studyContext}
        </>
      );

    case 'participation.applied':
      return <>Applied{studyContext}</>;

    case 'participation.marked_as_booked':
    case 'participation.booked':
      if (byCustomer) {
        return (
          <>
            Booked an interview{timeContext}
            {studyContext}
          </>
        );
      } else {
        return (
          <>
            Marked {customerLink} as booked{timeContext}
            {studyContext}
          </>
        );
      }

    case 'participation.completed':
      return (
        <>
          Marked {customerLink} as completed participation{studyContext}
        </>
      );

    // case 'participation.update':
    case 'participation.consented':
      return <>Consented{studyContext}</>;

    case 'participation.invited':
      return (
        <>
          Sent {customerLink} the screener{studyContext}
        </>
      );

    case 'participation.no_show':
    case 'participation.no_showed':
      return (
        <>
          Marked {customerLink} as no show{studyContext}
        </>
      );

    case 'participation.opted_out':
      return <>Opted out{studyContext}</>;

    case 'participation.rejected':
      if (byCustomer) {
        return <>Removed themselves {studyContext}</>;
      }
      return (
        <>
          Removed {customerLink}
          {studyContext}
        </>
      );

    case 'participation.created_time_proposal':
      return (
        <>
          {customerLink} proposed an alternate time for an interview{studyContext}
        </>
      );

    case 'participation.manual_reschedule':
      return (
        <>
          Rescheduled {customerLink} {timeContext}
          {studyContext}
        </>
      );

    case 'participation.reschedule_requested':
      return (
        <>
          Sent a rechedule request to {customerLink}
          {studyContext}
        </>
      );

    case 'participation.reschedule':
      return (
        <>
          {customerLink} rescheduled{timeContext}
          {studyContext}
        </>
      );

    case 'participation.waitlisted':
      return (
        <>
          {customerLink} joined the waitlist{studyContext}
        </>
      );

    case 'participation.removed_from_waitlist':
      return (
        <>
          {customerLink} removed themselves from the waitlist{studyContext}
        </>
      );

    case 'participation.requested':
      return (
        <>
          Invited {customerLink} to participate{studyContext}
        </>
      );

    case 'participation.started':
      return <>Started participating{studyContext}</>;

    case 'participation.removed_as_started':
      return <>Was returned from started to invited{studyContext}</>;

    case 'participation.canceled':
      if (byCustomer) {
        return <>Canceled their interview {studyContext}</>;
      }
      return (
        <>
          Marked {customerLink} as canceled on the {studyLink}
        </>
      );

    case 'participation.moderator_updated':
      return (
        <>
          Set {modName || 'not found user'} as moderator for the interview with {customerLink} on {studyLink}
        </>
      );

    case 'participation.updated_incentive':
      if (parameters?.incentive_previously_was) {
        return (
          <>
            Updated incentive for {customerLink} from ${parameters.incentive_previously_was} to ${parameters.incentive}
            {studyContext}
          </>
        );
      } else {
        return (
          <>
            Set incentive for {customerLink} to ${parameters.incentive}
            {studyContext}
          </>
        );
      }

    case 'customer.create':
      if (byCustomer) {
        return <>Signed up to your research pool.</>;
      } else {
        return <>Added {customerLink} to the research pool</>;
      }

    case 'customer.contact_access_updated':
      return (
        <>
          Updated contact access for {customerLink} to{' '}
          {parameters?.value === 'needs_team' ? 'only specific teams' : 'anyone on the workspace'}
        </>
      );

    case 'customer.sent_screener':
      return (
        <>
          {' '}
          Sent {customerLink} the screener {studyContext}{' '}
        </>
      );

    case 'customer_import.completed':
      return <> Imported {parameters?.count || 0} new candidates.</>;

    case 'incentive.redeemed':
      return (
        <>
          {' '}
          {customerLink}redeemed {parameters?.amount ? `a $${parameters.amount}` : 'an'} incentive{studyContext}
        </>
      );

    case 'incentive.sent':
      return (
        <>
          {' '}
          Sent {customerLink}
          {parameters?.amount ? `a $${parameters.amount}` : 'an'} incentive{studyContext}
        </>
      );

    case 'incentive.sent_coupon':
      return (
        <>
          {' '}
          Sent {customerLink} the coupon code {parameters?.incentive_coupon} {studyContext}
        </>
      );

    case 'project.bulk_completed':
      return (
        <>
          {' '}
          Marked {parameters?.count || 0} participations as completed{studyContext}{' '}
        </>
      );

    case 'project.bulk_no_showed':
      return (
        <>
          {' '}
          Marked {parameters?.count || 0} participations as no show{studyContext}{' '}
        </>
      );

    case 'project.bulk_rejected':
      return (
        <>
          {' '}
          Removed {parameters.count} participations{studyContext}{' '}
        </>
      );

    case 'project.closed':
      return <> Closed the study {studyContext}.</>;

    case 'project.create':
      return <> Created a new study {studyContext}.</>;

    case 'project.sent_requests':
      return (
        <>
          {' '}
          Sent {parameters?.total || 0} invites{' '}
          {parameters?.batching ? `in batches of ${parameters?.batch_size}` : 'at once'}
          {studyContext}
        </>
      );

    case 'screener.sent_requests':
      return (
        <>
          {' '}
          Sent {parameters?.count || 0} candidates the screener{studyContext}{' '}
        </>
      );

    case 'screener_response.create':
      return <>{screenerResponses[`${parameters?.screener_type}_${project?.style}`]?.()}</>;

    case 'report.published':
      return <> Published a report for {studyLink}</>;

    case 'project.shortlisted':
      return (
        <>
          Shortlisted {parameters?.count || 0} candidates for study {studyContext}
        </>
      );
    case 'screener.create':
    case 'participation.update':
    case 'participation.create':
      return <></>; // i think this is removed

    case 'email_template.create':
      return <>Created a new email template</>;
    case 'interview_template.create':
      return <>Created a new interview template</>;
    case 'template_study.create':
      return <>Created a new template</>;
    case 'candidate_message.canceled':
      return (
        <>
          Couldn't send {CANDIDATE_MESSAGE_EVENTS[parameters?.event]} message to {customerLink} {studyContext}.
        </>
      );
    case 'candidate_message.sent':
      return (
        <>
          Sent {CANDIDATE_MESSAGE_EVENTS[parameters?.event]} message to {customerLink}
          {studyContext}
        </>
      );
    case 'candidate_message.opened':
      return (
        <>
          Opened {CANDIDATE_MESSAGE_EVENTS[parameters?.event]} message{studyContext}
        </>
      );
    case 'candidate_message.clicked':
      return (
        <>
          Clicked {CANDIDATE_MESSAGE_EVENTS[parameters?.event]} message{studyContext}
        </>
      );

    default:
      return <>{key}</>;
    // const [object, action] = key.split('.')
    // return <>{action + 'ed the ' + object}</>;
  }
};

export default ActivityInfo;
