import React, { useMemo, VFC } from 'react';
import { Grid } from '@components/common/Grid';
import { SummaryColumn } from '@components/ScreenerResults/components';
import * as BlockableComponents from '@components/SurveyBuilder/types/blockable-components';
import { formatMilliseconds } from '@components/ScreenerResults/components/CardSortResponse/utils';

interface Props {
  answers: NonNullable<ScreenerResponseAnswerValue<'card_sort'>>[];
  sortType: BlockableComponents.CardSort['sort_type'];
  totalCards: number;
  totalCategories: number;
  overallAgreement: number;
}

export const CardSortSummary: VFC<Props> = ({ overallAgreement, answers, totalCards, totalCategories, sortType }) => {
  const totalColumns = sortType === 'closed' ? 5 : 4;

  const completed = useMemo(() => answers.filter((answer) => answer.completed), [answers]);

  const incompleteRate = useMemo(() => {
    const incompleteCount = answers.filter((answer) => !answer.completed).length;

    return (incompleteCount / answers.length) * 100;
  }, [answers]);

  const avgDuration = useMemo(() => {
    const totalDuration = completed.reduce((acc, answer) => acc + (answer.time_in_task ?? 0), 0);

    return totalDuration / (completed.length || 1);
  }, [answers, completed]);

  return (
    <section className='p-4 mb-2 border border-gray-200 rounded'>
      <Grid desktop={totalColumns} tablet={totalColumns} mobile={2}>
        <SummaryColumn
          value={`${overallAgreement}%`}
          label='Overall agreement'
          tooltip='The percentage of users who placed a card in a certain category'
          iconHref='#prototypeTaskMetrics-success'
        />
        {sortType === 'closed' && (
          <SummaryColumn
            tooltip='Percentage of responses that failed to complete the task'
            value={incompleteRate}
            label='Incomplete'
            iconHref='#prototypeTaskMetrics-incomplete'
          />
        )}
        <SummaryColumn
          tooltip='Number of cards in the task that needed to be sorted'
          value={totalCards}
          label='Cards'
          iconHref='#prototypeTaskMetrics-cards'
        />
        <SummaryColumn
          tooltip='The number of categories in the task into which the cards had to be sorted'
          value={totalCategories}
          label='Avg. Cat. per response'
          iconHref='#prototypeTaskMetrics-folder'
        />
        <SummaryColumn
          tooltip='How long it took users to sort all cards, on average'
          value={formatMilliseconds(avgDuration)}
          label='Avg. Duration'
          iconHref='#prototypeTaskMetrics-avgDuration'
        />
      </Grid>
    </section>
  );
};
