import { Button, Modal } from '@components/common';
import React from 'react';

interface Props {
  onClose: () => void;
  onUnmerge: () => void;
  isLoading?: boolean;
}

export const UnmergeCategoryModal: React.FC<Props> = ({ onClose, onUnmerge, isLoading }) => {
  return (
    <Modal icon='danger' title='Unmerge categories' onClose={onClose}>
      <p className='pb-12 text-gray-700'>
        You are about to unmerge previously merged categories. All categories will return to their previous states.
      </p>
      <div className='flex justify-end space-x-4'>
        <Button disabled={isLoading} onClick={onClose}>
          Cancel
        </Button>
        <Button
          className='whitespace-nowrap'
          data-testid='Confirm'
          disabled={isLoading}
          loading={isLoading}
          onClick={onUnmerge}
          danger
        >
          Confirm and unmerge
        </Button>
      </div>
    </Modal>
  );
};
