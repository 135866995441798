import * as React from 'react';
import { useMemo, useState } from 'react';
import { ColumnInstance, Row } from 'react-table';
import { BulkActions } from '@components/StudiesApp/components/StudyPublished/components/BulkActions';
import { DefaultActions } from '@components/StudiesApp/components/StudyPublished/components/DefaultActions';
import { ExportCsvModal } from '@components/shared/ExportCsvModal';
import { usePermission } from '@hooks/usePermission';
import { useFeature } from '@hooks/useFeature';
import { StudyAction } from '@components/StudiesApp/components/StudyPublished/components/ACTIONS';
import { Option } from '@components/common';
import { ParticipationTableItem } from '../../../ParticipationTable/helpers/buildParticipantsColumns';
import { ExportCsvButton } from '@components/common/ExportCsvButton';
import { DropdownLink } from '@components/common';

interface Props {
  canSchedule: boolean;
  currentStatus: ParticipationStatus;
  selectedIds: number[];
  study: Study;
  mode: StudyAction | null;
  setMode: (v: StudyAction | null) => void;
  participations: Participation[];
  closeAll: () => void;
  setLoading: (v: boolean) => void;
  handleParticipationsUpdate: (backgroundTask?: BackgroundTask) => void;
  columnOptions: Option[];
  visibleColumnNames: string[];
  visibleColumns: ColumnInstance<ParticipationTableItem>[];
  studyLimits?: StudyLimit[];
  setAddCandidatesDefaultStudyLimit: (v: StudyLimit | null) => void;
  rows: Row<ParticipationTableItem>[];
  query?: ServerFilterQuery;
  allSelected?: boolean;
}

export const ParticipantsActions: React.FC<Props> = ({
  canSchedule,
  currentStatus,
  selectedIds,
  study,
  mode,
  setMode,
  participations,
  closeAll,
  setLoading,
  handleParticipationsUpdate,
  columnOptions,
  visibleColumnNames,
  visibleColumns,
  studyLimits,
  setAddCandidatesDefaultStudyLimit,
  rows,
  query,
  allSelected
}) => {
  const canUpdate = usePermission<Study>('updateStudy')(study);
  const canExportCsv = usePermission('exportCsv')();
  const serverSideParticipations = useFeature('serverside_participations');

  const csvHeaders = useMemo(
    () =>
      columnOptions
        .filter(({ value }) => visibleColumnNames.includes(value))
        .map(({ label, value }) => ({ label, key: value })),
    [columnOptions, visibleColumnNames]
  );

  const selectedCsvData = useMemo(
    () =>
      selectedIds.map((id) => {
        const participation = participations.find((p) => p.id === id);

        if (!participation) return {};

        const values = visibleColumns.reduce((prev, { id }) => {
          const accessor = visibleColumns.find((d) => d.id === id)?.accessor;

          let value;

          if (typeof accessor === 'function') {
            value = accessor({ participation });
          } else {
            value = participation?.[id];
          }

          return { ...prev, [id]: value };
        }, {});

        return values;
      }),
    [selectedIds, participations, csvHeaders, visibleColumns]
  );

  const csvData = useMemo(() => rows.map((row) => row.values), [rows]);
  const [csvExportModalOpen, setCsvExportModalOpen] = useState<boolean>(false);

  return (
    <>
      {!['closed', 'archived'].includes(study.state) &&
        ((selectedIds.length > 0 || (allSelected && serverSideParticipations)) && canUpdate ? (
          <BulkActions
            query={query}
            allSelected={allSelected}
            medium
            selectedIds={selectedIds}
            study={study}
            canSchedule={canSchedule}
            currentStatus={currentStatus}
            mode={mode}
            participations={participations}
            onSuccess={handleParticipationsUpdate}
            closeAll={closeAll}
            setLoading={setLoading}
            setMode={setMode}
            renderExportCSV={() =>
              serverSideParticipations ? (
                <DropdownLink className='flex-nowrap flex items-center' onClick={() => setCsvExportModalOpen(true)}>
                  Export CSV
                </DropdownLink>
              ) : (
                <ExportCsvButton
                  className='desktop:mx-0 mx-2 mb-4'
                  data={selectedCsvData}
                  disabled={!canExportCsv}
                  disabledTooltip={
                    serverSideParticipations ? 'This feature is temporary disabled.' : 'Available only for admins'
                  }
                  medium
                  type='link'
                  filename='participations-export.csv'
                  headers={csvHeaders}
                />
              )
            }
          />
        ) : (
          <DefaultActions
            renderExportCSV={() =>
              serverSideParticipations ? (
                <DropdownLink className='flex-nowrap flex items-center' onClick={() => setCsvExportModalOpen(true)}>
                  Export CSV
                </DropdownLink>
              ) : (
                <ExportCsvButton
                  className='desktop:mx-0 mx-2 mb-4'
                  data={csvData}
                  disabled={!canExportCsv}
                  disabledTooltip={
                    serverSideParticipations ? 'This feature is temporary disabled.' : 'Available only for admins'
                  }
                  medium
                  type='link'
                  filename='participations-export.csv'
                  headers={csvHeaders}
                />
              )
            }
            canUpdate={canUpdate}
            setMode={setMode}
            mode={mode}
            study={study}
            closeAll={closeAll}
            studyLimits={studyLimits || []}
            setStudyLimit={setAddCandidatesDefaultStudyLimit}
          />
        ))}

      {csvExportModalOpen && (
        <ExportCsvModal
          params={{
            columns: visibleColumnNames,
            kind: 'participations',
            ...(allSelected ? { query } : { ids: selectedIds })
          }}
          setOpen={setCsvExportModalOpen}
          subjectString={'participations'}
        />
      )}
    </>
  );
};
