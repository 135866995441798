export const getStatusAttrs = (
  status: ParticipationStatus,
  style: Study['style'],
  onGQ: boolean,
  serverSideParticipations: boolean
) => {
  const attrs: { label: string; name: string }[] = [{ name: 'status', label: 'Status' }];

  if (status === 'booked') {
    attrs.push({ label: 'Call Link', name: 'join_url' });
  }
  if (status === 'completed' && style === 'unmoderated_test') {
    attrs.push({ name: 'participation', label: 'Recording' });
  }
  if (status === 'completed' && style === 'survey' && onGQ) {
    attrs.push({ label: 'Survey', name: 'survey' });
  }
  if (['completed', 'booked', 'no_show'].includes(status) && style === 'video_call') {
    attrs.push({ label: 'Moderator', name: 'moderator' });
  }
  if (['completed', 'canceled', 'no_show'].includes(status)) {
    attrs.push({ label: 'Participant rating', name: 'rating' });
  }
  if (status !== 'invited') {
    if (!serverSideParticipations) {
      attrs.push({ label: 'Matches all ideal answers?', name: 'preScreenerField.ideal_response' });
      attrs.push({ label: '# of ideal answers', name: 'preScreenerField.ideal_answers_count' });
    } else {
      attrs.push({ label: '# of ideal answers', name: 'pre_screener_response:match_score' });
    }
  }

  return attrs;
};
