import React, { forwardRef } from 'react';
import { useParams } from 'react-router-dom';
import cn from 'classnames';

import { Item, Menu } from '@components/common/Menu';
import { Button } from '@components/common';
import { ButtonProps } from '@components/common/Button';
import { api } from '@api/reduxApi';

import { BLOCK_KIND_LABELS } from '../constants';
import { getBlockLabel } from '../helpers/getBlockLabel';
import { Block } from './Block';
import { BlockIcon } from './BlockIcon';
import { useGetAvailableBlockKinds } from '../hooks/useGetAvailableBlockKinds';
import * as Models from '../types/models';

interface Props extends ButtonProps {
  onPickBlock?: (kind: Models.BlockKind) => void;
}

export const AddBlockButton = forwardRef<HTMLButtonElement, Props>(({ onPickBlock, ...rest }, ref) => {
  const availableBlockKinds = useGetAvailableBlockKinds();

  const { id } = useParams<{ id: string }>();

  const { data: study } = api.useGetSimpleStudyQuery(Number(id));

  return (
    <Menu
      renderTrigger={() => (
        <Button ref={ref} icon='plus' small {...rest}>
          Add block
        </Button>
      )}
      popperProps={{ placement: 'bottom-start' }}
      onItemSelect={onPickBlock}
      className='w-60 h-64 overflow-auto bg-white border border-gray-200 divide-y divide-gray-200 rounded shadow-lg'
    >
      {availableBlockKinds.map((kind) => {
        const isDisabled = study?.device_type === 'mobile' && kind === 'card_sort';

        return (
          <Item
            tooltip={isDisabled ? 'Card Sort is not available on mobile.' : ''}
            key={kind}
            tabIndex={0}
            value={kind}
            isDisabled={isDisabled}
          >
            <Block
              tabIndex={-1}
              aria-label={`${getBlockLabel(kind)} block`}
              className={cn(isDisabled ? 'bg-gray-200' : 'hover:bg-gray-100')}
              isCompact
              title={BLOCK_KIND_LABELS[kind]}
              icon={<BlockIcon kind={kind} isCompact isDisabled={isDisabled} />}
            />
          </Item>
        );
      })}
    </Menu>
  );
});
