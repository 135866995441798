import Tippy from '@tippyjs/react';
import * as React from 'react';

import { Avatar, AvatarFromId, Text } from '@components/common';
import { capitalize } from '@components/utils';

type Props = {
  calendarEvent: CalendarEvent;
};

export const RSVP_ICONS: Partial<Record<Invitee['rsvp'] & string, string>> = {
  yes: '✅',
  no: '❌',
  maybe: '❓'
};

export const RSVP_TOOLTIPS: Partial<Record<Invitee['rsvp'] & string, string>> = {
  yes: 'The attendee has accepted the calendar event.',
  no: 'The attendee has declined the calendar event.',
  maybe: 'The attendee has marked the calendar event with a maybe.'
};

export const Attendees: React.FC<Props> = ({ calendarEvent }) => (
  <div className='flex flex-col space-y-3'>
    {calendarEvent.invitees.length === 0 && (
      <Text h='400' color='gray-500'>
        None.
      </Text>
    )}
    {calendarEvent.invitees.map(({ email, name, role, rsvp, owner_id, owner_type }) => (
      <div key={email} className='flex items-start space-x-2'>
        {owner_type === 'User' && owner_id && <AvatarFromId userId={owner_id} />}
        {owner_type !== 'User' && <Avatar size='md' user={{ name, email } as any} />}

        <div>
          <div className='flex items-center space-x-2'>
            <Text color={name ? undefined : 'gray-500'} h='400'>
              {name || 'No name'}
            </Text>
            {rsvp && rsvp in RSVP_ICONS && (
              <Tippy content={RSVP_TOOLTIPS[rsvp]} placement='top'>
                <span className='text-sm'>{RSVP_ICONS[rsvp]}</span>
              </Tippy>
            )}
          </div>
          {email && (
            <Text h='200' color='gray-500'>
              {email}
            </Text>
          )}
          <Text h='200' color='gray-500'>
            {capitalize(role || 'no role')}
          </Text>
        </div>
      </div>
    ))}
  </div>
);
