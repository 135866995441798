import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@components/common';
import { ZoomableImage } from '@components/common';
import { LinkifiedText } from '@components/common/LinkifiedText';
import { ErrorSvg } from '@components/svgs';

import {
  BooleanInput,
  DateInput,
  EmailInput,
  InfoInput,
  LocationSearchInput,
  LongTextInput,
  MultipleChoiceInput,
  MultipleChoicePillInput,
  NumberInput,
  NumberRangeInput,
  ShortTextInput,
  SingleChoiceInput,
  SingleChoicePillInput,
  TaskInput,
  WebsiteInput
} from './fields';
import { QuestionCard, QuestionInner, QuestionNumber, QuestionTitle } from './QuestionCard';
import { InputProps, QuestionProps } from './types';

type FieldDisplayType = 'multiple_choice_list' | 'multiple_choice_pill' | 'single_choice_list' | 'single_choice_pill';
type InputComponetsType = Omit<
  Record<ScreenerFieldType | FieldDisplayType, React.FC<InputProps>>,
  'start_loom' | 'stop_loom'
>;
const InputComponents: InputComponetsType = {
  short_text: ShortTextInput,
  yes_no: BooleanInput,
  long_text: LongTextInput,
  email: EmailInput,
  number: NumberInput,
  website: WebsiteInput,
  multiple_choice: MultipleChoiceInput,
  single_choice: SingleChoiceInput,
  multiple_choice_list: MultipleChoiceInput,
  multiple_choice_pill: MultipleChoicePillInput,
  single_choice_list: SingleChoiceInput,
  single_choice_pill: SingleChoicePillInput,
  number_range: NumberRangeInput,
  task: TaskInput,
  info: InfoInput,
  date: DateInput,
  location: LocationSearchInput,
  prototype_test: InfoInput,
  card_sort: InfoInput,
  tree_test: InfoInput
};

export const Question: React.FC<QuestionProps> = ({
  number,
  register,
  watch,
  errors,
  field,
  autofocus,
  onFocus,
  onClick,
  isActive,
  setValue,
  control
}) => {
  let inputKey = field.field_type as string;
  const { t } = useTranslation('SurveyQuestion');
  if (['multiple_choice', 'single_choice'].includes(field.field_type)) {
    inputKey = inputKey + '_' + (field.layout || 'list');
  }
  const Input = InputComponents[inputKey];
  let helper = field.helper;

  if (!helper && field.field_type === 'multiple_choice') {
    helper = t('select_all_that_apply');
  }

  const hasImageUrl = !!(field.image_url && field.image_url.length > 1);

  return (
    <QuestionCard key={`field_${field.id}`} onClick={onClick} isActive={!!isActive}>
      <QuestionNumber number={number} />
      <QuestionInner>
        <QuestionTitle htmlFor={String(field.id)} required={field.required}>
          <LinkifiedText text={field.label} />
        </QuestionTitle>
        {helper &&
          field.field_type !== 'info' &&
          helper.split('\n').map((t, i) => (
            <p key={i} id={`description-for-${field.id}`} className='text-custom-brand mt-2 text-sm'>
              <LinkifiedText text={t} />
            </p>
          ))}

        {hasImageUrl && (
          <ZoomableImage
            src={field.image_url || undefined}
            className='w-96 object-contain object-center h-48 my-4'
            alt={field.image_alt || 'Question image'}
          />
        )}

        <div className='w-full mt-5'>
          <Input
            id={String(field.id)}
            control={control}
            field={field}
            onFocus={onFocus}
            register={register}
            autofocus={autofocus}
            watch={watch}
            error={errors[field.id]}
            setValue={setValue}
          />
        </div>
        {errors[field.id] && (
          <Text
            role='alert'
            aria-label='Error message'
            aria-describedby={String(field.id)}
            color='red-600'
            h='400'
            className='no-branding flex items-center mt-4 truncate'
          >
            <ErrorSvg className='mr-2' />
            {t(errors[field.id].message) || t('required')}
          </Text>
        )}
      </QuestionInner>
    </QuestionCard>
  );
};

export const NotQualifiedQuestion: React.FC = () => {
  const { t } = useTranslation('SurveyQuestion');

  return (
    <QuestionCard isActive>
      <QuestionInner>
        <LinkifiedText text={t('not_qualified')} />
      </QuestionInner>
    </QuestionCard>
  );
};
