import { ELocalStorageKeys } from 'constants/localStorageKeys';
import * as React from 'react';
import { HeaderProps } from 'react-table';

import { Avatar, PopperDropdown, Text, Tooltip } from '@components/common';
import { CaretDownSVG, ChevronDownSVG } from '@components/svgs';
import { ColumnsActionsDropdown } from '@components/shared/GridTable/components/shared';

interface OtherHeaderProps<T extends { [K in keyof T]: T[K] }> extends HeaderProps<T> {
  setSortValue?: (value: string, desc: boolean) => void;
  storageKey: ELocalStorageKeys;
  tooltip?: string;
  disableSort?: boolean;
}

type OtherHeader = <T>(props: OtherHeaderProps<T>) => React.ReactElement<OtherHeaderProps<T>>;

export const OtherHeader: OtherHeader = ({
  storageKey,
  column,
  visibleColumns,
  setColumnOrder,
  setSortValue,
  tooltip,
  addFilter,
  disableSort
}) => {
  const handleChangeOrder = (id, indexChange) => {
    const visibleColumnsIds = visibleColumns.map((column) => column.id);

    const fromIndex = visibleColumnsIds.indexOf(id);
    // prevent from changing "name" column position (it always remains 1st)
    if (fromIndex === 1 && indexChange === -1) {
      return;
    }

    visibleColumnsIds.splice(fromIndex, 1);
    visibleColumnsIds.splice(fromIndex + indexChange, 0, id);

    setColumnOrder(visibleColumnsIds);

    localStorage.setItem(storageKey, JSON.stringify(visibleColumnsIds));
  };

  const hideColumn = () => {
    column.toggleColVisibility(column.id);
  };

  const changeSort = (descending: boolean): void => {
    setSortValue?.(column.id, descending);
    column.toggleSortBy?.(descending);
  };

  const canHide = !['name', 'interview', 'recording'].includes(column.id);

  return (
    <div className='h400 hover:bg-gray-50 overflow-hidden hover:text-indigo-600 flex items-center w-full h-full font-bold cursor-pointer'>
      <PopperDropdown
        hideCaret
        popperProps={{ placement: 'bottom-end', renderOnBodyRoot: false }}
        className='group truncate flex-1 flex-shrink-0 flex h-full flex-col items-stretch h400 px-2 font-bold cursor-pointer'
        text={
          <div className='flex w-full justify-between'>
            <span className='font-bold truncate xx-column-header' title={column.headerLabel}>
              {column.headerLabel}
            </span>
            <ChevronDownSVG className='group-hover:block flex-shrink-0 ml-1 hidden text-gray-500' />
          </div>
        }
        tooltip={`${column.headerLabel} column options`}
      >
        <ColumnsActionsDropdown
          addFilter={addFilter}
          changeSort={setSortValue ? changeSort : undefined}
          columnAccessor={column.id}
          sortIconType={column.sortIconType}
          hideColumn={canHide ? hideColumn : undefined}
          handleChangeOrder={handleChangeOrder}
          disableSort={disableSort}
        />
      </PopperDropdown>
    </div>
  );
};
