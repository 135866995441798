import React from 'react';
import { Row } from 'react-table';
import { SelectOption } from '@components/common';

export enum Operators {
  is = 'is',
  is_not = 'is_not',
  includes_any = 'includes_any',
  includes_none = 'includes_none',
  includes_all = 'includes_all',
  is_greater = 'is_greater',
  is_lesser = 'is_lesser',
  is_between = 'is_between',
  is_present = 'is_present',
  is_blank = 'is_blank',
  contains = 'contains',
  contains_not = 'contains_not'
}

export type Operator = keyof typeof Operators;

type HookPayload<T extends FilterState<any>> = {
  state: T | undefined;
  meta: T['meta'] | undefined;
  setMeta: (meta: T['meta']) => void;
};
type DefinitionHook<T extends FilterState<any>> = (props: HookPayload<T>) => void;

export type FilterType =
  | 'date'
  | 'team_members'
  | 'artifact_type'
  | 'tag'
  | 'studies'
  | 'free_text'
  | 'text' // AKA. single_choice
  | 'multiple_choice'
  | 'segment'
  | 'choose'
  | 'participated_in_studies'
  | 'guide_status' // as will this
  | 'number'
  | 'eligibility'
  | 'boolean'
  | 'incentive_status' // this will go away.
  | 'owner'
  | 'participant'
  | 'team_ids';

// consider two classes - JSFilter vs BackendFilter?
export type FilterDefinition<D, U = any> = {
  id: string;
  type: FilterType;
  name: string;
  onlyAllowOne?: boolean;
  applyOnChange?: boolean;
  displayName?: string;
  defaultOperator: Operator;
  defaultValue?: U;
  range?: { min: U; max: U };
  func?: (obj: D, state: FilterState<D, U>) => boolean;
  operators?: Operator[];
  renderValue?: (value: U) => string;
  allowNotSet?: boolean;
  Component?: React.FC<DropdownProps<D, U>>;
  hook?: DefinitionHook<FilterState<D, U>>;
  Icon?: React.FC;
  toAlgoliaFilter?: (state: FilterState<D, U>) => string;
  periodOptions?: SelectOption[];
  transformValue?: (value: any) => any;
  inputSuffix?: string;
  changeFn?: (value: any) => U;
  tooltip?: string;
  values?: U;
};

export type FilterRange<U> = {
  min: U | null;
  max: U | null;
};

export type FilterState<D, U = any, M = any> = {
  id: number;
  definition: FilterDefinition<D, U>;
  operator: Operator;
  value: U | null;
  range?: FilterRange<U>;
  allowNotSet?: boolean;
  meta?: M;
  period?: string;
};

export type OperatorOptions = { label: string; value: Operator; icon: React.FC<any> }[];

export type GlobalFilter<D extends object> = (rows: Row<D>[], columnIds: string[]) => Row<D>[];

export type DropdownProps<D, U, M = any> = FilterState<D, U, M> & {
  onChange: (state: Partial<FilterState<D, U, M>>) => void;
  values?: U;
  localValue?: U;
};

export type PartialFilterDefinition<T> = Partial<Omit<FilterDefinition<T>, 'id'>> &
  Required<Pick<FilterDefinition<T>, 'id'>>;
