import { compact } from '@components/utils';
import Tippy from '@tippyjs/react';
import { MergeCells } from '@components/shared/Tiptap/icons';
import React from 'react';

export const formatMilliseconds = (milliseconds: number) => {
  if (milliseconds < 0) {
    return 'Invalid duration';
  }

  const totalSeconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  if (minutes === 0) {
    return `${seconds} sec`;
  }

  return `${minutes} min ${seconds} sec`;
};

export const consolidateCards = (
  arr: ScreenerResponseAnswerValue<'card_sort'>['results']
): ScreenerResponseAnswerValue<'card_sort'>['results'] =>
  Object.values(
    arr.reduce((acc, curr) => {
      if (!acc[curr.name]) {
        acc[curr.name] = { name: curr.name, cards: [] };
      }

      acc[curr.name].cards.push(...curr.cards);

      return acc;
    }, {})
  );

export const getCustomCategories = (answers: NonNullable<ScreenerResponseAnswerValue<'card_sort'>>[]): string[] => {
  const customCategories = compact(
    answers.reduce((acc, answer) => {
      return [...acc, ...answer.results.map(({ name }) => name)];
    }, [] as string[])
  );

  return Array.from(new Set(customCategories)).sort();
};

export const renderMergedCategories = ({
  mergedCategories,
  category
}: {
  mergedCategories: { name: string; categories: string[] }[];
  category: string;
}) => {
  const mergedCategory = mergedCategories.find((merged) => merged.name === category);
  if (!mergedCategory?.categories.length) return null;

  let tooltip: string = '';

  if (mergedCategory.categories.length === 1) {
    tooltip = `Merged using ${mergedCategory.categories[0]}`;
  } else if (mergedCategory.categories.length === 2) {
    tooltip = `Merged using ${mergedCategory.categories[0]} and ${mergedCategory.categories[1]}`;
  } else {
    const categories = [...mergedCategory.categories];
    const lastElement = categories.pop();

    tooltip = `Merged using ${categories.join(', ')} and ${lastElement}`;
  }

  return (
    <Tippy arrow={false} content={tooltip}>
      <span className='ml-2'>
        <MergeCells className='text-green-600' />
      </span>
    </Tippy>
  );
};
