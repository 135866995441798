import { useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import * as Types from '../types';

interface UseCardSortTaskTrackingArgs {
  debounceDelay?: number;
  onEvent?: (events: Types.CardSortEvent[]) => void;
}

interface UseCardSortTaskTrackingReturn {
  events: Types.CardSortEvent[];
  onMoveCard: (args: Types.MoveCardArgs) => void;
  onCreateCategory: (category: string) => void;
  onEditCategory: (args: Types.EditCategoryArgs) => void;
  onDeleteCategory: (category: string) => void;
}


export const useCardSortTaskTracking = (args: UseCardSortTaskTrackingArgs): UseCardSortTaskTrackingReturn => {
  const { debounceDelay = 500, onEvent } = args;

  const [events, setEvents] = useState<Types.CardSortEvent[]>([]);

  const cachedEvents = useRef<Types.CardSortEvent[]>([]);

  const send = () => {
    onEvent?.(cachedEvents.current);
    setEvents(cachedEvents.current);
  };

  const { callback: debouncedSend } = useDebouncedCallback(send, debounceDelay);

  const onMoveCard = ({ card, from, to }: Types.MoveCardArgs) => {
    const event: Types.CardSortEvent<'move_card'> = {
      at: new Date(),
      card,
      from,
      to,
      event: 'move_card'
    };

    cachedEvents.current.push(event);

    debouncedSend();
  };

  const onCreateCategory = (category: string) => {
    const event: Types.CardSortEvent<'create_category'> = {
      at: new Date(),
      category,
      event: 'create_category'
    };

    cachedEvents.current.push(event);
  };

  const onEditCategory = ({ category, previous }: Types.EditCategoryArgs) => {
    const event: Types.CardSortEvent<'edit_category'> = {
      at: new Date(),
      category,
      previous,
      event: 'edit_category'
    };

    cachedEvents.current.push(event);
    debouncedSend();
  };

  const onDeleteCategory = (category: string) => {
    const event: Types.CardSortEvent<'delete_category'> = {
      at: new Date(),
      category,
      event: 'delete_category'
    };

    cachedEvents.current.push(event);

    debouncedSend();
  };

  return { events, onMoveCard, onCreateCategory, onEditCategory, onDeleteCategory };
};
