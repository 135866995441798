import React, { forwardRef, HTMLAttributes, useMemo } from 'react';
import { Table } from '@components/shared/Table';
import { getCardsTableColumnDefs } from './helpers/getCardsTableColumnDefs';
import { buildCardsTableData } from '@components/ScreenerResults/components/CardSortResponse/components/helpers/buildCardsTableData';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  answers: NonNullable<ScreenerResponseAnswerValue<'card_sort'>>[];
  cards: string[];
  categories: string[];
  mergedCategories: { name: string; categories: string[] }[];
}

export interface CardsTableData {
  card: string;
  categories: { name: string; frequency: number; avgPosition: string; avgAgreement: number }[];
}

export const CardsTable = forwardRef<HTMLDivElement, Props>(({ answers, mergedCategories, cards, categories }, ref) => {
  const columns = useMemo(() => getCardsTableColumnDefs({ answers, mergedCategories }), [mergedCategories]);

  const data = useMemo(() => {
    return buildCardsTableData({ cards, categories, answers });
  }, [answers, cards, categories]);

  return (
    <div className='relative max-w-full overflow-auto'>
      <Table<CardsTableData>
        data={data}
        columns={columns}
        columnPinning={{ left: ['card'], right: ['expand'] }}
        className='w-full bg-white border border-gray-200 table-fixed'
        cellClassName='border-b border-gray-200 align-top'
      />
    </div>
  );
});
