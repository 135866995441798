import * as React from 'react';
import { useState } from 'react';

import { api } from '@api/reduxApi';
import { Text } from '@components/common';
import { Basic } from '@components/shared/Skeleton';
import { BookingDetailsForm } from '@components/shared/BookingDetailsForm';

type Props = { participationId: number; onClose: () => void };

export const BookingDetails: React.FC<Props> = ({ participationId: id }) => {
  const { data: bookingDetail, isLoading: isLoading } = api.useGetParticipationCalendarBookingDetailsQuery({ id });

  const hasEvent = !!bookingDetail?.candidate_event;

  const [editMode, setEditMode] = useState(false);

  return (
    <>
      <div className='flex flex-col space-y-3'>
        {isLoading && <Basic />}

        {!isLoading && !hasEvent && <Text color='gray-500'>No booking details.</Text>}

        {hasEvent && (
          <div className='mt-3 mb-6'>
            <BookingDetailsForm editMode={editMode} bookingDetail={bookingDetail} />
          </div>
        )}
      </div>
    </>
  );
};
