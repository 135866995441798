import React from 'react';

import { Checkboxes } from './Checkboxes';
import { DatePicker as Date } from './DatePicker';
import { DateRange } from './DateRange';
import { Number } from './Number';
import { NumberRange } from './NumberRange';
import { Radios } from './Radios';
import { Text } from './Text';

type AnswerOperatorConfig = Record<'default', Record<IdealAnswerOperator, React.FC<AnswerInputProps<any>>>> &
  Partial<Record<ScreenerField['field_type'], Partial<Record<IdealAnswerOperator, React.FC<AnswerInputProps<any>>>>>>;

// Important: make sure to keep this in sync with the transformAnswerValue function in utils.tsx
const OPERATOR_ELS: AnswerOperatorConfig = {
  default: {
    contains: Text,
    not_contains: Text,
    starts_with: Text,
    ends_with: Text,
    contains_any: Checkboxes,
    contains_all: Checkboxes,
    contains_none: Checkboxes,
    not_eq: Text,
    eq: Text,
    gt: Number,
    lt: Number,
    between: NumberRange
  },
  number: {
    not_eq: Number,
    eq: Number
  },
  yes_no: {
    not_eq: Radios,
    eq: Radios
  },
  single_choice: {
    eq: Radios
  },
  multiple_choice: {
    eq: Checkboxes
  },
  date: {
    eq: Date,
    gt: Date,
    lt: Date,
    between: DateRange
  }
};

type Props = {
  answer: IdealAnswer;
  field: ScreenerField;
  onChange: (value: any) => void;
};

function getValueOptions(operator: IdealAnswerOperator | null, field: ScreenerField): string[] {
  let options: string[] = [];
  if (field.field_type === 'yes_no') {
    options = ['Yes', 'No'];
  } else {
    options = field.options || [];
  }
  if (field.other) {
    options = options.concat(['Other']);
  }
  return options;
}

export const ValueInput: React.FC<Props> = ({ answer, field, onChange }) => {
  const { operator, value } = answer;
  if (operator) {
    const Element = (OPERATOR_ELS[field.field_type] || {})[operator] || OPERATOR_ELS.default[operator];
    return <Element value={value} onChange={onChange} options={getValueOptions(operator, field)} />;
  } else {
    return null;
  }
};
