import React, { VFC } from 'react';
import { Grid } from '@components/common/Grid';
import { SummaryColumn } from '@components/ScreenerResults/components';
import { formatMilliseconds } from '@components/ScreenerResults/components/CardSortResponse/utils';

interface Props {
  answers: NonNullable<ScreenerResponseAnswerValue<'tree_test'>>[];
}

export const TreeTestSummary: VFC<Props> = ({ answers }) => {
  const completed = 35;
  const overallSuccess = 90;
  const directSuccess = 80;
  const overallDirectness = 70;
  const avgDuration = 1000;

  return (
    <section className='p-4 mb-2 border border-gray-200 rounded'>
      <Grid desktop={5} tablet={5} mobile={2}>
        <SummaryColumn
          value={completed}
          label='Completed'
          tooltip='The number of users who completed the test.'
          iconHref='#prototypeTaskMetrics-person'
        />
        <SummaryColumn
          tooltip='The percentage of users who selected the right answer'
          value={`${overallSuccess}%`}
          label='Overall success'
          iconHref='#prototypeTaskMetrics-success'
        />
        <SummaryColumn
          tooltip='The percentage of users who selected the correct and direct answer'
          value={`${directSuccess}%`}
          label='Direct success'
          iconHref='#prototypeTaskMetrics-success'
        />
        <SummaryColumn
          tooltip='The percentage of users who respondend directly'
          value={`${overallDirectness}%`}
          label='Overall directness'
          iconHref='#prototypeTaskMetrics-rocket'
        />
        <SummaryColumn
          tooltip='How long it took users to complete a task'
          value={formatMilliseconds(avgDuration)}
          label='Avg. Duration'
          iconHref='#prototypeTaskMetrics-avgDuration'
        />
      </Grid>
    </section>
  );
};
