import copy from 'copy-to-clipboard';
import * as React from 'react';

import { Button, ButtonProps } from '@components/common/Button';
import { createPolymorphicComponent } from '@helpers/react/createPolymorphicComponent';
import { useToaster } from '@stores/toaster';
import Tippy from '@tippyjs/react';

interface Props extends Omit<ButtonProps, 'text'> {
  text: string;
  as?: string;
  onCopy?: (text: string) => void;
  noTippy?: boolean;
}
export const CopyURLButton = createPolymorphicComponent<Props, 'div'>(
  ({ as: Component = Button, text, noTippy, children, onCopy, ...props }) => {
    const showToast = useToaster();

    function onClick() {
      showToast({
        heading: 'URL copied to the clipboard',
        text: 'Paste it wherever you’d like.',
        icon: 'success'
      });
      copy(text);
      if (onCopy) {
        onCopy(text);
      }
    }

    if (noTippy) {
      return (
        <Component aria-label='Copy link' {...props} onClick={onClick}>
          {children}
        </Component>
      );
    }

    return (
      <Tippy content='Copy link'>
        <Component aria-label='Copy link' {...props} onClick={onClick}>
          {children}
        </Component>
      </Tippy>
    );
  },
  Button
);
