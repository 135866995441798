import * as React from 'react';
import { SORT_ICONS } from '@components/shared/GridTable/components/OtherHeader/constants';
import { PopperDropdownButton, Text } from '@components/common';
import { FilterSVG, HideSVG, MoveToLeftSVG, MoveToRightSVG } from '@components/svgs';
import { SortType } from '../../types';

interface Props {
  handleChangeOrder?: (id: string, indexChange: number) => void;
  hideColumn?: () => void;
  columnAccessor: string;
  sortIconType: SortType;
  changeSort?: (descending: boolean) => void;
  addFilter?: (v: string) => void;
  disableSort?: boolean;
}

export const ColumnsActionsDropdown: React.FC<Props> = ({
  handleChangeOrder,
  sortIconType,
  hideColumn,
  columnAccessor,
  changeSort,
  addFilter,
  disableSort
}) => {
  const sortCopy = SORT_ICONS[sortIconType] || SORT_ICONS.text;

  const moveLeft = () => {
    handleChangeOrder?.(columnAccessor, -1);
  };

  const moveRight = () => {
    handleChangeOrder?.(columnAccessor, 1);
  };

  const changeSortDescending = () => {
    changeSort?.(true);
  };

  const changeSortAscending = () => {
    changeSort?.(false);
  };

  return (
    <>
      {addFilter && (
        <PopperDropdownButton onClick={() => addFilter(columnAccessor)}>
          <div className='flex items-center'>
            <FilterSVG className='mr-4' />
            <Text h='400'>Filter</Text>
          </div>
        </PopperDropdownButton>
      )}
      {hideColumn && (
        <PopperDropdownButton onClick={hideColumn}>
          <div className='flex items-center'>
            <HideSVG className='mr-4' />
            <Text h='400'>Hide</Text>
          </div>
        </PopperDropdownButton>
      )}
      {!disableSort && changeSort && (
        <>
          <PopperDropdownButton onClick={changeSortAscending}>
            <div className='flex items-center'>
              <sortCopy.ascending.Icon className='mr-4' />
              <Text h='400'>{sortCopy.ascending.text}</Text>
            </div>
          </PopperDropdownButton>
          <PopperDropdownButton onClick={changeSortDescending}>
            <div className='flex items-center'>
              <sortCopy.descending.Icon className='mr-4' />
              <Text h='400'>{sortCopy.descending.text}</Text>
            </div>
          </PopperDropdownButton>
        </>
      )}
      {handleChangeOrder && (
        <>
          <PopperDropdownButton onClick={moveLeft}>
            <div className='flex items-center'>
              <MoveToLeftSVG className='mr-4' />
              <Text h='400'>Move to left</Text>
            </div>
          </PopperDropdownButton>
          <PopperDropdownButton onClick={moveRight}>
            <div className='flex items-center'>
              <MoveToRightSVG className='mr-4' />
              <Text h='400'>Move to right</Text>
            </div>
          </PopperDropdownButton>
        </>
      )}
    </>
  );
};
